import "./CursedHorizontalRule.scss";

type CursedHrProps = {
  content?: string;
  className?: string;
};

export const CursedHorizontalRule = ({
  content = "♢",
  className,
}: CursedHrProps) => {
  return (
    <hr className={`cursed-hr mx-auto ${className}`} data-content={content} />
  );
};
