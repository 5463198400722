import { ConnectKitButton, useModal } from "connectkit";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import {
  getMintCountInfo,
  getWalletStatus,
  MintCountInfo,
} from "../../config/api/rmx-api";
import { FrontendConfig } from "../../config/api/types";
import "./CursedEmojis.scss";
import { CursedEmojisRender } from "./CursedEmojisRender";
import { useErrorHandler } from "react-error-boundary";

export interface CursedEmojisProps {
  frontendConfig: FrontendConfig;
  ready: boolean;
}

export const CursedEmojis = ({ frontendConfig, ready }: CursedEmojisProps) => {
  const handleError = useErrorHandler();

  const { address, isConnected, isConnecting } = useAccount();
  const { open, setOpen } = useModal();

  const [isUserAllowed, setIsUserAllowed] = useState<boolean | null>(null);
  const [hasUserAlreadyMinted, setHasUserAlreadyMinted] = useState(false);
  const [mintCountInfo, setMintCountInfo] = useState<MintCountInfo>({
    totalMinted: 0,
    maximumMints: 512,
  });

  useEffect(() => {
    const fetchMintCountInfo = async () => {
      getMintCountInfo()
        .then((data) => {
          setMintCountInfo(data);
        })
        .catch((error) => {
          handleError(error);
        });
    };
    fetchMintCountInfo();
  }, []);

  useEffect(() => {
    if (!address || !ready) return;

    const fetchWalletApproval = async (walletId: string) => {
      // const result = await getWalletStatus(walletId);
      getWalletStatus(walletId)
        .then((result) => {
          console.log("wallet pre-check status:", address, result);
          console.log("frontend max mints", frontendConfig.maxMintsPerWallet);
          console.log(
            "already minted?",
            result.genesisMintCount >= frontendConfig.maxMintsPerWallet
          );
          setIsUserAllowed(result.isSignedUp);
          setHasUserAlreadyMinted(
            result.genesisMintCount >= frontendConfig.maxMintsPerWallet
          );
        })
        .catch((error) => {
          handleError(error);
        });
    };
    fetchWalletApproval(address).catch((error) => {
      console.error(`Error fetching mintCount for account ${address}`, error);
      handleError(error);
    });
  }, [address, ready]);

  console.log("hasUserAlreadyMinted", hasUserAlreadyMinted);
  return (
    <>
      <section className={"connect-button"}>
        <ConnectKitButton theme="minimal" />
      </section>
      <CursedEmojisRender
        isUserAllowed={isUserAllowed}
        mintCountInfo={mintCountInfo}
        hasUserAlreadyMinted={hasUserAlreadyMinted}
        openConnectModal={() => setOpen(true)}
        isConnecting={isConnecting}
        isConnected={isConnected}
        address={address}
        frontendConfig={frontendConfig}
      />
    </>
  );
};
