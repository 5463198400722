import { Card, Col } from "react-bootstrap";
import { Face } from "../../../../types";
import { FrontendConfig } from "../../config/api/types";
import { getMaxUniqueFeatures } from "../../config/rmx-console-config";
import TrashIcon from "../../images/Trash-icon.svg";
import TrashIconDisabled from "../../images/Trash-icon-disabled.svg";
import EmojiCanvas from "../EmojiCanvas/EmojiCanvas";
import { useEmojiCanvasContext } from "../EmojiCanvas/EmojiCanvasContext";
import { RmxButton } from "../RmxButton";
import TraitCounter from "../TraitCounter/TraitCounter";
import "./EmojiWorkspace.scss";
import { ModalishWalletWarning } from "./ModalishWalletWarning";
import { Overlay } from "./Overlay";
import { Part } from "../../types";

export function EmojiWorkspace(props: {
  frontendConfig: FrontendConfig;
  showWalletWarning: boolean;
  showNoTokenWarning: boolean;
  showSignupWarning: boolean;
  premintSignupUrl: string;
  onClearCanvasSelection: () => void;
  usedTraits: string[];
  showRemixCompleteWarning: boolean;
  showLoadingModal: boolean;
  face: Face;
  allUsedTraits: Part[];
}) {
  const { selectedElements } = useEmojiCanvasContext();
  const maxUniqueFeatures = getMaxUniqueFeatures();

  const showOverlay = (): boolean => {
    return (
      props.showWalletWarning ||
      props.showLoadingModal ||
      props.showRemixCompleteWarning ||
      props.showNoTokenWarning
    );
  };

  const determineTrashIcon = () => {
    if (selectedElements.length === 0) {
      return <img src={TrashIconDisabled} className={`trash-icon`} />;
    } else {
      return <img src={TrashIcon} className={`trash-icon`} />;
    }
  };

  return (
    <div className={"position-relative"}>
      <TraitCounter
        usedTraits={props.usedTraits}
        max={maxUniqueFeatures}
        face={props.face}
        frontendConfig={props.frontendConfig}
        selectedElements={selectedElements}
        allUsedTraits={props.allUsedTraits}
      />
      <Col style={{ padding: "0 16px" }}>
        <Card className={"rmx-card emoji-canvas-container mx-auto"}>
          <div className={"ratio ratio-1x1 "}>
            <EmojiCanvas />
            <ModalishWalletWarning
              show={props.showWalletWarning}
              title={"〠 Welcome to the RMX Console 〠"}
              subTitle={"++++++ Cursed Emojis ++++++"}
              line1={"Connect your wallet to get started"}
            />
            <ModalishWalletWarning
              show={props.showRemixCompleteWarning}
              title={"⎌⎌ Your remix is complete! ⎌⎌"}
              line1={
                <>
                  All your Emoji Bases are remixed! Wanna remix more? Pick one
                  up on{" "}
                  <a
                    href={props.frontendConfig.genesisCollectionUrl}
                    target={"_blank"}
                  >
                    OpenSea.
                  </a>
                </>
              }
              line2={
                <>
                  For more info, see{" "}
                  <a href={"/about"} target={"_blank"}>
                    our FAQs.
                  </a>
                </>
              }
              showCross={false}
            />
            <ModalishWalletWarning
              show={props.showNoTokenWarning}
              title={"⎌⎌ Curses! ⎌⎌"}
              line1={
                <>
                  You need a RMX Pass to remix. Pick one up on{" "}
                  <a
                    href={props.frontendConfig.genesisCollectionUrl}
                    target={"_blank"}
                  >
                    OpenSea.
                  </a>
                </>
              }
              line2={
                <>
                  For more info, see{" "}
                  <a href={"/about"} target={"_blank"}>
                    our FAQs.
                  </a>
                </>
              }
              showCross={false}
            />
            <ModalishWalletWarning
              show={props.showLoadingModal}
              title={"〠 Welcome to the RMX Console 〠"}
              subTitle={"++++++ Cursed Emojis ++++++"}
              line1={"Searching the blockchain to see if you have a RMX pass"}
              spin={true}
            />
          </div>
        </Card>
      </Col>
      <section
        className={"d-flex justify-content-center remove-button-container"}
      >
        <RmxButton
          title={"remove selection"}
          action={props.onClearCanvasSelection}
          disabled={selectedElements.length === 0}
          className={`trait-remove ${showOverlay() ? "blur" : ""}`}
          icon={determineTrashIcon()}
        />
        {showOverlay() && <Overlay />}
      </section>
    </div>
  );
}
