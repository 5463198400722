import { Face, Part, Trait } from "../../../../types";
import { Category } from "../../../../types";
import { useEffect, useState } from "react";
import { getFeatures } from "../../config/api/rmx-api";
import { DEFAULT_FACE } from "../../config/api/load-static-assets";

export type RmxConsoleUIModel = {
  ready: boolean; // ready is true when all data is loaded and state is set up
  face: Face; // the current face
  setFace: (face: Face) => void;
  setCategory: (category: Category) => void; // sets the selected category, used for UI
  category: Category; // currently selected category
  parts: Part[]; // a set of parts for the currently-selected category
  selectedPartId?: number; // id of the currently selected part, if any; used to render UI
};

/**
 * returns an useRmxConsoleUIModel that encapsulates:
 *   - UI control state (e.g. selected face, selected Parts category)
 *   - fetches the faces and traits for use in the UI controls
 */
export const useRmxConsoleUIModel = (traitCategoryMap): RmxConsoleUIModel => {
  const [ready, setReady] = useState<boolean>(false);
  const [emojis, setEmojis] = useState<Part[]>([]);
  const [features, setFeatures] = useState<Trait[]>([]);
  const [category, setCategory] = useState<Category>("eyes");
  const [parts, setParts] = useState<Part[]>([]);
  const [face, setFace] = useState<Part>(DEFAULT_FACE);

  useEffect(() => {
    const fetch = async () => {
      const featureRecords = await getFeatures();
      setFeatures(featureRecords);

      //todo: consider moving this initialization
      // defaults initial load to the faces
      // setParts(baseEmojis);
    };
    fetch().catch((error) => {
      console.log("Error fetching base emojis and features", error);
    });
  }, [face]);

  useEffect(() => {
    if (features.length) {
      setReady(true);
    }
  }, [emojis, features]);

  useEffect(() => {
    setParts(
      features.filter((feature) => {
        return (
          feature.category === category &&
          (!feature.faceId || feature.faceId === face.id)
        );
      })
    );
  }, [category, setParts, features]);

  const partObj = category === "face" ? { selectedPartId: face.id } : {};
  return {
    ready,
    face,
    setFace,
    category,
    setCategory,
    parts: parts,
    ...partObj,
  };
};
