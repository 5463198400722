import React from "react";
import { FrontendConfig } from "../../../config/api/types";
import { NoMoreMintsContent } from "../MintPageUncursed/MintPageUncursed";

interface NoMintLeftProps {
  frontEndConfig: FrontendConfig;
}

export const NoMintLeft = ({ frontEndConfig }: NoMintLeftProps) => {
  return (
    <NoMoreMintsContent collectionUrl={frontEndConfig.genesisCollectionUrl} />
  );
};
