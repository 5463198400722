import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { CursedEmojis } from "./components/CursedEmojis/CursedEmojis";
import { getFrontendConfig } from "./config/api/rmx-api";
import { FrontendConfig } from "./config/api/types";
import { useErrorHandler } from "react-error-boundary";
import RmxConsoleContainer from "./components/RmxConsole/RmxConsoleContainer";
import { Collection } from "./components/Collection/Collection";

function App() {
  const handleError = useErrorHandler();
  const [appReady, setAppReady] = useState(false);
  const [frontendConfig, setFrontendConfig] = useState<FrontendConfig>({
    maxMintsPerWallet: 0,
    premintUrl: "",
    genesisCollectionUrl: "",
    genesisMintContractAddress: "",
    etherScanUrl: "",
    mintPhase: "pre-mint",
    remixPhase: "pre-remix",
    auctionPhase: "pre-auction",
    traitCategoryMap: {
      eyes: "",
      mouths: "",
      options: "",
      bodyparts: "",
    },
  });

  useEffect(() => {
    const fetchConfig = async () => {
      getFrontendConfig()
        .then((config) => {
          console.log("Config Loaded:", config);
          setFrontendConfig(config);
          setAppReady(true);
        })
        .catch((error) => handleError(error));
    };
    fetchConfig();
  }, []);

  return (
    <div className="App position-relative">
      <BrowserRouter>
        <Routes>
          <Route
            path="/RMXconsole"
            element={
              <RmxConsoleContainer
                ready={appReady}
                disabled={frontendConfig.remixPhase !== "open-remix"}
                frontendConfig={frontendConfig}
              />
            }
          />
          <Route
            path="/collection"
            element={<Collection frontEndConfig={frontendConfig} />}
          />
          <Route
            path="/*"
            element={
              <CursedEmojis ready={appReady} frontendConfig={frontendConfig} />
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
