import { useEffect, useState } from "react";

export const ResizeHook = (window: Window, screenCutOffSize: number) => {
  const [isDesktop, setIsDesktop] = useState(false);

  const componentToDisplay = () => {
    const width = window.innerWidth;
    if (width < screenCutOffSize) {
      setIsDesktop(false);
    } else {
      return setIsDesktop(true);
    }
  };

  useEffect(() => {
    componentToDisplay();
    window.addEventListener("resize", componentToDisplay);
    return () => {
      window.removeEventListener("resize", componentToDisplay);
    };
  });

  return { isDesktop };
};
