import React, { ReactElement, useEffect } from "react";
import "./TraitCounter.scss";
import { Face, Part } from "../../types";
import { nftUrlOpenSea } from "../utils/urlOpenSeaParser";
import { FrontendConfig } from "../../config/api/types";
import { ResizeHook } from "../../hooks/resizeHook";

export interface TraitCounterProps {
  usedTraits: string[];
  max: number;
  face: Face;
  frontendConfig: FrontendConfig;
  selectedElements: Part[];
  allUsedTraits: Part[];
}

const TraitCounter = ({
  usedTraits,
  max,
  face,
  frontendConfig,
  selectedElements,
  allUsedTraits,
}: TraitCounterProps) => {
  const TOKENBASENAME = "RMX PASS: EMOJI BASE";

  const { isDesktop } = ResizeHook(window, 790);
  const prevAllUsedTraits = React.useRef(allUsedTraits);

  const TRAITCOLLECTIONURL =
    "https://opensea.io/assets/ethereum/0xc96db8a6d30f6c752081db6156e8316e5f96fa60/";
  const TRAITCOLLECTIONID = "0xc96db8a6d30f6c752081db6156e8316e5f96fa60";

  useEffect(() => {
    prevAllUsedTraits.current = allUsedTraits;
  }, [allUsedTraits]);

  const adaptNftNameToScreenSize = (name) => {
    if (name) {
      return isDesktop ? (
        <>NFT: {name.toUpperCase()}</>
      ) : (
        <>NFT: {name.toUpperCase().slice(0, 15)}…</>
      );
    }
  };

  const nftNameAndLink = (
    nftName: ReactElement,
    nftUrl: string,
    nftLinkText
  ) => {
    return (
      <>
        <span>{nftName}</span>
        <span>
          <a href={nftUrl} target={"_blank"}>
            {nftLinkText}
          </a>
        </span>
      </>
    );
  };

  const nftNameToDisplay = () => {
    if (usedTraits.length === 0) {
      return nftNameAndLink(
        adaptNftNameToScreenSize(TOKENBASENAME),
        nftUrlOpenSea(
          frontendConfig.genesisCollectionUrl,
          frontendConfig.genesisMintContractAddress,
          face.tokenId.toString()
        ),
        openSeaLinkName(frontendConfig.genesisMintContractAddress, face.tokenId)
      );
    }

    if (selectedElements && selectedElements.length > 0) {
      if (selectedElements.length > 1) {
        return (
          <>
            <span>{adaptNftNameToScreenSize("MULTIPLE SELECTED")}</span>
          </>
        );
      }
      return nftNameAndLink(
        adaptNftNameToScreenSize(selectedElements[0].name),
        `${TRAITCOLLECTIONURL}${selectedElements[0].tokenId}`,
        openSeaLinkName(TRAITCOLLECTIONID, selectedElements[0].tokenId)
      );
    }

    if (allUsedTraits.length > prevAllUsedTraits.current.length) {
      return nftNameAndLink(
        adaptNftNameToScreenSize(allUsedTraits.at(-1).name),
        `${TRAITCOLLECTIONURL}${allUsedTraits.at(-1).tokenId}`,
        openSeaLinkName(TRAITCOLLECTIONID, allUsedTraits.at(-1).tokenId)
      );
    }

    return nftNameAndLink(
      adaptNftNameToScreenSize(TOKENBASENAME),
      nftUrlOpenSea(
        frontendConfig.genesisCollectionUrl,
        frontendConfig.genesisMintContractAddress,
        face.tokenId.toString()
      ),
      openSeaLinkName(frontendConfig.genesisMintContractAddress, face.tokenId)
    );
  };

  const openSeaLinkName = (url: string, tokenId: string) => {
    return isDesktop ? (
      <>
        {url}/{tokenId}
      </>
    ) : (
      <>
        {url.slice(0, 4)}…{url.slice(-2)}/{tokenId}
      </>
    );
  };

  const isGreenBackground = () => {
    return usedTraits.length >= max ? "green" : "";
  };

  return (
    <section className={"d-flex justify-content-center traits-container"}>
      <section className={"traits-box d-flex flex-column flex-fill"}>
        {face.tokenId && (
          <>
            <section className={"d-flex justify-content-between"}>
              {nftNameToDisplay()}
            </section>
            <span
              className={`counter ${isGreenBackground()} d-flex align-content-center`}
            >
              {`${usedTraits.length}/${max}`} TRAITS IN USE
            </span>
          </>
        )}
      </section>
    </section>
  );
};

export default TraitCounter;
