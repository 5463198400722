import { Col } from "react-bootstrap";
import "./Footer.scss";
import React from "react";
import { RmxLogo } from "../../CursedEmojis/Footer/RmxLogo";

const year = new Date().getFullYear();

export const Footer = () => {
  return (
    <Col
      className={
        "rmx-console-footer d-flex flex-column align-items-center text-center"
      }
    >
      <div className={"d-flex flex-column justify-content-center logo-section"}>
        <span>{RmxLogo()}</span>
        <span className={"logo-text mx-auto"}>
          Art by{" "}
          <a
            href={
              "https://mirror.xyz/rmxparty.eth/KoLrhfPsd80LGg2O9nKSy6W-LXcht3XHpNu4KflUz_M"
            }
            target={"_blank"}
          >
            yung jake
          </a>
        </span>
        <hr className={"bar"} id="footer-hr" />
        <span className="copyright mx-auto">RMX (c){year}</span>
      </div>
    </Col>
  );
};
