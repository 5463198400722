import { getAxiosClient } from "./axios-client";
import {
  CreateCursedEmojiStatus,
  FrontendConfig,
  MediaCheck,
  NftStatusCheck,
  WalletStatus,
} from "./types";
import { Face, Trait } from "../../../../types";
import { AxiosResponse } from "axios";

import { loadStaticFaces, loadStaticFeatures } from "./load-static-assets";
import { checkFileExistsWithRetryAndDelay } from "./check-file-exists-with-retry-and-delay";
import { fetchStatusWithDelay } from "./fetch-status-with-delay";
import { fetchNftStatusWithRetryAndDelay } from "./fetch-nft-status-with-retry-and-delay";
import { GenesisMintUIMetadata } from "../../types";

export const GENESIS_MINT_RETRIES = 10;
export const GENESIS_MINT_DELAY_BETWEEN_RETRIES = 5000; // in millis

export const GENESIS_IMG_CHECK_RETRIES = 5;
export const GENESIS_IMG_CHECK_DELAY_BETWEEN_RETRIES = 3000; // in millis

export const getFrontendConfig = async (): Promise<FrontendConfig> => {
  return getAxiosClient()
    .get(`/feconfig`, {})
    .then((response) => {
      return response.data;
    });
};

export type MintCountInfo = {
  totalMinted: number;
  maximumMints: number;
};
// TODO: look into caching this response, and also polling for updates during
// the minting process
export const getMintCountInfo = async (): Promise<MintCountInfo> => {
  return await getAxiosClient()
    .get(`/contract/minted_count`, {})
    .then((result) => {
      return result.data;
    });
};

export const getWalletStatus = async (
  walletId: string
): Promise<WalletStatus> => {
  return getAxiosClient()
    .get(`/wallet/${walletId}`, {})
    .then((result) => {
      return result.data;
    });
};

export interface RequestCurseResponse {
  alreadyCursed: boolean;
  pendingCurseTxHash: string;
  genesisMintContractAddress: string;
  error?: string;
}

/**
 * sends request to server to add provided walletAddress to the OCAL
 * if the return value's 'alreadyCursed' field is true, then no need to wait
 * on the transaction.
 * @param walletAddress
 */
export const requestCurse = async (
  walletAddress: string
): Promise<RequestCurseResponse> => {
  let requestCurseResponse: RequestCurseResponse = {
    alreadyCursed: false,
    genesisMintContractAddress: "",
    pendingCurseTxHash: "",
  };
  return getAxiosClient()
    .post<RequestCurseResponse>(`/wallet/${walletAddress}/curse_request`, {})
    .then((result) => {
      if (result.data.error) {
        console.log(
          `curse request failed for wallet ${walletAddress}: ${result.data.error}`
        );
        throw Error(result.data.error);
      }
      requestCurseResponse = result.data;
      return requestCurseResponse;
    })
    .catch((error) => {
      console.log(
        `curse request failed for wallet ${walletAddress}: ${error as Error}`
      );
      throw Error(error);
    });
};

export interface GenesisMintDetails extends MediaCheck {
  nftTxnHash: string;
  openSeaLink?: string; //todo: server should be able to provide this
}

export const createBlankEmojiAssets = async (
  transactionHash: string
): Promise<GenesisMintDetails> => {
  const axios = getAxiosClient();
  console.log(
    "requesting NFT asset creation from server for mint:",
    transactionHash
  );

  const postResult = await axios.post<MediaCheck>(
    `/genesis/${transactionHash}`,
    { transactionHash },
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  const receipt = postResult.data;
  console.log("NFT asset receipt:", receipt);

  // ensure that the image file is avaiable to the browser
  await checkFileExistsWithRetryAndDelay({
    client: axios,
    fileUri: receipt.imgUri,
    delayInMillis: GENESIS_IMG_CHECK_DELAY_BETWEEN_RETRIES,
    retries: GENESIS_IMG_CHECK_RETRIES,
  });
  return { ...receipt, nftTxnHash: transactionHash };
};

export interface CreateRemixedAssetsArgs {
  tokenId: number;
  remixTransactionHash: string;
  fromWallet: string;
  imageBuffer: Blob;
  fabricJSON: unknown;
}

export const createRemixedAssets = async ({
  tokenId,
  remixTransactionHash,
  fromWallet,
  imageBuffer,
  fabricJSON,
}: CreateRemixedAssetsArgs): Promise<MediaCheck> => {
  //convert data to form data for posting
  const fd = new FormData();
  fd.set("tokenId", tokenId.toString());
  fd.set("remixTransactionHash", remixTransactionHash);
  fd.set("fromWallet", fromWallet);
  fd.set("image", imageBuffer, "cursed.png");
  fd.set("fabricJSON", JSON.stringify(fabricJSON));

  const axios = getAxiosClient();

  const postResult = await axios.post<MediaCheck>(`/remix`, fd, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  const receipt = postResult.data;
  console.log("NFT asset receipt:", receipt);

  // ensure that the image file is avaiable to the browser
  await checkFileExistsWithRetryAndDelay({
    client: axios,
    fileUri: receipt.imgUri,
    delayInMillis: GENESIS_IMG_CHECK_DELAY_BETWEEN_RETRIES,
    retries: GENESIS_IMG_CHECK_RETRIES,
  });
  return { ...receipt };
};

export type CreateBlankEmojiArgs = {
  transactionHash: string;
  walletAddress: string;
};

export const createBlankEmoji = async (
  paymentTx: string,
  walletId: string
): Promise<CreateCursedEmojiStatus> => {
  const data: CreateBlankEmojiArgs = {
    transactionHash: paymentTx,
    walletAddress: walletId,
  };

  const axios = getAxiosClient();

  console.log("about to post the mint request");

  const postResult = axios.post(`/blankemoji`, data, {
    headers: { "Content-Type": "application/json" },
  });

  const content = await postResult;

  const status = fetchNftStatusWithRetryAndDelay({
    client: axios,
    statusToken: content.data,
    retries: GENESIS_MINT_RETRIES,
    delayInMillis: GENESIS_MINT_DELAY_BETWEEN_RETRIES,
  });

  const nftStatus = (await status) as CreateCursedEmojiStatus;

  // ensure that the image file is avaiable to the browser
  await checkFileExistsWithRetryAndDelay({
    client: axios,
    fileUri: nftStatus.imgUri,
    delayInMillis: GENESIS_IMG_CHECK_DELAY_BETWEEN_RETRIES,
    retries: GENESIS_IMG_CHECK_RETRIES,
  });

  return nftStatus;
};

/**
 * createCursedEmoji
 *
 * NOTE: This function has an artificial delay that waits 30 seconds before fetching the minted emoji status
 * This is done to circumvent the heroku 30-second max timout issue.
 * This should be refined to use a polling technique instead.
 *
 * @param paymentTx
 * @param walletId
 * @param emojiBlob
 * @param millisToWaitBeforeGettingUrl  default is 30000ms
 */
export const createCursedEmoji = async (
  paymentTx: string,
  walletId: string,
  emojiBlob: Blob,
  millisToWaitBeforeGettingUrl = 30000
): Promise<CreateCursedEmojiStatus> => {
  const fd = new FormData();
  fd.set("paymentTx", paymentTx);
  fd.set("walletId", walletId);
  fd.set("image", emojiBlob, "cursed.png");

  const axios = getAxiosClient();

  const token = await axios
    .post(`/cursedemoji`, fd, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((result: AxiosResponse<NftStatusCheck>) => {
      return result.data;
    });

  const status = await fetchStatusWithDelay(
    axios,
    token,
    millisToWaitBeforeGettingUrl
  );

  return (await status) as CreateCursedEmojiStatus;
};

export const getMetadataFromUrl = async (
  url: string
): Promise<GenesisMintUIMetadata> => {
  console.log("getMetadaFromUrl", url);
  const axios = getAxiosClient();
  return axios.get(url).then((metadataResult) => {
    return metadataResult.data;
  });
};

export const getBaseEmojis = async (): Promise<Face[]> => loadStaticFaces();

export const getFeatures = async (): Promise<Trait[]> => loadStaticFeatures();
