import React from "react";
import { MintCountDown } from "../MintCountDown";
import { MintCountInfo } from "../../../config/api/rmx-api";
import { LiveCountdownClock } from "../LiveCountdownClock";
import { TableBox } from "../TableBox";
import { ColorLight } from "../ColorLight";
import { FrontendConfig } from "../../../config/api/types";
import { REMIX_OPEN_DATE } from "../../../config/dates";

export interface MintPageUncursedProps {
  mintCountInfo: MintCountInfo;
  frontEndConfig: FrontendConfig;
}

function JoinAllowListContent() {
  return (
    <div className={"mint-content-card"}>
      <div className="d-flex flex-row header">
        <ColorLight color={"yellow"} />
        <div
          className={
            "content w-100 d-flex justify-content-center align-items-center"
          }
        >
          <span className="px-2">HʘW TO GET A MINT PASS</span>
        </div>
        <ColorLight color={"yellow"} />
      </div>
      <div className="content copy">
        <p>
          You’ll need a RMX Pass to gain access to our RMX Console. This is the
          first step in creating your own Cursed Emoji with artist yung jake.
        </p>
        <p>
          Join us on{" "}
          <a href={"https://twitter.com/rmx_party"} target={"_blank"}>
            twitter
          </a>{" "}
          or look for a{" "}
          <a
            href={"https://opensea.io/collection/cursedemojis"}
            target={"_blank"}
          >
            RMX Pass to purchase!
          </a>
        </p>
      </div>
    </div>
  );
}

function JoinAllowListInfoBlock(props: { mintCountInfo: MintCountInfo }) {
  return (
    <section style={{ maxWidth: "512px" }}>
      <TableBox
        keys={["Access:", "Price:", "Limit:", "Remix In:", "Mints Left:"]}
        values={[
          <>
            {" "}
            Only Open to{" "}
            <a
              href="https://www.premint.xyz/site-cursed-emojis-co-create-art/"
              target="_blank"
            >
              Allowlist
            </a>
          </>,
          "Free to Mint",
          "1 Mint per Wallet",
          <LiveCountdownClock date={REMIX_OPEN_DATE} />,
          <MintCountDown mintCountInfo={props.mintCountInfo} />,
        ]}
        leftWidth={"medium"}
      />
    </section>
  );
}

export function NoMoreMintsContent(collectionUrl) {
  return (
    <div className={"mint-content-card"}>
      <div className="d-flex flex-row header">
        <ColorLight color={"red"} />
        <div
          className={
            "content w-100 d-flex justify-content-center align-items-center"
          }
        >
          <span className="px-2">MiNT SOLD OÜT!</span>
        </div>
        <ColorLight color={"red"} />
      </div>
      <div className="content copy">
        <p>
          Curses! All RMX Passes have been secured. You’ll need a RMX Pass:
          Emoji Base to gain access to the RMX Console, opening January 19.
        </p>
        <p>
          Still want in?{" "}
          <a
            href={"https://opensea.io/collection/cursedemojis"}
            target={"_blank"}
          >
            Find a RMX Pass available for purchase.
          </a>
        </p>
      </div>
    </div>
  );
}

export function NoMoreMintsInfoBlock(props: { mintCountInfo: MintCountInfo }) {
  return (
    <div className={"mint-content-card dimmed"} style={{ maxWidth: "512px" }}>
      <div className={"d-flex table"}>
        <div className={"d-flex flex-column flex-fill justify-content-between"}>
          <div className={"content flex-grow-1"}>Remix In:</div>
          <div className={"content flex-grow-1"}>Mints Left:</div>
        </div>
        <div className={"flex-grow-1 d-flex flex-column"}>
          <div className={"content flex-grow-1"}>
            <LiveCountdownClock date={new Date(2023, 0, 19, 16)} />
          </div>
          <div className={"content p-1"}>
            <MintCountDown mintCountInfo={props.mintCountInfo} />
          </div>
        </div>
      </div>
    </div>
  );
}

const MintPageUncursed = ({
  mintCountInfo,
  frontEndConfig,
}: MintPageUncursedProps) => {
  const noMoreMints = mintCountInfo.totalMinted >= mintCountInfo.maximumMints;
  return (
    <div className="mint-page uncursed">
      {noMoreMints ? (
        <>
          <NoMoreMintsContent
            collectionUrl={frontEndConfig.genesisCollectionUrl}
          />
          <NoMoreMintsInfoBlock mintCountInfo={mintCountInfo} />
        </>
      ) : (
        <>
          <JoinAllowListContent />
          <JoinAllowListInfoBlock mintCountInfo={mintCountInfo} />
        </>
      )}
    </div>
  );
};

export default MintPageUncursed;
