import React, { useState } from "react";
import {
  CreateCursedEmojiStatus,
  FrontendConfig,
} from "../../config/api/types";
import { LiveCountdownClock } from "./LiveCountdownClock";
import {
  GenesisMintMetadataAttribute,
  GenesisMintUIMetadata,
} from "../../types";
import opensea from "../LandingPage/landing/images/footer/opensea.svg";
import geneva from "../LandingPage/landing/images/footer/geneva.svg";
import CursedEmojisNav from "./CursedEmojisNav/CursedEmojisNav";
import "./FinishedMintedEmoji.scss";
import {
  getIconForTraitType,
  renderTraitDate,
} from "../utils/metadataReaderUtil";
import { nftUrlOpenSea, parseNftId } from "../utils/urlOpenSeaParser";
import questionMark2 from "../../images/icon-question.svg";
import yellowButton from "../../images/YellowButton.svg";
import { Link } from "react-router-dom";
import downloadIcon from "../../images/downloadIcon.svg";
import blueHandDown from "../../images/bodyparts-blue-shh-hand-down.png";
import MyModal from "../Modal";
import { MINT_OPEN_DATE, REMIX_OPEN_DATE } from "../../config/dates";

type FinishedMintedEmojiProps = {
  creationStatus: CreateCursedEmojiStatus;
  mintedNftMetadata: GenesisMintUIMetadata | undefined;
  frontEndConfig: FrontendConfig;
};
export const FinishedMintedEmoji = ({
  creationStatus,
  mintedNftMetadata,
  frontEndConfig,
}: FinishedMintedEmojiProps) => {
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  if (!frontEndConfig) return <p>Missing app configuration data...</p>;
  if (!mintedNftMetadata) return <p>Missing token metadata...</p>;

  const toggleShowHowto = () => {
    setShowInfoModal((prevState) => !prevState);
  };

  return (
    <>
      <div className={"mint-content"}>
        <div className={"genesis-subnav text-center"}>
          <CursedEmojisNav />
        </div>

        <div className={"mint-content-card"}>
          <header className="d-flex flex-row justify-content-between header">
            <img src={yellowButton} className="content yellow-button" />
            <div
              className={
                "text-center content w-100 d-flex align-items-center justify-content-center"
              }
            >
              <p className="title-text">
                Y🄞LO ALERT: RMX CONSOLE UNLOCKS JANUAƦY 19
              </p>
            </div>
            <img src={yellowButton} className="content yellow-button" />
          </header>
          <div className={"content copy"}>
            <p>
              Congratulations! You’ve minted your RMX Pass: Emoji Base. This NFT
              is a key that unlocks the RMX Console. Come back to make your
              Cursed Emoji on January 19!
            </p>
          </div>
          <div className={"d-flex table "}>
            <div
              className={"d-flex align-items-center justify-content-between"}
            >
              <p className={"content h-100 remix-in-text"}>Remix in:</p>
            </div>
            <div
              className={
                "d-flex flex-column my-0 w-100 justify-content-between"
              }
            >
              <div className={"content"}>
                <section className={"clock-container"}>
                  <LiveCountdownClock date={REMIX_OPEN_DATE} />
                </section>
              </div>
            </div>
          </div>
          <div className={"content"}>
            <div className="d-flex align-content-center justify-content-center">
              <Link
                to="/CalendarInvite/rmx-cursed-emoji-calendar.ics"
                target="_blank"
                download
              >
                Save reminder: Jan 19 11AM EST
                <img src={downloadIcon} className={"link-icon"} />
              </Link>
            </div>
          </div>
        </div>

        <div
          className={"mint-content-card mx-auto d-flex justify-content-center"}
          style={{ maxWidth: "420px", overflow: "hidden" }}
        >
          <img
            className={"nft-image"}
            src={creationStatus.imgUri}
            alt={"Genesis Mint base"}
          />
        </div>
        <section className={"d-flex justify-content-center"}>
          <img className={"hand"} src={blueHandDown} />
        </section>
        <section className={"info-container"}>
          <div className={"mint-content"}>
            <div className="mint-content-card">
              <div className="content d-flex flex-row justify-content-between">
                <div className={"text-left"}>Your Cursed Genealogy</div>
                <button
                  className={"button-helper d-flex justify-content-center"}
                  onClick={() => {
                    setShowInfoModal(true);
                  }}
                >
                  <img
                    src={questionMark2}
                    className={"question-icon"}
                    alt={"open genealogy modal"}
                    title={"open genealogy modal"}
                  />
                </button>
              </div>

              <div
                className={
                  "table d-flex flex-row align-items-stretch mb-0 text-capitalize"
                }
              >
                <div className={"d-flex flex-column align-content-stretch"}>
                  {mintedNftMetadata &&
                    mintedNftMetadata.attributes.map(
                      (
                        attribute: GenesisMintMetadataAttribute,
                        index: number
                      ) => {
                        return (
                          <div
                            className="content trait-icon"
                            key={`metadata-attribute-key${index}`}
                          >
                            <img
                              src={getIconForTraitType(attribute)}
                              alt={attribute.trait_type}
                              title={attribute.trait_type}
                            />
                          </div>
                        );
                      }
                    )}
                </div>
                <div
                  className={
                    "d-flex flex-column my-0 flex-fill justify-content-between"
                  }
                >
                  {mintedNftMetadata &&
                    mintedNftMetadata.attributes.map(
                      (attribute: GenesisMintMetadataAttribute, i: number) => {
                        if (attribute.display_type === "date") {
                          return (
                            <div
                              className="content flex-grow-1 d-flex align-items-center"
                              key={`metadata-attribute-value${i}`}
                            >
                              {renderTraitDate(attribute)}
                            </div>
                          );
                        }
                        return (
                          <div
                            className="content flex-grow-1 d-flex align-items-center"
                            key={`metadata-attribute-value${i}`}
                          >
                            {attribute.value}
                          </div>
                        );
                      }
                    )}
                </div>
              </div>

              <div className="content text-center">
                <a
                  href={nftUrlOpenSea(
                    frontEndConfig.genesisCollectionUrl,
                    frontEndConfig.genesisMintContractAddress,
                    parseNftId(mintedNftMetadata.image)
                  )}
                  target="_blank"
                >
                  View on OpenSea
                  <img alt="OPENSEA" src={opensea} className={"link-icon"} />
                </a>
              </div>
            </div>
          </div>

          <div className={"text-center image-container"}>
            <img
              className={"dance chromatic-ignore"}
              src={"https://cursedemojis.world/assets/media/CE-dancing-256.gif"}
              alt={"cursed emojis dance"}
            />
          </div>
          <div className="mint-content-card">
            <div className="content text-center">
              <a
                href="https://app.geneva.com/invite/6626ed36-79db-41d3-94e5-68a2163345e6"
                target="_blank"
              >
                Get to know us at Café RMX on Geneva
                <img alt="GENEVA" src={geneva} className={"link-icon"} />
              </a>
            </div>
          </div>
        </section>
      </div>
      <MyModal
        title={"† GENEALOGY GLOSSARY †"}
        show={showInfoModal}
        onHide={toggleShowHowto}
        primaryActionHandler={toggleShowHowto}
        primaryActionLabel={"OKAY"}
      >
        <section className={"modal-info"}>
          <p>
            🟡🔴🔵🥔 <b>RMX Pass:</b> Emoji Base: An Emoji Base serves as both a
            pass and the underlying “face” of the Cursed Emoji you will create
            in the RMX Console. When you mint a pass, you’ll receive one of four
            randomized colors for your Emoji Base: Old Yeller, Big Red, True
            Blue, or Yukon Gold.
          </p>
          <p>
            🧪 <b>Temperament:</b> A description of your Cursed Emoji’s innate
            personality.
          </p>
          <p>
            🧬 <b>Incarnation:</b> When you remix your Emoji Base to create a
            Cursed Emoji, the smart contract and metadata of your NFT will
            update, thus advancing your NFT into its second incarnation.
          </p>
          <p>
            🎂 <b>Birthday:</b> The day your Emoji Base is minted.
          </p>
          <p>
            For more info, see{" "}
            <a href={"/about"} target={"_blank"}>
              our FAQs.
            </a>
          </p>
        </section>
      </MyModal>
    </>
  );
};
