import React, { useReducer } from "react";

export type State =
  | "loading"
  | "chooseBaseEmoji"
  | "addFeatures"
  | "waitingWalletConfirmation"
  | "waitingForMint"
  | "mintComplete";
export type Event =
  | "FINISHED_LOADING"
  | "BASE_EMOJI_SELECTED"
  | "CONFIRM_MINT"
  | "MINT"
  | "MINT_COMPLETE";

type EventSet = {
  [key in Event as string]: State;
};

type stateMachine = {
  [key in State as string]: EventSet;
};

const STATE_MACHINE: stateMachine = {
  loading: {
    FINISHED_LOADING: "chooseBaseEmoji",
  },
  chooseBaseEmoji: {
    BASE_EMOJI_SELECTED: "addFeatures",
  },
  addFeatures: {
    CONFIRM_MINT: "waitingWalletConfirmation",
  },
  waitingWalletConfirmation: {
    MINT: "waitingForMint",
    BASE_EMOJI_SELECTED: "chooseBaseEmoji",
  },
  waitingForMint: {
    MINT_COMPLETE: "mintComplete",
  },
  mintComplete: {},
};

const reducer = (s: State, e: Event): State => {
  const nextState = STATE_MACHINE[s][e];
  return nextState !== undefined ? nextState : s;
};

export const useRmxConsoleState = (
  initialState: State = "loading"
): [State, React.Dispatch<Event>] => {
  const [state, send] = useReducer(reducer, initialState);
  return [state, send];
};
