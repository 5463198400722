import { MintedEmojiDisplay } from "./MintedEmojiDisplay";
import React from "react";
import "./NFTReveal.scss";
import "../CursedEmojis/MintContent.scss";
import "../CursedEmojis/FinishedMintedEmoji.scss";
import {
  CreateCursedEmojiStatus,
  FrontendConfig,
} from "../../config/api/types";
import { CursedHeader } from "../LandingPage/CursedHeader";
import { SimpleWhiteSquareBgText } from "./SimpleWhiteSquareBgText";
import { WhiteButtonWithBorder } from "./WhiteButtonWithBorder";
import collectionIcon from "../../images/collection-icon.svg";
import shareIcon from "../../images/share-icon.svg";
import downloadIcon from "../../images/downloadIcon.svg";
import { OwnedNft } from "alchemy-sdk";
import { GenesisMintUIMetadata } from "../../types";
import { RefreshOpenSeaExplanation } from "./RefreshOpenSeaExplanation";
import { nftUrlOpenSea } from "../utils/urlOpenSeaParser";
import CursedEmojisNav from "../CursedEmojis/CursedEmojisNav/CursedEmojisNav";
import { CursedEmojiTabHeader } from "../CursedEmojis/CursedEmojiTabHeader/CursedEmojiTabHeader";
import openSecIcon from "../LandingPage/landing/images/footer/opensea.svg";
import { CursedEmojiFooter } from "../CursedEmojis/Footer/CursedEmojiFooter";

interface NFTRevealProps {
  cursedEmojiCreationStatus: CreateCursedEmojiStatus;
  token: OwnedNft | null;
  frontEndConfig: FrontendConfig;
  newMetadata: GenesisMintUIMetadata | undefined;
  usedTraits: string[];
}

const NFTReveal = ({
  cursedEmojiCreationStatus,
  token,
  frontEndConfig,
  newMetadata,
  usedTraits,
}: NFTRevealProps) => {
  const buildTwitterShareLink = (
    text: string,
    mentions: string,
    hashtags: string,
    url: string | undefined
  ) => {
    // twitter docs: https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/overview
    //the hashtags need to be a string separated with commas without #
    //the mentions '@address' need to be added to the text string
    const textWithoutSpace = text.replace(" ", "%20");
    const mentionsWithoutSpace = mentions.replace(" ", "%20");
    const cleanHashtags = hashtags.replace("#", "");

    if (url) {
      return (
        "https://twitter.com/intent/tweet?text=" +
        textWithoutSpace +
        "%20" +
        mentionsWithoutSpace +
        "&hashtags=" +
        cleanHashtags +
        "&url=" +
        url
      );
    }
  };

  return (
    <div className={"bg-white d-flex flex-column mint-receipt"}>
      <section className={"nft-reveal align-items-center  d-flex flex-column "}>
        <CursedEmojiTabHeader />
        <div className={"genesis-subnav text-center"}>
          <CursedEmojisNav />
        </div>
        <div className={"text-center"}>
          <img
            src={"https://cursedemojis.world/assets/media/congrats.gif"}
            alt={"congratulation for minting"}
            className={"congrat chromatic-ignore"}
          />
        </div>
        <MintedEmojiDisplay
          frontEndConfig={frontEndConfig}
          newMetadata={newMetadata}
          usedTraits={usedTraits}
          cursedEmojiCreationStatus={cursedEmojiCreationStatus}
        />

        <RefreshOpenSeaExplanation />
        <section className="w-100 d-flex flex-column gap-2">
          <WhiteButtonWithBorder>
            <a
              className={"button-link"}
              href={nftUrlOpenSea(
                frontEndConfig.genesisCollectionUrl,
                frontEndConfig.genesisMintContractAddress,
                token!.tokenId.toString()
              )}
              target={"_blank"}
            >
              View Your Remix (Don’t Forget to Refresh Metadata!)
              <img src={openSecIcon} className={"link-icon"} />
            </a>
          </WhiteButtonWithBorder>
          <WhiteButtonWithBorder>
            <a
              className={"button-link"}
              target={"_blank"}
              href={buildTwitterShareLink(
                "My 𝕮urse is on the blockchain 4eva",
                "@RMX_PARTY @yungjake",
                "#cursedemoji",
                nftUrlOpenSea(
                  frontEndConfig.genesisCollectionUrl,
                  frontEndConfig.genesisMintContractAddress,
                  token!.tokenId.toString()
                )
              )}
            >
              Share
              <img src={shareIcon} className={"link-icon"} />
            </a>
          </WhiteButtonWithBorder>
          <WhiteButtonWithBorder>
            <a className={"button-link"} href={newMetadata!.image}>
              Download as Image
              <img src={downloadIcon} className={"link-icon"} />
            </a>
          </WhiteButtonWithBorder>
        </section>
      </section>
      <CursedEmojiFooter />
    </div>
  );
};
export default NFTReveal;
