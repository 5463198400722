import { RmxButton } from "../RmxButton";
import "./CTACursedEmoji.scss";

type BarVariant = "tall" | "short";

type CTACursedEmojiProps = {
  title: string;
  disabled: boolean;
  action: string | (() => void);
  variant?: BarVariant;
};

export const CTACursedEmoji = ({
  title,
  disabled,
  action,
  variant = "tall",
}: CTACursedEmojiProps) => {
  const button = () => {
    return (
      <RmxButton
        title={title}
        disabled={disabled}
        action={action}
        className={"primary cta"}
      />
    );
  };

  return (
    <div className={`cta-cursed-emoji ${variant} fixed-bottom`}>{button()}</div>
  );
};
