import "./PartsNav.scss";
import { Category, TraitCategoryMap } from "../../../../types";

export interface PartsNavProps {
  setCategory: (category: Category) => void;
  category: Category;
  traitCategoryMap: TraitCategoryMap;
  grayScaleActive: boolean;
}

export const PartsNav = ({
  setCategory,
  category,
  traitCategoryMap,
  grayScaleActive,
}: PartsNavProps) => {
  function activeCategoryClass(
    thisCategory: Category,
    activeCategory: Category
  ): string {
    if (grayScaleActive) return "";
    return thisCategory === activeCategory ? "active" : "";
  }

  const markupForFeatureCategories = (): JSX.Element[] => {
    const categories = traitCategoryMap && Object.keys(traitCategoryMap);

    return (
      categories &&
      categories.map((categoryIdentifier) => {
        return (
          <div
            key={categoryIdentifier}
            title={categoryIdentifier}
            className={`label ${activeCategoryClass(
              categoryIdentifier,
              category
            )} ${categoryIdentifier}`}
            onClick={() => {
              setCategory(categoryIdentifier);
            }}
          >
            <span>{traitCategoryMap[categoryIdentifier]}</span>
          </div>
        );
      })
    );
  };

  return (
    <div className={`parts-nav ${grayScaleActive ? "grayScale blur" : ""}`}>
      <menu>{markupForFeatureCategories()}</menu>
      <hr />
    </div>
  );
};
