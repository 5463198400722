import { fabric } from "fabric";
import { DOMUtils } from "../../dom-utils";
import { Trait } from "../../../../types";
import {
  AddFeatureResult,
  addFeatureToCanvas,
  canFeatureBeAdded,
  TraitList,
} from "./add-feature-to-canvas";
import { getMaxUniqueFeatures } from "../../config/rmx-console-config";
import { Part } from "../../types";

// This value is derived from the art assets;
// Current feature art is upscaled 3x from the 'ideal' size, so we scale it down
// when placing on canvas.
const DEFAULT_FEATURE_SCALING_FACTOR = 0.33;

export interface EmojiCanvasController {
  /**
   * adds the feature with image at imgUrl to canvas
   * @param imgUrl
   * @param widthScale an options value between 0 and 1. feature will be scaled by that fraction.
   */
  addFeature: (feature: Trait, widthScale?: number) => AddFeatureResult;
  clearAll: () => void;
  getEmojiAsFileBlob: () => Promise<Blob>;
  clearSelection: () => Part[];
  setFace: (imgUrl: string) => void;
  canvasJSON: () => string;
  canvas: fabric.Canvas;
}

export const CANVAS_ID = "emojicanvas";

function randomCoordinateFromCanvasCenter(canvas: fabric.Canvas) {
  return Math.round(Math.random() * (canvas.getCenter().top * 1.3));
}

export const fromCanvas = (canvas: fabric.Canvas): EmojiCanvasController => {
  return {
    addFeature: (
      feature: Trait,
      widthScale = DEFAULT_FEATURE_SCALING_FACTOR
    ) => {
      const result = canFeatureBeAdded(feature, canvas, getMaxUniqueFeatures());
      if (result.success) {
        fabric.Image.fromURL(
          feature.imgUrl,
          function (oImg) {
            addFeatureToCanvas(widthScale, oImg, feature, canvas);
          },
          {
            selectable: true,
            top: randomCoordinateFromCanvasCenter(canvas),
            left: randomCoordinateFromCanvasCenter(canvas),
            crossOrigin: "Anonymous",
          }
        );
      }
      return result;
    },
    clearAll: () => {
      canvas.remove(...canvas.getObjects());
    },
    getEmojiAsFileBlob: () => {
      canvas.discardActiveObject().renderAll();
      console.log("Canvas ID: ", CANVAS_ID);
      console.log("Canvas act: ", DOMUtils.getBlobableById(CANVAS_ID));
      return getCanvasBlob(
        DOMUtils.getBlobableById(CANVAS_ID) as HTMLCanvasElement
      );
    },
    clearSelection: () => {
      canvas.remove(...canvas.getActiveObjects());
      const allUsedTraits: Part[] = [];
      canvas.getObjects("image").forEach((value) => {
        allUsedTraits.push(value.data);
      });
      return allUsedTraits;
    },
    setFace: (imgUrl: string) => {
      setCanvasBackground(imgUrl, canvas);
    },
    canvasJSON: () => {
      return canvas.toJSON();
    },
    canvas: canvas,
  };
};

type Blobable = {
  toBlob: (
    callback: BlobCallback,
    type?: string | undefined,
    quality?: unknown
  ) => void;
};

async function getCanvasBlob(canvas: Blobable): Promise<Blob> {
  const emojiBlob: Blob | null = await new Promise((resolve) =>
    canvas.toBlob(resolve)
  );

  if (emojiBlob == null) {
    throw Error("There was a problem getting the data from the canvas element");
  }
  return emojiBlob;
}

function setCanvasBackground(baseEmoji: string, canvas: fabric.Canvas) {
  fabric.Image.fromURL(
    baseEmoji,
    (img) => {
      // import the image as it comes; don't force a size
      img.set({
        originX: "left",
        originY: "top",
      });
      img.scaleToWidth(canvas.width as number);
      canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
    },
    { crossOrigin: "anonymous" }
  );
}
