import logo from "../../LandingPage/landing/images/logo.svg";
import "./RmxLogo.scss";
import React from "react";

export function RmxLogo() {
  return (
    <header className={"cursed-header"}>
      <a href="https://www.rmx.party/" target={"_blank"}>
        <img src={logo} className="logo content" alt="RMX logo" />
      </a>
    </header>
  );
}
