import { Button, Modal } from "react-bootstrap";
import "./Modal.scss";
import * as React from "react";
import { RmxButton } from "./RmxButton";

type modalSize = "sm" | "lg" | "xl";

export interface MyModalProps {
  title: string;
  children?: React.ReactElement;
  show: boolean;
  onHide: () => void;
  primaryActionHandler?: () => void;
  primaryActionLabel?: string;
  disablePrimaryAction?: boolean;
  size?: modalSize;
}

/**
 * A modal.
 * If primaryActionHandler is provided, it will add a primary-styled button to
 * the footer.
 */
export default function MyModal({
  children,
  show,
  onHide,
  title,
  primaryActionHandler,
  primaryActionLabel,
  disablePrimaryAction,
  size,
}: MyModalProps) {
  return (
    <Modal
      show={show}
      className={"my-modal"}
      onHide={onHide}
      backdrop="static"
      centered
      size={size}
    >
      <Modal.Header closeButton>
        <div>{title}</div>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {primaryActionHandler && (
        <RmxButton
          action={primaryActionHandler}
          className={"primary"}
          disabled={!!disablePrimaryAction}
          title={primaryActionLabel}
        />
      )}
    </Modal>
  );
}
