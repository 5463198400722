import React from "react";
import "./TableBox.scss";

type WidthLeftBox = "small" | "medium";

interface TableBoxProps {
  keys: string[];
  values: (React.ReactElement | string)[];
  footer?: React.ReactElement;
  leftWidth?: WidthLeftBox;
}

export const TableBox = ({
  keys,
  values,
  footer,
  leftWidth = "small",
}: TableBoxProps) => {
  return (
    <div className={"mint-content-table dimmed"}>
      <div className={"d-flex table"}>
        <div className={`d-flex flex-column ${leftWidth}`}>
          {keys.map((elementKey, index) => {
            return (
              <div className={"content d-flex align-items-center"} key={index}>
                {elementKey}
              </div>
            );
          })}
        </div>
        <div className={"flex-grow-1 d-flex flex-column"}>
          {values.map((elementValue, index) => {
            return (
              <div
                className={"content flex-grow-1 d-flex align-items-center"}
                key={index}
              >
                {elementValue}
              </div>
            );
          })}
        </div>
      </div>
      {footer && (
        <footer className="content text-center d-flex align-items-center justify-content-center">
          {footer}
        </footer>
      )}
    </div>
  );
};
