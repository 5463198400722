import React from "react";
import { Image } from "react-bootstrap";
import YouAreCursed from "../../../images/YouAreCursed.svg";
import { MintCountDown } from "../MintCountDown";
import { MintCountInfo } from "../../../config/api/rmx-api";
import { TableBox } from "../TableBox";
import { ColorLight } from "../ColorLight";
import { FrontendConfig } from "../../../config/api/types";
import { NoMintLeft } from "./NoMintLeft";
import { LiveCountdownClock } from "../LiveCountdownClock";
import { REMIX_OPEN_DATE } from "../../../config/dates";
import { MintPageJustMinted } from "../MintPageJustMinted";

export interface MintPageCursedProps {
  mintCountInfo: MintCountInfo;
  frontEndConfig: FrontendConfig;
  hasUserAlreadyMinted: boolean;
}

const MintPageCursed = ({
  mintCountInfo,
  frontEndConfig,
  hasUserAlreadyMinted,
}: MintPageCursedProps) => {
  const noMintLeft = (): boolean => {
    return mintCountInfo.totalMinted >= mintCountInfo.maximumMints;
  };
  return (
    <div className="mint-page cursed">
      {!hasUserAlreadyMinted && (
        <>
          {noMintLeft() && <NoMintLeft frontEndConfig={frontEndConfig} />}
          {!noMintLeft() && (
            <div className={"mint-content-card"}>
              <header className="d-flex flex-row justify-content-between header">
                <ColorLight color={"green"} />
                <div
                  className={
                    "text-center content w-100 d-flex align-items-center justify-content-center"
                  }
                >
                  RɛADY TO MiNT
                </div>
                <ColorLight color={"green"} />
              </header>
              <div className={"content copy"}>
                <p>
                  You’re in! Welcome to the first step in creating your own
                  Cursed Emoji with artist yung jake.
                </p>
                <p>
                  You’ll need a RMX Pass to gain access to our RMX Console,
                  opening on January 19.
                </p>
              </div>
            </div>
          )}
          <section style={{ maxWidth: "512px" }}>
            <TableBox
              keys={["Price:", "Limit:", "Remix In:", "Mints Left:"]}
              values={[
                "Free to Mint",
                "1 Mint per Wallet",
                <LiveCountdownClock date={REMIX_OPEN_DATE} />,
                <MintCountDown mintCountInfo={mintCountInfo} />,
              ]}
              leftWidth={"medium"}
            />
          </section>
        </>
      )}
      {hasUserAlreadyMinted && (
        <MintPageJustMinted mintCountInfo={mintCountInfo} />
      )}
    </div>
  );
};

export default MintPageCursed;
