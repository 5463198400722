import CursedEmojisLogo from "../../../images/cursed_emojis_logo.svg";
import React from "react";
import "./CursedEmojiTabHeader.scss";

export const CursedEmojiTabHeader = () => {
  return (
    <section className={"header-container mx-auto"}>
      <img className={"header-image"} src={CursedEmojisLogo} />
      <p className={"header-text"}>by RMX with yung jake</p>
    </section>
  );
};
