import {
  CANVAS_ID,
  EmojiCanvasController,
  fromCanvas,
} from "./EmojiCanvasController";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Trait } from "../../../../types";
import { ChildrenProps, Part } from "../../types";
import { fabric } from "fabric";
import { IEvent } from "fabric/fabric-impl";

export interface EmojiCanvasContextData {
  ready: boolean;
  setCanvasContainerDivElement: (ref: HTMLDivElement) => void;
  controller: EmojiCanvasController;
  selectedElements: Part[];
  setSelectedElements: (value: Part[]) => void;
}

//todo: there is a better way to do this
const defaultDummyController: EmojiCanvasController = {
  addFeature: (_feature: Trait) => {
    return { traits: [], allTraitsPresent: [], success: false };
  },
  clearAll: () => {},
  clearSelection: () => [],
  getEmojiAsFileBlob: () => new Promise<Blob>((_resolve) => {}),
  setFace: (_imgUrl: string) => {},
  canvasJSON: () => "",
  canvas: new fabric.Canvas(CANVAS_ID, {
    height: 1,
    width: 1,
  }),
};

const EmojiCanvasContext = createContext<EmojiCanvasContextData | undefined>({
  ready: false,
  setCanvasContainerDivElement: (_: HTMLDivElement) => {},
  controller: defaultDummyController,
  selectedElements: [],
  setSelectedElements: (_: Part[]) => {},
});

const makeCanvasAndController = (
  ref: HTMLDivElement
): [fabric.Canvas, EmojiCanvasController] => {
  const canvas = makeCanvas(ref);
  const controller = fromCanvas(canvas);
  return [canvas, controller];
};

function makeCanvas(ref: HTMLDivElement) {
  const canvas = new fabric.Canvas(CANVAS_ID, {
    height: ref.offsetHeight,
    width: ref.offsetWidth,
  });
  return canvas;
}

// TODO: we may need this approach; holding it for a few cycles to make sure we don't
// https://stackoverflow.com/a/58894059
// const useFabric = (onChange) => {
//   const fabricRef = useRef();
//   const disposeRef = useRef();
//   return useCallback((node) => {
//     if (node) {
//       fabricRef.current = new fabric.Canvas(node);
//       if (onChange) {
//         disposeRef.current = onChange(fabricRef.current);
//       }
//     }
//     else if (fabricRef.current) {
//       fabricRef.current.dispose();
//       if (disposeRef.current) {
//         disposeRef.current();
//         disposeRef.current = undefined;
//       }
//     }
//   }, []);
// };

export const EmojiCanvasProvider = (props: ChildrenProps) => {
  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const [canvasRef, setCanvasRef] = useState<HTMLDivElement>();
  const [ready, setReady] = useState<boolean>(false);
  const [selectedElements, setSelectedElements] = useState<Part[]>([]);
  const [controller, setController] = useState<EmojiCanvasController>(
    defaultDummyController
  );

  useEffect(() => {
    if (!canvasRef) return;
    if (!canvas) {
      const [canv, controller] = makeCanvasAndController(canvasRef);
      setCanvas(controller.canvas);
      setController(controller);
    }
  }, [canvasRef, canvas]);

  //memoized for performance
  const memSetCanvasElement = useCallback(
    (ref: HTMLDivElement) => {
      ref && setCanvasRef(ref);
    },
    [setCanvasRef]
  );

  // when canvas set up, add listeners; create unset function
  useEffect(() => {
    //todo: add baseemoji selection? or no?

    const getSelectedElements = (event: IEvent<Event>) => {
      if (event.selected) {
        const elements = event.selected.map((selectedElement: any) => {
          return selectedElement.get("data");
        });
        return elements;
      } else return [];
    };

    if (!canvas) return;

    const handleSelectAction = (e: IEvent<Event>) => {
      e && setSelectedElements(getSelectedElements(e));
    };
    const handleDeselectAction = (e: IEvent<Event>) =>
      e && setSelectedElements(getSelectedElements(e));

    canvas.on("selection:created", handleSelectAction);
    canvas.on("selection:updated", handleSelectAction);
    canvas.on("selection:cleared", handleDeselectAction);

    // return our unset function
    return () => {
      canvas.off("selection:created");
      canvas.off("selection:updated");
      canvas.off("selection:cleared");
    };
  }, [canvas]);

  // when canvas and controller exist, we are ready
  useEffect(() => {
    if (canvas && controller) {
      setReady(true);
    }
  }, [canvas, controller]);

  return (
    <EmojiCanvasContext.Provider
      value={{
        ready: ready,
        setCanvasContainerDivElement: memSetCanvasElement,
        controller: controller || defaultDummyController,
        selectedElements: selectedElements,
        setSelectedElements: setSelectedElements,
      }}
    >
      {props.children}
    </EmojiCanvasContext.Provider>
  );
};

export const useEmojiCanvasContext = () => {
  const ctx = useContext(EmojiCanvasContext);
  if (!ctx) throw new Error("No EmojiCanvasContext.Provider found.");
  return ctx;
};

export default EmojiCanvasContext;
