import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import "./WaitingScreenLoading.scss";

type LoadingGif = {
  name: string;
  url: string;
  text: string;
};

export const gifs: LoadingGif[] = [
  {
    name: "scrubber",
    url: "https://cursedemojis.world/assets/images/cursed-multitask.gif",
    text: "Scrubbin the ethers",
  },
  {
    name: "judgin",
    url: "https://cursedemojis.world/assets/images/cursed-punk.gif",
    text: "Judgin your soul on the blockchain",
  },
  {
    name: "tighterin",
    url: "https://cursedemojis.world/assets/images/cursed-smileyputer.gif",
    text: "Hodl tight, loading now",
  },
];

interface WaitingScreenLoadingProps {
  index: number;
  text: string | number | (any | string)[];
}

const WaitingScreenLoading = ({ index, text }: WaitingScreenLoadingProps) => {
  useEffect(() => {
    document.body.classList.add("no-scrolling");

    return () => {
      document.body.classList.remove("no-scrolling");
    };
  });

  return (
    <section
      className={
        "d-flex flex-column align-items-center text-center loader-container"
      }
    >
      <div className={"px-3 mt-4 logo-container"}>
        <Image
          className={"w-100 image chromatic-ignore"}
          src={gifs[index].url}
          alt={gifs[index].text}
        />
        <p className={"w-100 waiting-title"}>
          {" "}
          {typeof text === "number" ? gifs[index].text : text}
        </p>
      </div>
    </section>
  );
};

export default WaitingScreenLoading;
