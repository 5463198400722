import React from "react";
import "./MintContent.scss";
import "./RemixContent.scss";
import CursedEmojisNav from "./CursedEmojisNav/CursedEmojisNav";
import { BigBoxWithTitleLightTextAndBigImage } from "./BigBoxWithTitleLightTextAndBigImage";
import { RemixPhases } from "../../config/api/types";
import { TableBox } from "./TableBox";
import { Link } from "react-router-dom";

interface RemixContentProps {
  remixPhase: RemixPhases;
  autoPlay?: boolean;
}

const remixDemoVideoSrc = `https://cursedemojis.world/assets/media/building-ce-canvas-only.mp4`;

export const RemixContent = ({
  remixPhase,
  autoPlay = true,
}: RemixContentProps) => {
  return (
    <div className={"mint-content remix-content"}>
      <div className={"genesis-subnav text-center"}>
        <CursedEmojisNav />
      </div>

      {remixPhase === "pre-remix" && (
        <>
          <BigBoxWithTitleLightTextAndBigImage
            title={"OPENS FOR ReMiXiNG: JANUAƦY 19"}
            color={"yellow"}
            text={
              "The RMX Console will unlock on January 19. It gives pass holders the ability to remix their CC0 Cursed Emojis with up to 10 unique NFT traits. The most creative emojis will be nominated for Jake's Grails. "
            }
            children={
              <video loop={true} autoPlay={autoPlay} src={remixDemoVideoSrc} />
            }
          />

          <div className={"content-card dimmed"} style={{ maxWidth: "512px" }}>
            <header className={"content text-center"}>Wanting to Chat?</header>
            <div className={"content copy sub-content"}>
              <span>
                {" "}
                Connect with the Cursed Community at{" "}
                <a
                  target={"_blank"}
                  href={
                    "https://app.geneva.com/invite/6626ed36-79db-41d3-94e5-68a2163345e6"
                  }
                >
                  Café RMX on Geneva
                </a>
                .
              </span>
            </div>
          </div>
        </>
      )}

      {(remixPhase === "open-remix" || remixPhase === "post-remix") && (
        <>
          <BigBoxWithTitleLightTextAndBigImage
            title={"CƦEATE YER CɄRSED EMOJI!"}
            color={"green"}
            text={
              <>
                Create your Cursed Emoji by remixing your RMX Pass with up to 10
                unique traits, where each trait can be used unlimited times. All
                traits were created by yung jake and exist{" "}
                <a
                  href={"https://opensea.io/collection/rmx-cursed-traits"}
                  target={"_blank"}
                >
                  on-chain.
                </a>{" "}
                Make an NFT from NFTs and get cuɹsɛd!
              </>
            }
            text2={
              "The RMX Console will choose the lowest token number first. You can return to the console for the next base once your first remix is complete."
            }
            children={
              <a href="/RMXconsole" className={"d-flex justify-content-center"}>
                <video
                  loop={true}
                  autoPlay={autoPlay}
                  src={remixDemoVideoSrc}
                  height={420}
                  width={420}
                />
              </a>
            }
          />
          <section className={"table-section"}>
            <TableBox
              keys={["Access:", "Price:", "Limit:", "Remix In:"]}
              values={[
                <>
                  Must Own a
                  <a href={"https://opensea.io/collection/cursedemojis"}>
                    RMX Pass
                  </a>
                </>,
                "Free to Remix",
                "1 Remix per Pass",
                <p className={"timer"}>REMIX IS LIVE</p>,
              ]}
              footer={
                <>
                  For more info, see our
                  <Link className={"faq-link"} to={"/about"}>
                    FAQs
                  </Link>
                </>
              }
            />
          </section>
        </>
      )}
      {remixPhase === "post-remix" && <></>}
    </div>
  );
};
