export const nftUrlOpenSea = (
  urlToParse: string,
  genesisMintAddress: string,
  id: string
) => {
  const parsedUrl = new URL(urlToParse);
  return parsedUrl.origin + "/assets/" + genesisMintAddress + "/" + id;
};

export const parseNftId = (metadataImageUrl: string): string => {
  const imageUrl = new URL(metadataImageUrl);
  const splitUrl = imageUrl.pathname.split("/");
  return parseInt(splitUrl[2], 16).toString();
};
