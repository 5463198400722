import { HTTPClient } from "./axios-client";

export const checkFileExistsWithDelay = async (
  client: HTTPClient,
  fileUri: string,
  delay = 5000
): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    setTimeout(() => {
      client
        .head(fileUri)
        .then((result) => {
          if (result.status === 200) {
            resolve(true);
          } else {
            throw new Error(
              `problem calling HEAD on ${fileUri}: returned ${result.status}`
            );
          }
        })
        .catch((reason) => {
          console.error(reason);
          resolve(false);
        });
    }, delay);
  });
};
