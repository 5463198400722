import { useCallback, useEffect, useState } from "react";
import {
  createBlankEmojiAssets,
  GenesisMintDetails,
  requestCurse,
  RequestCurseResponse,
} from "../config/api/rmx-api";
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

import activeContract from "../contracts/CursedEmojis.json";
// const contractFile = "contracts/GMB4_2_0.json";
// const contractFilePrev = "contracts/GMB4_1_0.json";
// const activeContract = await import(process.env.PUBLIC_URL + `/${contractFile}`);
// proposal spec:
// const activeContract = await import(`${process.env.PUBLIC_URL}/contracts/${CONTRACT_ADDRESS}/something.json`);

import { useErrorHandler } from "react-error-boundary";
import Bugsnag from "@bugsnag/js";

export type GenesisMintStatus =
  | "start"
  | "waiting_on_access_list_confirmation"
  | "waiting_on_wallet_confirmation"
  | "minting"
  | "minted";

export interface GenesisMint {
  status: GenesisMintStatus;
  initFn: (walletAddress: string) => Promise<void>;
  mintFn?: () => Promise<void>;
  readyToMint: boolean;
  genesisMintDetails?: GenesisMintDetails;
}

export const useGenesisMint = (): GenesisMint => {
  const handleError = useErrorHandler();
  const [gensisMintStatus, setGenesisMintStatus] =
    useState<GenesisMintStatus>("start");

  const [curseRequestResponse, setCurseRequestResponse] =
    useState<RequestCurseResponse>();
  const [mintTxSent, setMintTxSent] = useState<boolean>(false);
  const [mintDetails, setMintDetails] = useState<GenesisMintDetails>();

  // TODO: change startfn to skip curse request if it's already applied
  // trigger curse request immediately on wallet connect, optimistically/eagerly
  const startFn = useCallback(
    async (walletAddress: string) => {
      setGenesisMintStatus("waiting_on_access_list_confirmation");
      requestCurse(walletAddress)
        .then((response) => {
          setCurseRequestResponse(response);
        })
        .catch((error) => {
          handleError(error);
        });
    },
    [setCurseRequestResponse]
  );

  const { data: curseData } = useWaitForTransaction({
    hash: curseRequestResponse?.pendingCurseTxHash as `0x${string}`,
    // TODO: enable when user clicks mint and is already cursed
    enabled:
      !curseRequestResponse?.alreadyCursed &&
      !!curseRequestResponse?.pendingCurseTxHash,
    onError: (error) => {
      handleError(error);
    },
  });

  const preparedContractWrite = usePrepareContractWrite({
    address: curseRequestResponse?.genesisMintContractAddress as `0x${string}`,
    abi: activeContract.abi,
    functionName: "genesisMint",
    enabled:
      (curseRequestResponse?.alreadyCursed || !!curseData) && !mintTxSent,
    onError: (error) => {
      handleError(error);
    },
  });

  const {
    data: mintData,
    writeAsync: mintWrite,
    isLoading: mintIsLoading,
    isSuccess: mintIsSuccess,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } = useContractWrite(preparedContractWrite?.config);

  useEffect(() => {
    if (mintIsLoading) {
      setGenesisMintStatus("waiting_on_wallet_confirmation");
    }
  }, [mintIsLoading]);

  useEffect(() => {
    if (mintIsSuccess) {
      setGenesisMintStatus("minting");
    }
  }, [mintIsSuccess]);

  const _mintTx = useWaitForTransaction({
    hash: mintData?.hash,
    enabled: !!mintData?.hash,
    onSuccess: (data) => {
      console.log("mint successful:", data);
      createBlankEmojiAssets(data.transactionHash)
        .then((result) => {
          console.log("mint details", result);
          setMintDetails(result);
          setGenesisMintStatus("minted");
        })
        .catch((error) => {
          Bugsnag.addMetadata(
            "createBlankEmojiAssets",
            "transactionHash",
            data.transactionHash
          );
          handleError(error);
        });
    },
    onError: (error) => {
      Bugsnag.addMetadata("useWaitForTransaction", "hash", mintData?.hash);
      handleError(error);
    },
  });

  const mintFn = useCallback(async () => {
    if (mintWrite) {
      console.log("yep mintWrite");
      await mintWrite()
        .then((result) => console.log("mintWrite result:", result))
        .catch((error) => {
          handleError(error);
        });
    } else {
      console.log("no mintWrite");
    }
  }, [mintWrite]);

  useEffect(() => {
    if (mintData) {
      setMintTxSent(true);
    }
  }, [mintData]);

  const readyToMint =
    !!mintWrite &&
    (!!curseData || !!curseRequestResponse?.alreadyCursed) &&
    !mintTxSent;

  return {
    status: gensisMintStatus,
    initFn: startFn,
    mintFn: mintFn,
    readyToMint: readyToMint,
    genesisMintDetails: mintDetails,
  };
};
