import { HTTPClient } from "./axios-client";
import { checkFileExistsWithDelay } from "./check-file-exists-with-delay";

export interface FileCheckArgs {
  client: HTTPClient;
  fileUri: string;
  retries: number;
  delayInMillis: number;
}

export const checkFileExistsWithRetryAndDelay = async ({
  client,
  fileUri,
  retries = 1,
  delayInMillis = 5000,
}: FileCheckArgs): Promise<boolean> => {
  console.log(`trying ${retries} times`);
  const lastError: Error = new Error("check for token image unsuccessful");

  let result = false;

  for (let i = 0; i < retries; i++) {
    result = await checkFileExistsWithDelay(client, fileUri, delayInMillis);
    console.log(`try #${i + 1} returned ${result}`);
    if (result) return true;
  }
  throw lastError;
};
