import {
  BLUE_FACE_ID,
  POTATO_FACE_ID,
  RED_FACE_ID,
  YELLOW_FACE_ID,
} from "./types";
import { Part } from "../../../../types";

const cdnUrl = "https://cursedemojis.world/assets/images/traits";

//TODO figure out if ID is still needed
export const newTraitMap: Part[] = [
  {
    id: 1,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-blue-butt.png",
    name: "butt",
    category: "bodyparts",
    faceId: BLUE_FACE_ID,
    tokenId: "207",
    idName: "bodyparts-blue-butt",
  },
  {
    id: 2,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-blue-eyy-hands.png",
    name: "eyy hands",
    category: "bodyparts",
    faceId: BLUE_FACE_ID,
    tokenId: "217",
    idName: "bodyparts-blue-eyy-hands",
  },
  {
    id: 3,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-blue-hang-loose-hand.png",
    name: "hang loose hand",
    category: "bodyparts",
    faceId: BLUE_FACE_ID,
    tokenId: "216",
    idName: "bodyparts-blue-hang-loose-hand",
  },
  {
    id: 4,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-blue-pray-hands.png",
    name: "pray hands",
    category: "bodyparts",
    faceId: BLUE_FACE_ID,
    tokenId: "219",
    idName: "bodyparts-blue-pray-hands",
  },
  {
    id: 5,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-blue-question-hand.png",
    name: "question hand",
    category: "bodyparts",
    faceId: BLUE_FACE_ID,
    tokenId: "212",
    idName: "bodyparts-blue-question-hand",
  },
  {
    id: 6,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-blue-sad-wrinkle.png",
    name: "sad wrinkle",
    category: "bodyparts",
    faceId: BLUE_FACE_ID,
    tokenId: "213",
    idName: "bodyparts-blue-sad-wrinkle",
  },
  {
    id: 7,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-blue-shh-hand.png",
    name: "shh hand",
    category: "bodyparts",
    faceId: BLUE_FACE_ID,
    tokenId: "222",
    idName: "bodyparts-blue-shh-hand",
  },
  {
    id: 8,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-blue-shrek-ear-left.png",
    name: "shrek ear left",
    category: "bodyparts",
    faceId: BLUE_FACE_ID,
    tokenId: "214",
    idName: "bodyparts-blue-shrek-ear-left",
  },
  {
    id: 9,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-blue-shrek-ear-right.png",
    name: "shrek ear right",
    category: "bodyparts",
    faceId: BLUE_FACE_ID,
    tokenId: "215",
    idName: "bodyparts-blue-shrek-ear-right",
  },
  {
    id: 10,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-blue-slender-nose.png",
    name: "slender nose",
    category: "bodyparts",
    faceId: BLUE_FACE_ID,
    tokenId: "208",
    idName: "bodyparts-blue-slender-nose",
  },
  {
    id: 11,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-blue-sparse-hair.png",
    name: "sparse hair",
    category: "bodyparts",
    faceId: BLUE_FACE_ID,
    tokenId: "216",
    idName: "bodyparts-blue-sparse-hair",
  },
  {
    id: 12,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-blue-weird-3d-nose.png",
    name: "weird 3d nose",
    category: "bodyparts",
    faceId: BLUE_FACE_ID,
    tokenId: "209",
    idName: "bodyparts-blue-weird-3d-nose",
  },
  {
    id: 13,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-potato-butt.png",
    name: "butt",
    category: "bodyparts",
    faceId: POTATO_FACE_ID,
    tokenId: "177",
    idName: "bodyparts-potato-butt",
  },
  {
    id: 14,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-potato-eyy-hands.png",
    name: "eyy hands",
    category: "bodyparts",
    faceId: POTATO_FACE_ID,
    tokenId: "183",
    idName: "bodyparts-potato-eyy-hands",
  },
  {
    id: 15,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-potato-hang-loose-hand.png",
    name: "hang loose hand",
    category: "bodyparts",
    faceId: POTATO_FACE_ID,
    tokenId: "184",
    idName: "bodyparts-potato-hang-loose-hand",
  },
  {
    id: 16,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-potato-pray-hands.png",
    name: "pray hands",
    category: "bodyparts",
    faceId: POTATO_FACE_ID,
    tokenId: "185",
    idName: "bodyparts-potato-pray-hands",
  },
  {
    id: 17,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-potato-question-hand.png",
    name: "question hand",
    category: "bodyparts",
    faceId: POTATO_FACE_ID,
    tokenId: "178",
    idName: "bodyparts-potato-question-hand",
  },
  {
    id: 18,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-potato-sad-wrinkle.png",
    name: "sad wrinkle",
    category: "bodyparts",
    faceId: POTATO_FACE_ID,
    tokenId: "179",
    idName: "bodyparts-potato-sad-wrinkle",
  },
  {
    id: 19,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-potato-shh-hand.png",
    name: "shh hand",
    category: "bodyparts",
    faceId: POTATO_FACE_ID,
    tokenId: "187",
    idName: "bodyparts-potato-shh-hand",
  },
  {
    id: 20,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-potato-shrek-ear-left.png",
    name: "shrek ear left",
    category: "bodyparts",
    faceId: POTATO_FACE_ID,
    tokenId: "180",
    idName: "bodyparts-potato-shrek-ear-left",
  },
  {
    id: 21,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-potato-shrek-ear-right.png",
    name: "shrek ear right",
    category: "bodyparts",
    faceId: POTATO_FACE_ID,
    tokenId: "181",
    idName: "bodyparts-potato-shrek-ear-right",
  },
  {
    id: 22,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-potato-slender-nose.png",
    name: "slender nose",
    category: "bodyparts",
    faceId: POTATO_FACE_ID,
    tokenId: "170",
    idName: "bodyparts-potato-slender-nose",
  },
  {
    id: 23,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-potato-sparse-hair.png",
    name: "sparse hair",
    category: "bodyparts",
    faceId: POTATO_FACE_ID,
    tokenId: "182",
    idName: "bodyparts-potato-sparse-hair",
  },
  {
    id: 24,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-potato-spud-nose.png",
    name: "spud nose",
    category: "bodyparts",
    faceId: POTATO_FACE_ID,
    tokenId: "171",
    idName: "bodyparts-potato-spud-nose",
  },
  {
    id: 25,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-potato-weird-3d-nose.png",
    name: "weird 3d nose",
    category: "bodyparts",
    faceId: POTATO_FACE_ID,
    tokenId: "173",
    idName: "bodyparts-potato-weird-3d-nose",
  },
  {
    id: 26,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-red-butt.png",
    name: "butt",
    category: "bodyparts",
    faceId: RED_FACE_ID,
    tokenId: "188",
    idName: "bodyparts-red-butt",
  },
  {
    id: 27,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-red-eyy-hands.png",
    name: "eyy hands",
    category: "bodyparts",
    faceId: RED_FACE_ID,
    tokenId: "194",
    idName: "bodyparts-red-eyy-hands",
  },
  {
    id: 28,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-red-question-hand.png",
    name: "question hand",
    category: "bodyparts",
    faceId: RED_FACE_ID,
    tokenId: "195",
    idName: "bodyparts-red-question-hand",
  },
  {
    id: 29,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-red-sad-wrinkle.png",
    name: "sad wrinkle",
    category: "bodyparts",
    faceId: RED_FACE_ID,
    tokenId: "189",
    idName: "bodyparts-red-sad-wrinkle",
  },
  {
    id: 30,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-red-shh-hand.png",
    name: "shh hand",
    category: "bodyparts",
    faceId: RED_FACE_ID,
    tokenId: "196",
    idName: "bodyparts-red-shh-hand",
  },
  {
    id: 31,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-red-shrek-ear-left.png",
    name: "shrek ear left",
    category: "bodyparts",
    faceId: RED_FACE_ID,
    tokenId: "190",
    idName: "bodyparts-red-shrek-ear-left",
  },
  {
    id: 32,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-red-shrek-ear-right.png",
    name: "shrek ear right",
    category: "bodyparts",
    faceId: RED_FACE_ID,
    tokenId: "191",
    idName: "bodyparts-red-shrek-ear-right",
  },
  {
    id: 33,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-red-slender-nose.png",
    name: "slender nose",
    category: "bodyparts",
    faceId: RED_FACE_ID,
    tokenId: "186",
    idName: "bodyparts-red-slender-nose",
  },
  {
    id: 34,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-red-sparse-hair.png",
    name: "sparse hair",
    category: "bodyparts",
    faceId: RED_FACE_ID,
    tokenId: "192",
    idName: "bodyparts-red-sparse-hair",
  },
  {
    id: 35,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-red-weird-3d-nose.png",
    name: "weird 3d nose",
    category: "bodyparts",
    faceId: RED_FACE_ID,
    tokenId: "193",
    idName: "bodyparts-red-weird-3d-nose",
  },
  {
    id: 36,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-yellow-butt.png",
    name: "butt",
    category: "bodyparts",
    faceId: YELLOW_FACE_ID,
    tokenId: "197",
    idName: "bodyparts-yellow-butt",
  },
  {
    id: 37,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-yellow-eyy-hands.png",
    name: "eyy hands",
    category: "bodyparts",
    faceId: YELLOW_FACE_ID,
    tokenId: "203",
    idName: "bodyparts-yellow-eyy-hands",
  },
  {
    id: 38,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-yellow-hang-loose-hand.png",
    name: "hang loose hand",
    category: "bodyparts",
    faceId: YELLOW_FACE_ID,
    tokenId: "204",
    idName: "bodyparts-yellow-hang-loose-hand",
  },
  {
    id: 39,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-yellow-pray-hands.png",
    name: "pray hands",
    category: "bodyparts",
    faceId: YELLOW_FACE_ID,
    tokenId: "210",
    idName: "bodyparts-yellow-pray-hands",
  },
  {
    id: 40,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-yellow-question-hand.png",
    name: "question hand",
    category: "bodyparts",
    faceId: YELLOW_FACE_ID,
    tokenId: "205",
    idName: "bodyparts-yellow-question-hand",
  },
  {
    id: 41,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-yellow-sad-wrinkle.png",
    name: "sad wrinkle",
    category: "bodyparts",
    faceId: YELLOW_FACE_ID,
    tokenId: "206",
    idName: "bodyparts-yellow-sad-wrinkle",
  },
  {
    id: 42,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-yellow-shh-hand.png",
    name: "shh hand",
    category: "bodyparts",
    faceId: YELLOW_FACE_ID,
    tokenId: "211",
    idName: "bodyparts-yellow-shh-hand",
  },
  {
    id: 43,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-yellow-shrek-ear-left.png",
    name: "shrek ear left",
    category: "bodyparts",
    faceId: YELLOW_FACE_ID,
    tokenId: "198",
    idName: "bodyparts-yellow-shrek-ear-left",
  },
  {
    id: 44,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-yellow-shrek-ear-right.png",
    name: "shrek ear right",
    category: "bodyparts",
    faceId: YELLOW_FACE_ID,
    tokenId: "199",
    idName: "bodyparts-yellow-shrek-ear-right",
  },
  {
    id: 45,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-yellow-slender-nose.png",
    name: "slender nose",
    category: "bodyparts",
    faceId: YELLOW_FACE_ID,
    tokenId: "200",
    idName: "bodyparts-yellow-slender-nose",
  },
  {
    id: 46,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-yellow-sparse-hair.png",
    name: "sparse hair",
    category: "bodyparts",
    faceId: YELLOW_FACE_ID,
    tokenId: "201",
    idName: "bodyparts-yellow-sparse-hair",
  },
  {
    id: 47,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/bodyparts-yellow-weird-3d--nose.png",
    name: "weird 3d nose",
    category: "bodyparts",
    faceId: YELLOW_FACE_ID,
    tokenId: "202",
    idName: "bodyparts-yellow-weird-3d--nose",
  },
  {
    id: 48,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-bloodshot-eye-left.png",
    name: "bloodshot eye left",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "98",
    idName: "eyes-blue-bloodshot-eye-left",
  },
  {
    id: 49,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-bloodshot-eye-right.png",
    name: "bloodshot eye right",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "99",
    idName: "eyes-blue-bloodshot-eye-right",
  },
  {
    id: 50,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-brain-slobber-eye.png",
    name: "brain slobber eye",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "109",
    idName: "eyes-blue-brain-slobber-eye",
  },
  {
    id: 51,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-bro-brow.png",
    name: "bro brow",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "102",
    idName: "eyes-blue-bro-brow",
  },
  {
    id: 52,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-bro-eye-left.png",
    name: "bro eye left",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "103",
    idName: "eyes-blue-bro-eye-left",
  },
  {
    id: 53,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-bro-eye-right.png",
    name: "bro eye right",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "104",
    idName: "eyes-blue-bro-eye-right",
  },
  {
    id: 54,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-crammed-eyes.png",
    name: "crammed eyes",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "105",
    idName: "eyes-blue-crammed-eyes",
  },
  {
    id: 55,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-cyclops-eye.png",
    name: "cyclops eye",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "93",
    idName: "eyes-blue-cyclops-eye",
  },
  {
    id: 56,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-dark-eyes.png",
    name: "dark eyes",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "106",
    idName: "eyes-blue-dark-eyes",
  },
  {
    id: 57,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-dirty-eye.png",
    name: "dirty eye",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "100",
    idName: "eyes-blue-dirty-eye",
  },
  {
    id: 58,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-dont-look-brow.png",
    name: "dont look brow",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "91",
    idName: "eyes-blue-dont-look-brow",
  },
  {
    id: 59,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-dont-look-eye.png",
    name: "dont look eye",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "92",
    idName: "eyes-blue-dont-look-eye",
  },
  {
    id: 60,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-emo-eye.png",
    name: "emo eye",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "107",
    idName: "eyes-blue-emo-eye",
  },
  {
    id: 61,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-flimsy-cry-eye-left.png",
    name: "flimsy cry eye left",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "94",
    idName: "eyes-blue-flimsy-cry-eye-left",
  },
  {
    id: 62,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-flimsy-cry-eye-right.png",
    name: "flimsy cry eye right",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "95",
    idName: "eyes-blue-flimsy-cry-eye-right",
  },
  {
    id: 63,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-heart-eye.png",
    name: "heart eye",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "101",
    idName: "eyes-blue-heart-eye",
  },
  {
    id: 64,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-heart-wink.png",
    name: "heart wink",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "108",
    idName: "eyes-blue-heart-wink",
  },
  {
    id: 65,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-lashes-eyes.png",
    name: "lashes eyes",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "96",
    idName: "eyes-blue-lashes-eyes",
  },
  {
    id: 66,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-lazy-eye.png",
    name: "lazy eye",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "110",
    idName: "eyes-blue-lazy-eye",
  },
  {
    id: 67,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-real-eye-left.png",
    name: "real eye left",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "85",
    idName: "eyes-blue-real-eye-left",
  },
  {
    id: 68,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-real-eye-right.png",
    name: "real eye right",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "86",
    idName: "eyes-blue-real-eye-right",
  },
  {
    id: 69,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-real-tears-left.png",
    name: "real tears left",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "87",
    idName: "eyes-blue-real-tears-left",
  },
  {
    id: 70,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-real-tears-right.png",
    name: "real tears right",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "88",
    idName: "eyes-blue-real-tears-right",
  },
  {
    id: 71,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-seductive-eye-left.png",
    name: "seductive eye left",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "89",
    idName: "eyes-blue-seductive-eye-left",
  },
  {
    id: 72,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-seductive-eye-right.png",
    name: "seductive eye right",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "90",
    idName: "eyes-blue-seductive-eye-right",
  },
  {
    id: 73,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-shiny-eyes.png",
    name: "shiny eyes",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "111",
    idName: "eyes-blue-shiny-eyes",
  },
  {
    id: 74,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-shrek-eyes.png",
    name: "shrek eyes",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "115",
    idName: "eyes-blue-shrek-eyes",
  },
  {
    id: 75,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-blue-sleepy-eye.png",
    name: "sleepy eye",
    category: "eyes",
    faceId: BLUE_FACE_ID,
    tokenId: "97",
    idName: "eyes-blue-sleepy-eye",
  },
  {
    id: 76,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-bloodshot-eye-left.png",
    name: "bloodshot eye left",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "13",
    idName: "eyes-potato-bloodshot-eye-left",
  },
  {
    id: 77,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-bloodshot-eye-right.png",
    name: "bloodshot eye right",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "14",
    idName: "eyes-potato-bloodshot-eye-right",
  },
  {
    id: 78,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-brain-slobber-eye.png",
    name: "brain slobber eye",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "22",
    idName: "eyes-potato-brain-slobber-eye",
  },
  {
    id: 79,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-bro-brow.png",
    name: "bro brow",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "17",
    idName: "eyes-potato-bro-brow",
  },
  {
    id: 80,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-bro-eye-left.png",
    name: "bro eye left",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "15",
    idName: "eyes-potato-bro-eye-left",
  },
  {
    id: 81,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-bro-eye-right.png",
    name: "bro eye right",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "19",
    idName: "eyes-potato-bro-eye-right",
  },
  {
    id: 82,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-crammed-eyes.png",
    name: "crammed eyes",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "25",
    idName: "eyes-potato-crammed-eyes",
  },
  {
    id: 83,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-cyclops-eye.png",
    name: "cyclops eye",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "18",
    idName: "eyes-potato-cyclops-eye",
  },
  {
    id: 84,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-dark-eyes.png",
    name: "dark eyes",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "24",
    idName: "eyes-potato-dark-eyes",
  },
  {
    id: 85,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-dirty-eye.png",
    name: "dirty eye",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "20",
    idName: "eyes-potato-dirty-eye",
  },
  {
    id: 86,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-dont-look-brow.png",
    name: "dont look brow",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "6",
    idName: "eyes-potato-dont-look-brow",
  },
  {
    id: 87,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-dont-look-eye.png",
    name: "dont look eye",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "7",
    idName: "eyes-potato-dont-look-eye",
  },
  {
    id: 88,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-emo-eye.png",
    name: "emo eye",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "16",
    idName: "eyes-potato-emo-eye",
  },
  {
    id: 89,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-flimsy-cry-eye-left.png",
    name: "flimsy cry eye left",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "9",
    idName: "eyes-potato-flimsy-cry-eye-left",
  },
  {
    id: 90,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-flimsy-cry-eye-right.png",
    name: "flimsy cry eye right",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "10",
    idName: "eyes-potato-flimsy-cry-eye-right",
  },
  {
    id: 91,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-heart-eye.png",
    name: "heart eye",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "23",
    idName: "eyes-potato-heart-eye",
  },
  {
    id: 92,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-heart-wink.png",
    name: "heart wink",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "21",
    idName: "eyes-potato-heart-wink",
  },
  {
    id: 93,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-lashes-eyes.png",
    name: "lashes eyes",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "11",
    idName: "eyes-potato-lashes-eyes",
  },
  {
    id: 94,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-lazy-eye.png",
    name: "lazy eye",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "26",
    idName: "eyes-potato-lazy-eye",
  },
  {
    id: 95,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-real-eye-left.png",
    name: "real eye left",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "1",
    idName: "eyes-potato-real-eye-left",
  },
  {
    id: 96,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-real-eye-right.png",
    name: "real eye right",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "2",
    idName: "eyes-potato-real-eye-right",
  },
  {
    id: 97,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-real-tears-left.png",
    name: "real tears left",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "3",
    idName: "eyes-potato-real-tears-left",
  },
  {
    id: 98,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-real-tears-right.png",
    name: "real tears right",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "4",
    idName: "eyes-potato-real-tears-right",
  },
  {
    id: 99,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-seductive-eye-left.png",
    name: "seductive eye left",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "5",
    idName: "eyes-potato-seductive-eye-left",
  },
  {
    id: 100,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-seductive-eye-right.png",
    name: "seductive eye right",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "8",
    idName: "eyes-potato-seductive-eye-right",
  },
  {
    id: 101,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-shiny-eyes.png",
    name: "shiny eyes",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "31",
    idName: "eyes-potato-shiny-eyes",
  },
  {
    id: 102,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-shrek-eyes.png",
    name: "shrek eyes",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "30",
    idName: "eyes-potato-shrek-eyes",
  },
  {
    id: 103,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-potato-sleepy-eye.png",
    name: "sleepy eye",
    category: "eyes",
    faceId: POTATO_FACE_ID,
    tokenId: "12",
    idName: "eyes-potato-sleepy-eye",
  },
  {
    id: 104,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-bloodshot-eye-left.png",
    name: "bloodshot eye left",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "39",
    idName: "eyes-red-bloodshot-eye-left",
  },
  {
    id: 105,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-bloodshot-eye-right.png",
    name: "bloodshot eye right",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "40",
    idName: "eyes-red-bloodshot-eye-right",
  },
  {
    id: 106,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-brain-slobber-eye.png",
    name: "brain slobber eye",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "49",
    idName: "eyes-red-brain-slobber-eye",
  },
  {
    id: 107,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-bro-brow.png",
    name: "bro brow",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "50",
    idName: "eyes-red-bro-brow",
  },
  {
    id: 108,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-bro-eye-left.png",
    name: "bro eye left",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "51",
    idName: "eyes-red-bro-eye-left",
  },
  {
    id: 109,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-bro-eye-right.png",
    name: "bro eye right",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "52",
    idName: "eyes-red-bro-eye-right",
  },
  {
    id: 110,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-crammed-eyes.png",
    name: "crammed eyes",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "44",
    idName: "eyes-red-crammed-eyes",
  },
  {
    id: 111,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-cyclops-eye.png",
    name: "cyclops eye",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "41",
    idName: "eyes-red-cyclops-eye",
  },
  {
    id: 112,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-dark-eyes.png",
    name: "dark eyes",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "53",
    idName: "eyes-red-dark-eyes",
  },
  {
    id: 113,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-dirty-eye.png",
    name: "dirty eye",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "46",
    idName: "eyes-red-dirty-eye",
  },
  {
    id: 114,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-dont-look-brow.png",
    name: "dont look brow",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "33",
    idName: "eyes-red-dont-look-brow",
  },
  {
    id: 115,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-dont-look-eye.png",
    name: "dont look eye",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "35",
    idName: "eyes-red-dont-look-eye",
  },
  {
    id: 116,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-emo-eye.png",
    name: "emo eye",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "45",
    idName: "eyes-red-emo-eye",
  },
  {
    id: 117,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-flimsy-cry-eye-left.png",
    name: "flimsy cry eye left",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "42",
    idName: "eyes-red-flimsy-cry-eye-left",
  },
  {
    id: 118,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-flimsy-cry-eye-right.png",
    name: "flimsy cry eye right",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "36",
    idName: "eyes-red-flimsy-cry-eye-right",
  },
  {
    id: 119,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-heart-eye.png",
    name: "heart eye",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "23",
    idName: "eyes-red-heart-eye",
  },
  {
    id: 120,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-heart-wink.png",
    name: "heart wink",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "47",
    idName: "eyes-red-heart-wink",
  },
  {
    id: 121,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-lashes-eyes.png",
    name: "lashes eyes",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "37",
    idName: "eyes-red-lashes-eyes",
  },
  {
    id: 122,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-lazy-eye.png",
    name: "lazy eye",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "54",
    idName: "eyes-red-lazy-eye",
  },
  {
    id: 123,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-real-eye-left.png",
    name: "real eye left",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "27",
    idName: "eyes-red-real-eye-left",
  },
  {
    id: 124,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-real-eye-right.png",
    name: "real eye right",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "28",
    idName: "eyes-red-real-eye-right",
  },
  {
    id: 125,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-real-tears-left.png",
    name: "real tears left",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "38",
    idName: "eyes-red-real-tears-left",
  },
  {
    id: 126,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-real-tears-right.png",
    name: "real tears right",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "29",
    idName: "eyes-red-real-tears-right",
  },
  {
    id: 127,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-seductive-eye-left.png",
    name: "seductive eye left",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "32",
    idName: "eyes-red-seductive-eye-left",
  },
  {
    id: 128,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-seductive-eye-right.png",
    name: "seductive eye right",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "34",
    idName: "eyes-red-seductive-eye-right",
  },
  {
    id: 129,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-shiny-eyes.png",
    name: "shiny eyes",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "55",
    idName: "eyes-red-shiny-eyes",
  },
  {
    id: 130,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-shrek-eyes.png",
    name: "shrek eyes",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "56",
    idName: "eyes-red-shrek-eyes",
  },
  {
    id: 131,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-red-sleepy-eye.png",
    name: "sleepy eye",
    category: "eyes",
    faceId: RED_FACE_ID,
    tokenId: "43",
    idName: "eyes-red-sleepy-eye",
  },
  {
    id: 132,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-universal-beavis-brow-left.png",
    name: "beavis brow left",
    category: "eyes",
    faceId: undefined,
    tokenId: "113",
    idName: "eyes-universal-beavis-brow-left",
  },
  {
    id: 133,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-universal-beavis-brow-right.png",
    name: "beavis brow right",
    category: "eyes",
    faceId: undefined,
    tokenId: "112",
    idName: "eyes-universal-beavis-brow-right",
  },
  {
    id: 134,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-universal-blank-eyes.png",
    name: "blank eyes",
    category: "eyes",
    faceId: undefined,
    tokenId: "118",
    idName: "eyes-universal-blank-eyes",
  },
  {
    id: 135,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-universal-joker-eye-left.png",
    name: "joker eye left",
    category: "eyes",
    faceId: undefined,
    tokenId: "114",
    idName: "eyes-universal-joker-eye-left",
  },
  {
    id: 136,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-universal-joker-eye-right.png",
    name: "joker eye right",
    category: "eyes",
    faceId: undefined,
    tokenId: "116",
    idName: "eyes-universal-joker-eye-right",
  },
  {
    id: 137,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-universal-lamb-eyes.png",
    name: "lamb eyes",
    category: "eyes",
    faceId: undefined,
    tokenId: "117",
    idName: "eyes-universal-lamb-eyes",
  },
  {
    id: 138,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-universal-seductive-lash.png",
    name: "seductive lash",
    category: "eyes",
    faceId: undefined,
    tokenId: "119",
    idName: "eyes-universal-seductive-lash",
  },
  {
    id: 139,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-bloodshot-eye-left.png",
    name: "bloodshot eye left",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "72",
    idName: "eyes-yellow-bloodshot-eye-left",
  },
  {
    id: 140,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-bloodshot-eye-right.png",
    name: "bloodshot eye right",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "71",
    idName: "eyes-yellow-bloodshot-eye-right",
  },
  {
    id: 141,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-brain-slobber-eye.png",
    name: "brain slobber eye",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "81",
    idName: "eyes-yellow-brain-slobber-eye",
  },
  {
    id: 142,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-bro-brow.png",
    name: "bro brow",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "75",
    idName: "eyes-yellow-bro-brow",
  },
  {
    id: 143,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-bro-eye-left.png",
    name: "bro eye left",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "77",
    idName: "eyes-yellow-bro-eye-left",
  },
  {
    id: 144,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-bro-eye-right.png",
    name: "bro eye right",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "78",
    idName: "eyes-yellow-bro-eye-right",
  },
  {
    id: 145,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-crammed-eyes.png",
    name: "crammed eyes",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "70",
    idName: "eyes-yellow-crammed-eyes",
  },
  {
    id: 146,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-cyclops-eye.png",
    name: "cyclops eye",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "66",
    idName: "eyes-yellow-cyclops-eye",
  },
  {
    id: 147,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-dark-eyes.png",
    name: "dark eyes",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "79",
    idName: "eyes-yellow-dark-eyes",
  },
  {
    id: 148,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-dirty-eye.png",
    name: "dirty eye",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "73",
    idName: "eyes-yellow-dirty-eye",
  },
  {
    id: 149,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-dont-look-brow.png",
    name: "dont look brow",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "65",
    idName: "eyes-yellow-dont-look-brow",
  },
  {
    id: 150,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-dont-look-eye.png",
    name: "dont look eye",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "64",
    idName: "eyes-yellow-dont-look-eye",
  },
  {
    id: 151,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-emo-eye.png",
    name: "emo eye",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "80",
    idName: "eyes-yellow-emo-eye",
  },
  {
    id: 152,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-flimsy-cry-eye-left.png",
    name: "flimsy cry eye left",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "67",
    idName: "eyes-yellow-flimsy-cry-eye-left",
  },
  {
    id: 153,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-flimsy-cry-eye-right.png",
    name: "flimsy cry eye right",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "68",
    idName: "eyes-yellow-flimsy-cry-eye-right",
  },
  {
    id: 154,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-heart-eye.png",
    name: "heart eye",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "76",
    idName: "eyes-yellow-heart-eye",
  },
  {
    id: 155,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-heart-wink.png",
    name: "heart wink",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "74",
    idName: "eyes-yellow-heart-wink",
  },
  {
    id: 156,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-lashes-eyes.png",
    name: "lashes eyes",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "59",
    idName: "eyes-yellow-lashes-eyes",
  },
  {
    id: 157,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-lazy-eye.png",
    name: "lazy eye",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "82",
    idName: "eyes-yellow-lazy-eye",
  },
  {
    id: 158,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-real-eye-left.png",
    name: "real eye left",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "60",
    idName: "eyes-yellow-real-eye-left",
  },
  {
    id: 159,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-real-eye-right.png",
    name: "real eye right",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "61",
    idName: "eyes-yellow-real-eye-right",
  },
  {
    id: 160,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-real-tears-left.png",
    name: "real tears left",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "62",
    idName: "eyes-yellow-real-tears-left",
  },
  {
    id: 161,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-real-tears-right.png",
    name: "real tears right",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "63",
    idName: "eyes-yellow-real-tears-right",
  },
  {
    id: 162,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-seductive-eye-left.png",
    name: "seductive eye left",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "57",
    idName: "eyes-yellow-seductive-eye-left",
  },
  {
    id: 163,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-seductive-eye-right.png",
    name: "seductive eye right",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "58",
    idName: "eyes-yellow-seductive-eye-right",
  },
  {
    id: 164,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-shiny-eyes.png",
    name: "shiny eyes",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "83",
    idName: "eyes-yellow-shiny-eyes",
  },
  {
    id: 165,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-shrek-eyes.png",
    name: "shrek eyes",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "84",
    idName: "eyes-yellow-shrek-eyes",
  },
  {
    id: 166,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/eyes-yellow-sleepy-eye.png",
    name: "sleepy eye",
    category: "eyes",
    faceId: YELLOW_FACE_ID,
    tokenId: "69",
    idName: "eyes-yellow-sleepy-eye",
  },
  {
    id: 167,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-blue-bit-lip-mouth.png",
    name: "bit lip mouth",
    category: "mouths",
    faceId: BLUE_FACE_ID,
    tokenId: "152",
    idName: "mouths-blue-bit-lip-mouth",
  },
  {
    id: 168,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-blue-cursed-mouth.png",
    name: "cursed mouth",
    category: "mouths",
    faceId: BLUE_FACE_ID,
    tokenId: "161",
    idName: "mouths-blue-cursed-mouth",
  },
  {
    id: 169,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-blue-cute-kitty-mouth.png",
    name: "cute kitty mouth",
    category: "mouths",
    faceId: BLUE_FACE_ID,
    tokenId: "153",
    idName: "mouths-blue-cute-kitty-mouth",
  },
  {
    id: 170,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-blue-dirty-mouth.png",
    name: "dirty mouth",
    category: "mouths",
    faceId: BLUE_FACE_ID,
    tokenId: "156",
    idName: "mouths-blue-dirty-mouth",
  },
  {
    id: 171,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-blue-emo-mouth.png",
    name: "emo mouth",
    category: "mouths",
    faceId: BLUE_FACE_ID,
    tokenId: "157",
    idName: "mouths-blue-emo-mouth",
  },
  {
    id: 172,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-blue-no-lip-mouth.png",
    name: "no lip mouth",
    category: "mouths",
    faceId: BLUE_FACE_ID,
    tokenId: "158",
    idName: "mouths-blue-no-lip-mouth",
  },
  {
    id: 173,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-blue-real-small-lips-mouth.png",
    name: "real small lips",
    category: "mouths",
    faceId: BLUE_FACE_ID,
    tokenId: "159",
    idName: "mouths-blue-real-small-lips-mouth",
  },
  {
    id: 174,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-blue-shrek-mouth.png",
    name: "shrek mouth",
    category: "mouths",
    faceId: BLUE_FACE_ID,
    tokenId: "160",
    idName: "mouths-blue-shrek-mouth",
  },
  {
    id: 175,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-blue-slice-mouth.png",
    name: "slice mouth",
    category: "mouths",
    faceId: BLUE_FACE_ID,
    tokenId: "162",
    idName: "mouths-blue-slice-mouth",
  },
  {
    id: 176,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-blue-trustworthy-mouth.png",
    name: "trustworthy mouth",
    category: "mouths",
    faceId: BLUE_FACE_ID,
    tokenId: "165",
    idName: "mouths-blue-trustworthy-mouth",
  },
  {
    id: 177,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-blue-weird-3d-mouth.png",
    name: "weird 3d mouth",
    category: "mouths",
    faceId: BLUE_FACE_ID,
    tokenId: "154",
    idName: "mouths-blue-weird-3d-mouth",
  },
  {
    id: 178,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-potato-bit-lip-mouth.png",
    name: "bit lip mouth",
    category: "mouths",
    faceId: POTATO_FACE_ID,
    tokenId: "122",
    idName: "mouths-potato-bit-lip-mouth",
  },
  {
    id: 179,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-potato-cursed-mouth.png",
    name: "cursed mouth",
    category: "mouths",
    faceId: POTATO_FACE_ID,
    tokenId: "129",
    idName: "mouths-potato-cursed-mouth",
  },
  {
    id: 180,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-potato-cute-kitty-mouth.png",
    name: "cute kitty mouth",
    category: "mouths",
    faceId: POTATO_FACE_ID,
    tokenId: "120",
    idName: "mouths-potato-cute-kitty-mouth",
  },
  {
    id: 181,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-potato-dirty-mouth.png",
    name: "dirty mouth",
    category: "mouths",
    faceId: POTATO_FACE_ID,
    tokenId: "126",
    idName: "mouths-potato-dirty-mouth",
  },
  {
    id: 182,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-potato-emo-mouth.png",
    name: "emo mouth",
    category: "mouths",
    faceId: POTATO_FACE_ID,
    tokenId: "124",
    idName: "mouths-potato-emo-mouth",
  },
  {
    id: 183,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-potato-no-lip-mouth.png",
    name: "no lip mouth",
    category: "mouths",
    faceId: POTATO_FACE_ID,
    tokenId: "123",
    idName: "mouths-potato-no-lip-mouth",
  },
  {
    id: 184,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-potato-real-small-lips-mouth.png",
    name: "real small lips",
    category: "mouths",
    faceId: POTATO_FACE_ID,
    tokenId: "125",
    idName: "mouths-potato-real-small-lips-mouth",
  },
  {
    id: 185,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-potato-shrek-mouth.png",
    name: "shrek mouth",
    category: "mouths",
    faceId: POTATO_FACE_ID,
    tokenId: "127",
    idName: "mouths-potato-shrek-mouth",
  },
  {
    id: 186,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-potato-slice-mouth.png",
    name: "slice mouth",
    category: "mouths",
    faceId: POTATO_FACE_ID,
    tokenId: "128",
    idName: "mouths-potato-slice-mouth",
  },
  {
    id: 187,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-potato-trustworthy-mouth.png",
    name: "trustworthy mouth",
    category: "mouths",
    faceId: POTATO_FACE_ID,
    tokenId: "133",
    idName: "mouths-potato-trustworthy-mouth",
  },
  {
    id: 188,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-potato-weird-3d-mouth.png",
    name: "weird 3d mouth",
    category: "mouths",
    faceId: POTATO_FACE_ID,
    tokenId: "121",
    idName: "mouths-potato-weird-3d-mouth",
  },
  {
    id: 189,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-red-bit-lip-mouth.png",
    name: "bit lip mouth",
    category: "mouths",
    faceId: RED_FACE_ID,
    tokenId: "131",
    idName: "mouths-red-bit-lip-mouth",
  },
  {
    id: 190,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-red-cursed-mouth.png",
    name: "cursed mouth",
    category: "mouths",
    faceId: RED_FACE_ID,
    tokenId: "140",
    idName: "mouths-red-cursed-mouth",
  },
  {
    id: 191,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-red-cute-kitty-mouth.png",
    name: "cute kitty mouth",
    category: "mouths",
    faceId: RED_FACE_ID,
    tokenId: "130",
    idName: "mouths-red-cute-kitty-mouth",
  },
  {
    id: 192,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-red-dirty-mouth.png",
    name: "dirty mouth",
    category: "mouths",
    faceId: RED_FACE_ID,
    tokenId: "137",
    idName: "mouths-red-dirty-mouth",
  },
  {
    id: 193,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-red-emo-mouth.png",
    name: "emo mouth",
    category: "mouths",
    faceId: RED_FACE_ID,
    tokenId: "136",
    idName: "mouths-red-emo-mouth",
  },
  {
    id: 194,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-red-no-lip-mouth.png",
    name: "no lip mouth",
    category: "mouths",
    faceId: RED_FACE_ID,
    tokenId: "134",
    idName: "mouths-red-no-lip-mouth",
  },
  {
    id: 195,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-red-real-small-lips-mouth.png",
    name: "real small lips",
    category: "mouths",
    faceId: RED_FACE_ID,
    tokenId: "135",
    idName: "mouths-red-real-small-lips-mouth",
  },
  {
    id: 196,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-red-shrek-mouth.png",
    name: "shrek mouth",
    category: "mouths",
    faceId: RED_FACE_ID,
    tokenId: "138",
    idName: "mouths-red-shrek-mouth",
  },
  {
    id: 197,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-red-slice-mouth.png",
    name: "slice mouth",
    category: "mouths",
    faceId: RED_FACE_ID,
    tokenId: "139",
    idName: "mouths-red-slice-mouth",
  },
  {
    id: 198,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-red-trustworthy-mouth.png",
    name: "trustworthy mouth",
    category: "mouths",
    faceId: RED_FACE_ID,
    tokenId: "141",
    idName: "mouths-red-trustworthy-mouth",
  },
  {
    id: 199,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-red-weird-3d-mouth.png",
    name: "weird 3d mouth",
    category: "mouths",
    faceId: RED_FACE_ID,
    tokenId: "132",
    idName: "mouths-red-weird-3d-mouth",
  },
  {
    id: 200,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-universal-aghast-mouth.png",
    name: "aghast mouth",
    category: "mouths",
    faceId: undefined,
    tokenId: "174",
    idName: "mouths-universal-aghast-mouth",
  },
  {
    id: 201,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-universal-anime-drool.png",
    name: "anime drool",
    category: "mouths",
    faceId: undefined,
    tokenId: "163",
    idName: "mouths-universal-anime-drool",
  },
  {
    id: 202,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-universal-blank-mouth.png",
    name: "blank mouth",
    category: "mouths",
    faceId: undefined,
    tokenId: "164",
    idName: "mouths-universal-blank-mouth",
  },
  {
    id: 203,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-universal-insane-sad-mouth.png",
    name: "insane sad mouth",
    category: "mouths",
    faceId: undefined,
    tokenId: "166",
    idName: "mouths-universal-insane-sad-mouth",
  },
  {
    id: 204,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-universal-jealous-mouth.png",
    name: "jealous mouth",
    category: "mouths",
    faceId: undefined,
    tokenId: "167",
    idName: "mouths-universal-jealous-mouth",
  },
  {
    id: 205,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-universal-nerd-mouth.png",
    name: "nerd mouth",
    category: "mouths",
    faceId: undefined,
    tokenId: "168",
    idName: "mouths-universal-nerd-mouth",
  },
  {
    id: 206,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-universal-salivate-tongue.png",
    name: "salivate tongue",
    category: "mouths",
    faceId: undefined,
    tokenId: "169",
    idName: "mouths-universal-salivate-tongue",
  },
  {
    id: 207,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-universal-shakey-mouth.png",
    name: "shakey mouth",
    category: "mouths",
    faceId: undefined,
    tokenId: "172",
    idName: "mouths-universal-shakey-mouth",
  },
  {
    id: 208,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-universal-shh-mouth.png",
    name: "shh mouth",
    category: "mouths",
    faceId: undefined,
    tokenId: "175",
    idName: "mouths-universal-shh-mouth",
  },
  {
    id: 209,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-universal-slender-mouth.png",
    name: "slender mouth",
    category: "mouths",
    faceId: undefined,
    tokenId: "176",
    idName: "mouths-universal-slender-mouth",
  },
  {
    id: 210,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-yellow-bit-lip-mouth.png",
    name: "bit lip mouth",
    category: "mouths",
    faceId: YELLOW_FACE_ID,
    tokenId: "142",
    idName: "mouths-yellow-bit-lip-mouth",
  },
  {
    id: 211,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-yellow-cursed-mouth.png",
    name: "cursed mouth",
    category: "mouths",
    faceId: YELLOW_FACE_ID,
    tokenId: "149",
    idName: "mouths-yellow-cursed-mouth",
  },
  {
    id: 212,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-yellow-cute-kitty-mouth.png",
    name: "cute kitty mouth",
    category: "mouths",
    faceId: YELLOW_FACE_ID,
    tokenId: "143",
    idName: "mouths-yellow-cute-kitty-mouth",
  },
  {
    id: 213,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-yellow-dirty-mouth.png",
    name: "dirty mouth",
    category: "mouths",
    faceId: YELLOW_FACE_ID,
    tokenId: "146",
    idName: "mouths-yellow-dirty-mouth",
  },
  {
    id: 214,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-yellow-emo-mouth.png",
    name: "emo mouth",
    category: "mouths",
    faceId: YELLOW_FACE_ID,
    tokenId: "144",
    idName: "mouths-yellow-emo-mouth",
  },
  {
    id: 215,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-yellow-no-lip-mouth.png",
    name: "no lip mouth",
    category: "mouths",
    faceId: YELLOW_FACE_ID,
    tokenId: "147",
    idName: "mouths-yellow-no-lip-mouth",
  },
  {
    id: 216,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-yellow-real-small-lips-mouth.png",
    name: "real small lips",
    category: "mouths",
    faceId: YELLOW_FACE_ID,
    tokenId: "148",
    idName: "mouths-yellow-real-small-lips-mouth",
  },
  {
    id: 217,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-yellow-shrek-mouth.png",
    name: "shrek mouth",
    category: "mouths",
    faceId: YELLOW_FACE_ID,
    tokenId: "150",
    idName: "mouths-yellow-shrek-mouth",
  },
  {
    id: 218,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-yellow-slice-mouth.png",
    name: "slice mouth",
    category: "mouths",
    faceId: YELLOW_FACE_ID,
    tokenId: "151",
    idName: "mouths-yellow-slice-mouth",
  },
  {
    id: 219,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-yellow-trustworthy-mouth.png",
    name: "trustworthy mouth",
    category: "mouths",
    faceId: YELLOW_FACE_ID,
    tokenId: "155",
    idName: "mouths-yellow-trustworthy-mouth",
  },
  {
    id: 220,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/mouths-yellow-weird-3d-mouth.png",
    name: "weird 3d mouth",
    category: "mouths",
    faceId: YELLOW_FACE_ID,
    tokenId: "145",
    idName: "mouths-yellow-weird-3d-mouth",
  },
  {
    id: 221,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-blue-scar.png",
    name: "scar",
    category: "options",
    faceId: BLUE_FACE_ID,
    tokenId: "223",
    idName: "options-blue-scar",
  },
  {
    id: 222,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-potato-scar.png",
    name: "scar",
    category: "options",
    faceId: POTATO_FACE_ID,
    tokenId: "221",
    idName: "options-potato-scar",
  },
  {
    id: 223,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-red-scar.png",
    name: "scar",
    category: "options",
    faceId: RED_FACE_ID,
    tokenId: "220",
    idName: "options-red-scar",
  },
  {
    id: 224,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-universal-blood-drop.png",
    name: "blood drop",
    category: "options",
    faceId: undefined,
    tokenId: "224",
    idName: "options-universal-blood-drop",
  },
  {
    id: 225,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-universal-bro-hat.png",
    name: "bro hat",
    category: "options",
    faceId: undefined,
    tokenId: "226",
    idName: "options-universal-bro-hat",
  },
  {
    id: 226,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-universal-chef-hat.png",
    name: "chef hat",
    category: "options",
    faceId: undefined,
    tokenId: "234",
    idName: "options-universal-chef-hat",
  },
  {
    id: 227,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-universal-cowboy-hat.png",
    name: "cowboy hat",
    category: "options",
    faceId: undefined,
    tokenId: "233",
    idName: "options-universal-cowboy-hat",
  },
  {
    id: 228,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-universal-heart.png",
    name: "heart",
    category: "options",
    faceId: undefined,
    tokenId: "225",
    idName: "options-universal-heart",
  },
  {
    id: 229,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-universal-pacifier.png",
    name: "pacifier",
    category: "options",
    faceId: undefined,
    tokenId: "234",
    idName: "options-universal-pacifier",
  },
  {
    id: 230,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-universal-quiver-water.png",
    name: "quiver water",
    category: "options",
    faceId: undefined,
    tokenId: "227",
    idName: "options-universal-quiver-water",
  },
  {
    id: 231,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-universal-ring.png",
    name: "ring",
    category: "options",
    faceId: undefined,
    tokenId: "228",
    idName: "options-universal-ring",
  },
  {
    id: 232,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-universal-sparkle.png",
    name: "sparkle",
    category: "options",
    faceId: undefined,
    tokenId: "229",
    idName: "options-universal-sparkle",
  },
  {
    id: 233,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-universal-tear-drop.png",
    name: "tear drop",
    category: "options",
    faceId: undefined,
    tokenId: "230",
    idName: "options-universal-tear-drop",
  },
  {
    id: 234,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-universal-top-hat.png",
    name: "top hat",
    category: "options",
    faceId: undefined,
    tokenId: "235",
    idName: "options-universal-top-hat",
  },
  {
    id: 235,
    imgUrl:
      "https://cursedemojis.world/assets/images/traits/options-yellow-scar.png",
    name: "scar",
    category: "options",
    faceId: YELLOW_FACE_ID,
    tokenId: "231",
    idName: "options-yellow-scar",
  },
];

export const traits = [
  // "base-blue-true-blue",
  // "base-potato-yukon-gold",
  // "base-red-big-red.png",
  // "base-yellow-old-yeller",
  "bodyparts-blue-butt",
  "bodyparts-blue-eyy-hands",
  "bodyparts-blue-hang-loose-hand",
  "bodyparts-blue-pray-hands",
  "bodyparts-blue-question-hand",
  "bodyparts-blue-sad-wrinkle",
  "bodyparts-blue-shh-hand",
  "bodyparts-blue-shrek-ear-left",
  "bodyparts-blue-shrek-ear-right",
  "bodyparts-blue-slender-nose",
  "bodyparts-blue-sparse-hair",
  "bodyparts-blue-weird-3d-nose",
  "bodyparts-potato-butt",
  "bodyparts-potato-eyy-hands",
  "bodyparts-potato-hang-loose-hand",
  "bodyparts-potato-pray-hands",
  "bodyparts-potato-question-hand",
  "bodyparts-potato-sad-wrinkle",
  "bodyparts-potato-shh-hand",
  "bodyparts-potato-shrek-ear-left",
  "bodyparts-potato-shrek-ear-right",
  "bodyparts-potato-slender-nose",
  "bodyparts-potato-sparse-hair",
  "bodyparts-potato-spud-nose",
  "bodyparts-potato-weird-3d-nose",
  "bodyparts-red-butt",
  "bodyparts-red-eyy-hands",
  "bodyparts-red-question-hand",
  "bodyparts-red-sad-wrinkle",
  "bodyparts-red-shh-hand",
  "bodyparts-red-shrek-ear-left",
  "bodyparts-red-shrek-ear-right",
  "bodyparts-red-slender-nose",
  "bodyparts-red-sparse-hair",
  "bodyparts-red-weird-3d-nose",
  "bodyparts-yellow-butt",
  "bodyparts-yellow-eyy-hands",
  "bodyparts-yellow-hang-loose-hand",
  "bodyparts-yellow-pray-hands",
  "bodyparts-yellow-question-hand",
  "bodyparts-yellow-sad-wrinkle",
  "bodyparts-yellow-shh-hand",
  "bodyparts-yellow-shrek-ear-left",
  "bodyparts-yellow-shrek-ear-right",
  "bodyparts-yellow-slender-nose",
  "bodyparts-yellow-sparse-hair",
  "bodyparts-yellow-weird-3d--nose",
  "eyes-blue-bloodshot-eye-left",
  "eyes-blue-bloodshot-eye-right",
  "eyes-blue-brain-slobber-eye",
  "eyes-blue-bro-brow",
  "eyes-blue-bro-eye-left",
  "eyes-blue-bro-eye-right",
  "eyes-blue-crammed-eyes",
  "eyes-blue-cyclops-eye",
  "eyes-blue-dark-eyes",
  "eyes-blue-dirty-eye",
  "eyes-blue-dont-look-brow",
  "eyes-blue-dont-look-eye",
  "eyes-blue-emo-eye",
  "eyes-blue-flimsy-cry-eye-left",
  "eyes-blue-flimsy-cry-eye-right",
  "eyes-blue-heart-eye",
  "eyes-blue-heart-wink",
  "eyes-blue-lashes-eyes",
  "eyes-blue-lazy-eye",
  "eyes-blue-real-eye-left",
  "eyes-blue-real-eye-right",
  "eyes-blue-real-tears-left",
  "eyes-blue-real-tears-right",
  "eyes-blue-seductive-eye-left",
  "eyes-blue-seductive-eye-right",
  "eyes-blue-shiny-eyes",
  "eyes-blue-shrek-eyes",
  "eyes-blue-sleepy-eye",
  "eyes-potato-bloodshot-eye-left",
  "eyes-potato-bloodshot-eye-right",
  "eyes-potato-brain-slobber-eye",
  "eyes-potato-bro-brow",
  "eyes-potato-bro-eye-left",
  "eyes-potato-bro-eye-right",
  "eyes-potato-crammed-eyes",
  "eyes-potato-cyclops-eye",
  "eyes-potato-dark-eyes",
  "eyes-potato-dirty-eye",
  "eyes-potato-dont-look-brow",
  "eyes-potato-dont-look-eye",
  "eyes-potato-emo-eye",
  "eyes-potato-flimsy-cry-eye-left",
  "eyes-potato-flimsy-cry-eye-right",
  "eyes-potato-heart-eye",
  "eyes-potato-heart-wink",
  "eyes-potato-lashes-eyes",
  "eyes-potato-lazy-eye",
  "eyes-potato-real-eye-left",
  "eyes-potato-real-eye-right",
  "eyes-potato-real-tears-left",
  "eyes-potato-real-tears-right",
  "eyes-potato-seductive-eye-left",
  "eyes-potato-seductive-eye-right",
  "eyes-potato-shiny-eyes",
  "eyes-potato-shrek-eyes",
  "eyes-potato-sleepy-eye",
  "eyes-red-bloodshot-eye-left",
  "eyes-red-bloodshot-eye-right",
  "eyes-red-brain-slobber-eye",
  "eyes-red-bro-brow",
  "eyes-red-bro-eye-left",
  "eyes-red-bro-eye-right",
  "eyes-red-crammed-eyes",
  "eyes-red-cyclops-eye",
  "eyes-red-dark-eyes",
  "eyes-red-dirty-eye",
  "eyes-red-dont-look-brow",
  "eyes-red-dont-look-eye",
  "eyes-red-emo-eye",
  "eyes-red-flimsy-cry-eye-left",
  "eyes-red-flimsy-cry-eye-right",
  "eyes-red-heart-eye",
  "eyes-red-heart-wink",
  "eyes-red-lashes-eyes",
  "eyes-red-lazy-eye",
  "eyes-red-real-eye-left",
  "eyes-red-real-eye-right",
  "eyes-red-real-tears-left",
  "eyes-red-real-tears-right",
  "eyes-red-seductive-eye-left",
  "eyes-red-seductive-eye-right",
  "eyes-red-shiny-eyes",
  "eyes-red-shrek-eyes",
  "eyes-red-sleepy-eye",
  "eyes-universal-beavis-brow-left",
  "eyes-universal-beavis-brow-right",
  "eyes-universal-blank-eyes",
  "eyes-universal-joker-eye-left",
  "eyes-universal-joker-eye-right",
  "eyes-universal-lamb-eyes",
  "eyes-universal-seductive-lash",
  "eyes-yellow-bloodshot-eye-left",
  "eyes-yellow-bloodshot-eye-right",
  "eyes-yellow-brain-slobber-eye",
  "eyes-yellow-bro-brow",
  "eyes-yellow-bro-eye-left",
  "eyes-yellow-bro-eye-right",
  "eyes-yellow-crammed-eyes",
  "eyes-yellow-cyclops-eye",
  "eyes-yellow-dark-eyes",
  "eyes-yellow-dirty-eye",
  "eyes-yellow-dont-look-brow",
  "eyes-yellow-dont-look-eye",
  "eyes-yellow-emo-eye",
  "eyes-yellow-flimsy-cry-eye-left",
  "eyes-yellow-flimsy-cry-eye-right",
  "eyes-yellow-heart-eye",
  "eyes-yellow-heart-wink",
  "eyes-yellow-lashes-eyes",
  "eyes-yellow-lazy-eye",
  "eyes-yellow-real-eye-left",
  "eyes-yellow-real-eye-right",
  "eyes-yellow-real-tears-left",
  "eyes-yellow-real-tears-right",
  "eyes-yellow-seductive-eye-left",
  "eyes-yellow-seductive-eye-right",
  "eyes-yellow-shiny-eyes",
  "eyes-yellow-shrek-eyes",
  "eyes-yellow-sleepy-eye",
  "mouths-blue-bit-lip-mouth",
  "mouths-blue-cursed-mouth",
  "mouths-blue-cute-kitty-mouth",
  "mouths-blue-dirty-mouth",
  "mouths-blue-emo-mouth",
  "mouths-blue-no-lip-mouth",
  "mouths-blue-real-small-lips-mouth",
  "mouths-blue-shrek-mouth",
  "mouths-blue-slice-mouth",
  "mouths-blue-trustworthy-mouth",
  "mouths-blue-weird-3d-mouth",
  "mouths-potato-bit-lip-mouth",
  "mouths-potato-cursed-mouth",
  "mouths-potato-cute-kitty-mouth",
  "mouths-potato-dirty-mouth",
  "mouths-potato-emo-mouth",
  "mouths-potato-no-lip-mouth",
  "mouths-potato-real-small-lips-mouth",
  "mouths-potato-shrek-mouth",
  "mouths-potato-slice-mouth",
  "mouths-potato-trustworthy-mouth",
  "mouths-potato-weird-3d-mouth",
  "mouths-red-bit-lip-mouth",
  "mouths-red-cursed-mouth",
  "mouths-red-cute-kitty-mouth",
  "mouths-red-dirty-mouth",
  "mouths-red-emo-mouth",
  "mouths-red-no-lip-mouth",
  "mouths-red-real-small-lips-mouth",
  "mouths-red-shrek-mouth",
  "mouths-red-slice-mouth",
  "mouths-red-trustworthy-mouth",
  "mouths-red-weird-3d-mouth",
  "mouths-universal-aghast-mouth",
  "mouths-universal-anime-drool",
  "mouths-universal-blank-mouth",
  "mouths-universal-insane-sad-mouth",
  "mouths-universal-jealous-mouth",
  "mouths-universal-nerd-mouth",
  "mouths-universal-salivate-tongue",
  "mouths-universal-shakey-mouth",
  "mouths-universal-shh-mouth",
  "mouths-universal-slender-mouth",
  "mouths-yellow-bit-lip-mouth",
  "mouths-yellow-cursed-mouth",
  "mouths-yellow-cute-kitty-mouth",
  "mouths-yellow-dirty-mouth",
  "mouths-yellow-emo-mouth",
  "mouths-yellow-no-lip-mouth",
  "mouths-yellow-real-small-lips-mouth",
  "mouths-yellow-shrek-mouth",
  "mouths-yellow-slice-mouth",
  "mouths-yellow-trustworthy-mouth",
  "mouths-yellow-weird-3d-mouth",
  "options-blue-scar",
  "options-potato-scar",
  "options-red-scar",
  "options-universal-blood-drop",
  "options-universal-bro-hat",
  "options-universal-chef-hat",
  "options-universal-cowboy-hat",
  "options-universal-heart",
  "options-universal-pacifier",
  "options-universal-quiver-water",
  "options-universal-ring",
  "options-universal-sparkle",
  "options-universal-tear-drop",
  "options-universal-top-hat",
  "options-yellow-scar",
];

export const getPartsFromMap = (color: number | undefined) => {
  return newTraitMap.filter((row) => {
    return row.faceId === color;
  });
};

export const UNIVERSAL_TRAITS = getPartsFromMap(undefined);
export const RED_TRAITS = getPartsFromMap(RED_FACE_ID);
export const BLUE_TRAITS = getPartsFromMap(BLUE_FACE_ID);
export const YELLOW_TRAITS = getPartsFromMap(YELLOW_FACE_ID);
export const POTATO_FEATURES = getPartsFromMap(POTATO_FACE_ID);
