import { NoMintLeft } from "./MintPageCursed/NoMintLeft";
import { ColorLight } from "./ColorLight";
import { TableBox } from "./TableBox";
import { LiveCountdownClock } from "./LiveCountdownClock";
import { REMIX_OPEN_DATE } from "../../config/dates";
import { MintCountDown } from "./MintCountDown";
import React from "react";
import { MintCountInfo } from "../../config/api/rmx-api";

interface MintPageJustMintedProps {
  mintCountInfo: MintCountInfo;
}

export const MintPageJustMinted = ({
  mintCountInfo,
}: MintPageJustMintedProps) => {
  return (
    <div className="mint-page cursed">
      <div className={"mint-content-card"}>
        <header className="d-flex flex-row justify-content-between header">
          <ColorLight color={"green"} />
          <div
            className={
              "text-center content w-100 d-flex align-items-center justify-content-center"
            }
          >
            PA$S SECURED ⍻
          </div>
          <ColorLight color={"green"} />
        </header>
        <div className={"content copy"}>
          <p>
            Congratulations! You’ve minted your RMX Pass: Emoji Base. This NFT
            is a key that unlocks the RMX Console.
          </p>
          <p>Come back to make your Cursed Emoji on January 19!</p>
        </div>
      </div>

      <section style={{ maxWidth: "512px" }}>
        <TableBox
          keys={["Remix In:", "Mints Left:"]}
          values={[
            <LiveCountdownClock date={REMIX_OPEN_DATE} />,
            <MintCountDown mintCountInfo={mintCountInfo} />,
          ]}
          leftWidth={"medium"}
        />
      </section>
    </div>
  );
};
