import { NavLink } from "react-router-dom";
import "../../RmxButton.scss";
import "./CursedEmojisNav.scss";

const CursedEmojisNav = () => {
  const links: { content: string; location: string }[] = [
    {
      content: "MINT",
      location: "/mint",
    },
    {
      content: "REMIX",
      location: "/remix",
    },
    {
      content: "AUCTION",
      location: "/auction",
    },
    {
      content: "ABOUT",
      location: "/about",
    },
  ];

  return (
    <nav className={`cursed-emojis-nav`}>
      {links.map((link) => (
        <NavLink
          to={link.location}
          className={({ isActive }) =>
            `rmx-button nav ${isActive ? "active" : ""}`
          }
          key={link.location}
        >
          {link.content}
        </NavLink>
      ))}
    </nav>
  );
};
export default CursedEmojisNav;
