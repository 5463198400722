import "../CursedEmojis/MintContent.scss";
import "./AuctionContent.scss";
import "./CursedEmojis.scss";
import CursedEmojisNav from "./CursedEmojisNav/CursedEmojisNav";
import React from "react";
import { AuctionPhases } from "../../config/api/types";
import { LightColors } from "../../types";
import { ColorLight } from "./ColorLight";

interface AuctionContentProps {
  auctionPhase: AuctionPhases;
}

export const AuctionContent = ({ auctionPhase }: AuctionContentProps) => {
  const determineLightColor = (): LightColors => {
    if (auctionPhase === "pre-auction") {
      return "grey";
    } else if (auctionPhase === "open-auction") {
      return "green";
    } else {
      return "red";
    }
  };
  return (
    <div className="mint-content">
      <div className={"genesis-subnav text-center"}>
        <CursedEmojisNav />
      </div>

      <section className={"auction-content"}>
        <div className={"mint-content-card"}>
          <header className="d-flex flex-row justify-content-between header">
            <ColorLight color={determineLightColor()} />
            <div
              className={
                "text-center content w-100 d-flex align-items-center justify-content-center"
              }
            >
              AN NFT 0F NFT BY NḞTS
            </div>
            <ColorLight color={determineLightColor()} />
          </header>
          <div className={"content copy"}>
            <p>
              The conversation between artist and collectors completes when yung
              jake creates a new NFT-based artwork from the Cursed Emojis CC0
              NFT collection.
            </p>
            <p>
              yung jake will put the new work up for auction and the proceeds
              will be split 50/50 with the collectors of each of the Cursed
              Emojis he uses.
            </p>
          </div>
          <div
            className={"content copy"}
            style={{ backgroundColor: "rgb(243, 246, 248)" }}
          >
            <section className={"d-flex justify-content-center"}>
              <img
                className={"auction-image"}
                src={
                  "https://cursedemojis.world/assets/images/auction-tbd-greybkg-420.png"
                }
                alt={"Auction image"}
                height={420}
                width={420}
                style={{ objectFit: "contain" }}
              />
            </section>
          </div>
        </div>
      </section>
    </div>
  );
};
