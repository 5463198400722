import React from "react";
import { MintCountInfo } from "../../config/api/rmx-api";
import CursedEmojisNav from "./CursedEmojisNav/CursedEmojisNav";
import MintPageCursed from "./MintPageCursed/MintPageCursed";
import MintPagePreMint from "./MintPageDisconnected/MintPagePreMint";
import MintPageUncursed, {
  NoMoreMintsContent,
  NoMoreMintsInfoBlock,
} from "./MintPageUncursed/MintPageUncursed";
import { FrontendConfig } from "../../config/api/types";
import { MintPageOpenNotConnected } from "./MintPageDisconnected/MintPageOpenNotConnected";
import { GenesisMintUIMetadata } from "../../types";
import { LiveCountdownClock } from "./LiveCountdownClock";
import { REMIX_OPEN_DATE } from "../../config/dates";
import { MintCountDown } from "./MintCountDown";
import { TableBox } from "./TableBox";

//todo: styles come from parent component currently

export interface GenesisMintFlowProps {
  isConnected: true | boolean | false;
  isUserAllowed: boolean | null;
  mintCountInfo: MintCountInfo;
  frontEndConfig: FrontendConfig;
  hasUserAlreadyMinted: boolean;
}

export const GenesisMintContent = ({
  isConnected,
  isUserAllowed,
  mintCountInfo,
  frontEndConfig,
  hasUserAlreadyMinted,
}: GenesisMintFlowProps) => {
  return (
    <div className={"mint-content"}>
      <div className={"genesis-subnav text-center"}>
        <CursedEmojisNav />
      </div>

      {frontEndConfig.mintPhase === "pre-mint" && (
        <MintPagePreMint
          mintCountInfo={mintCountInfo}
          frontEndConfig={frontEndConfig}
        />
      )}

      {frontEndConfig.mintPhase === "open-mint" && (
        <>
          {!isConnected && (
            <MintPageOpenNotConnected
              mintCountInfo={mintCountInfo}
              frontEndConfig={frontEndConfig}
            />
          )}

          {isConnected && isUserAllowed && (
            <MintPageCursed
              mintCountInfo={mintCountInfo}
              frontEndConfig={frontEndConfig}
              hasUserAlreadyMinted={hasUserAlreadyMinted}
            />
          )}

          {isConnected && !isUserAllowed && (
            <MintPageUncursed
              mintCountInfo={mintCountInfo}
              frontEndConfig={frontEndConfig}
            />
          )}
        </>
      )}

      {frontEndConfig.mintPhase === "post-mint" && (
        <>
          <NoMoreMintsContent
            collectionUrl={frontEndConfig.genesisCollectionUrl}
          />
          <section style={{ maxWidth: "512px" }}>
            <TableBox
              keys={["Remix In:", "Mints Left:"]}
              values={[
                <LiveCountdownClock date={REMIX_OPEN_DATE} />,
                <MintCountDown mintCountInfo={mintCountInfo} />,
              ]}
              leftWidth={"medium"}
            />
          </section>
        </>
      )}
    </div>
  );
};
