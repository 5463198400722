import Bugsnag from "@bugsnag/browser";
import { BigNumber } from "ethers";
import { useCallback, useEffect, useState } from "react";
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { createRemixedAssets } from "../config/api/rmx-api";
import { CreateCursedEmojiStatus } from "../config/api/types";
import { useErrorHandler } from "react-error-boundary";
import contract from "../contracts/CursedEmojis.json";

type RemixTransactionStatus =
  | "start"
  | "waiting_on_wallet_confirmation"
  | "remixing_token"
  | "remixing_assets"
  | "remixed";

export interface RemixTransaction {
  status: RemixTransactionStatus;
  remix?: () => Promise<void>;
}

export interface UseRemixProps {
  genesisMintContractAddress: string;
  tokenId: number | null;
  getEmojiAsFileBlob: () => Promise<Blob>;
  //todo: consider renaming CreateCursedEmojiStatus
  onSuccess: (createCursedEmojiStatus: CreateCursedEmojiStatus) => void;
  getFabricJSON: () => string;
}

export const useRemix = ({
  genesisMintContractAddress,
  tokenId,
  getEmojiAsFileBlob,
  onSuccess,
  getFabricJSON,
}: UseRemixProps): RemixTransaction => {
  const [status, setStatus] = useState<RemixTransactionStatus>("start");
  const [done, setDone] = useState<boolean>(false);
  const [imageData, setImageData] = useState<Blob | null>(null);
  const [fabricJSON, setFabricJSON] = useState<string>();

  const handleError = useErrorHandler();
  console.log("Token ID", tokenId);
  const preparedContractWrite = usePrepareContractWrite({
    address: genesisMintContractAddress as `0x${string}`,
    abi: contract.abi,
    functionName: "remix",
    args:
      tokenId !== null && Number(tokenId) >= 0
        ? [BigNumber.from(tokenId)]
        : undefined,
    enabled: !!genesisMintContractAddress && tokenId !== null && !done,
    onSettled: (result) => {
      console.log("prepare remix tx settled:", result);
    },
    onError: (err) => {
      console.error(`Remix transaction failure: `, err);
      handleError(err);
      throw err;
    },
    onSuccess: (result) => {
      console.log("prepare remix tx success:", result);
    },
  });

  const contractWrite = useContractWrite(preparedContractWrite?.config);

  useEffect(() => {
    if (contractWrite.isLoading) {
      setStatus("waiting_on_wallet_confirmation");
    }
  }, [contractWrite.isLoading]);

  useEffect(() => {
    if (contractWrite.isSuccess) {
      setStatus("remixing_token");
    }
  }, [contractWrite.isSuccess]);

  useEffect(() => {
    if (contractWrite.isError) {
      setStatus("start");
    }
  }, [contractWrite.isError]);

  const _remixTx = useWaitForTransaction({
    hash: contractWrite.data?.hash,
    enabled: !!contractWrite.data?.hash,
    onSuccess: (data) => {
      if (tokenId != null && imageData != null) {
        const apiCall = async () => {
          console.log(
            "time to upload emoji canvas data and metadata to server"
          );
          setStatus("remixing_assets");

          return createRemixedAssets({
            tokenId,
            fabricJSON,
            remixTransactionHash: data.transactionHash,
            fromWallet: data.from,
            imageBuffer: imageData,
          })
            .then((mediaCheck) => {
              const emojiStatus: CreateCursedEmojiStatus = {
                nftTxnHash: data.transactionHash,
                ...mediaCheck,
              };
              console.log("got the status", emojiStatus);
              return emojiStatus;
            })
            .catch((error) => {
              Bugsnag.addMetadata("createRemixedAssets", "tokenId", tokenId);
              Bugsnag.addMetadata(
                "createRemixedAssets",
                "fabricJSON",
                fabricJSON
              );
              Bugsnag.addMetadata(
                "createRemixedAssets",
                "remixTransactionHash",
                data.transactionHash
              );
              Bugsnag.addMetadata(
                "createRemixedAssets",
                "fromWallet",
                data.from
              );
              Bugsnag.addMetadata(
                "createRemixedAssets",
                "imageBuffer",
                imageData
              );
              Bugsnag.notify(error);
            });
        };
        apiCall()
          .then((emojiStatus: CreateCursedEmojiStatus) => {
            console.log("post apiCall", emojiStatus);
            setStatus("remixed");
            onSuccess(emojiStatus);
          })
          .catch((error) => {
            handleError(error);
          });
      }
    },
    onSettled: (result) => {
      console.log("remix tx finished:", result);
      Bugsnag.addMetadata(
        "useWaitForTransaction OnSettled remix",
        "remix tx finished:",
        result
      );
    },
    onError: (err) => {
      console.error(err);
      Bugsnag.addMetadata(
        "useWaitForTransaction remix",
        "hash",
        contractWrite.data?.hash
      );
      Bugsnag.notify(err);
    },
  });

  const remix = useCallback(async () => {
    const img = await getEmojiAsFileBlob();
    const fabricRawData = getFabricJSON();
    setImageData(img);
    setFabricJSON(fabricRawData);
    console.log("captured image data", img, fabricRawData);

    if (contractWrite.writeAsync) {
      console.log("writing remix to contract");
      contractWrite.writeAsync().then((result) => {
        console.log("remix writeAsync result:", result);
        result
          .wait()
          .then((receipt) => {
            console.log(`remix contract write awaited`, receipt);
            Bugsnag.addMetadata(
              "remix contract write awaited",
              "receipt",
              receipt
            );
            Bugsnag.notify(
              new Error("transaction settled outside the hook remix")
            );
          })
          .catch((err) => {
            Bugsnag.notify(err);
          });
      });
    } else {
      console.log("remix transaction not prepared", contractWrite);
    }
  }, [contractWrite.writeAsync]);

  //todo verify; seems wrong
  useEffect(() => {
    if (contractWrite.data) {
      setDone(true);
    }
  }, [contractWrite.data]);

  return {
    status,
    remix,
  };
};
