/**
 * @module axiosClient
 * A customized axios instance for accessing backend API
 */
import axios, { AxiosInstance } from "axios";

const API_SEGMENT_ROOT = "/api/v1";

// API endpoint URL; no trailing slash
// this works because we are serving frontend from backend
const API_URL = `${API_SEGMENT_ROOT}`;

const buildClient = () => {
  return axios.create({
    baseURL: API_URL,
    timeout: 360000,
  });
};

export type HTTPClient = Pick<
  AxiosInstance,
  "get" | "put" | "post" | "delete" | "head"
>;

let client: HTTPClient;

export const getAxiosClient = () => {
  if (!client) {
    client = buildClient();
  }
  return client;
};
