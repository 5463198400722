import { Col, Image } from "react-bootstrap";
import logo from "../../../images/cursed_emojis_logo.svg";
import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import questionMark2 from "../../../images/icon-question.svg";
import mobileLogoRmxConsole from "../../../images/Mobile-RMX-Console-Logo.svg";
import desktopLogoRmxConsole from "../../../images/Desktop-RMX-Console-Logo.svg";
import { ConnectKitButton } from "connectkit";

interface HeaderProps {
  url?: string;
  setShowHowto?: (value: boolean) => void;
  isConnected: boolean;
}

export default function Header({
  url,
  setShowHowto,
  isConnected,
}: HeaderProps) {
  return (
    <Col className={"builder-panel bg-light px-0 flex-grow-0"}>
      <div className={"header-builder w-100"}>
        <div className={"d-flex align-items-center header-builder-name gap-1"}>
          {window.innerWidth > 512 ? (
            <img className={"logo-image-name"} src={desktopLogoRmxConsole} />
          ) : (
            <img className={"logo-image-name"} src={mobileLogoRmxConsole} />
          )}
          <div className={"grey-lines"}></div>
        </div>
        <div className={"header-builder-content"}>
          {url ? (
            <Link to={url} style={{ flexGrow: 1 }}>
              <Image
                className={"logo m-auto"}
                src={logo}
                alt={`Link to ${url}`}
              />
            </Link>
          ) : (
            <Image className={"logo m-auto"} src={logo} alt={"Cursed Emojis"} />
          )}
          <ConnectKitButton theme="minimal" showAvatar={false} />
          {typeof setShowHowto === "function" && (
            <button
              className={"button-helper d-flex justify-content-center"}
              onClick={() => {
                setShowHowto(true);
              }}
            >
              <img
                src={questionMark2}
                className={"link-icon-header"}
                alt={"link to faqs"}
              />
            </button>
          )}
        </div>
      </div>
    </Col>
  );
}
