import React from "react";
import "./Overlay.scss";

export const Overlay = () => {
  return (
    <span data-testid={"overlay"} className={"overlay"}>
      {" "}
    </span>
  );
};
