import React from "react";
import RmxConsole from "./RmxConsole";
import { EmojiCanvasProvider } from "../EmojiCanvas/EmojiCanvasContext";
import { Navigate } from "react-router-dom";
import { FrontendConfig } from "../../config/api/types";

interface RmxConsoleContainerProps {
  ready: boolean;
  disabled: boolean;
  frontendConfig: FrontendConfig;
}

const RmxConsoleContainer = ({
  ready,
  disabled,
  frontendConfig,
}: RmxConsoleContainerProps) => {
  return ready ? (
    !disabled ? (
      <>
        <EmojiCanvasProvider>
          <RmxConsole frontendConfig={frontendConfig} />
        </EmojiCanvasProvider>
      </>
    ) : (
      <Navigate to={"/"} replace />
    )
  ) : (
    <></>
  );
};

export default RmxConsoleContainer;
