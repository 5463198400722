import React, { useEffect, useState } from "react";
import "./Collection.scss";
import { CursedEmojiTabHeader } from "../CursedEmojis/CursedEmojiTabHeader/CursedEmojiTabHeader";
import NFTReveal from "../RmxConsole/NFTReveal";
import { FrontendConfig } from "../../config/api/types";
import { OwnedNft } from "alchemy-sdk";

interface CollectionProps {
  frontEndConfig: FrontendConfig;
}

export const Collection = ({ frontEndConfig }: CollectionProps) => {
  const [metadata, setMetadata] = useState([]);
  const [showDetail, setShowDetail] = useState<string>("");

  const createListOfHexId = () => {
    const idsArray = [];
    for (let i = 0; i < 512; i++) {
      const lenghtID = i.toString(16).length;

      let pad = 2;
      if (lenghtID > 2) {
        pad = 4;
      }
      idsArray.push("0x" + i.toString(16).padStart(pad, "0"));
    }
    return idsArray;
  };

  useEffect(() => {
    const getMetadata = async () => {
      const ids = createListOfHexId();

      const metadata = ids.map((id) => {
        return fetch(
          `https://cursedemojis.world/0xbd697a422a5cbd443a2e8610bfae19ed94f9e514/${id}/0x01.json`
        ).then((data) => {
          return data.json();
        });
      });

      const result = await Promise.allSettled(metadata);
      return result.map((metadata) => {
        if (metadata.status !== "rejected") {
          return metadata.value;
        }
      });
    };

    getMetadata().then((result) => {
      console.log("Result", result);
      setMetadata(result);
    });
  }, []);

  // https://cursedemojis.world/0xbd697a422a5cbd443a2e8610bfae19ed94f9e514/0x01fe/0x00.png

  return (
    <section className={"collection-container"}>
      <CursedEmojiTabHeader />
      <div className={"grid-container"}>
        <section className={"collection-grid"}>
          {metadata.map((metadata) => {
            console.log("metadata", metadata);
            if (metadata) {
              return (
                <button
                  className={"nft-card"}
                  onClick={() => {
                    setShowDetail(metadata.id);
                  }}
                >
                  <div className={"nft-name"}>{metadata.name}</div>
                  <img src={metadata.image} className={"nft-image"} />
                </button>
              );
            }
          })}
        </section>
        {showDetail !== "" && (
          <NFTReveal
            cursedEmojiCreationStatus={{
              imgUri:
                "https://cursedemojis.world/0x4823ca250ea95db3ec7b59e8b0ed5db025b3d51a/0x32/0x01.png",
              metadataUri:
                "https://cursedemojis.world/0x4823ca250ea95db3ec7b59e8b0ed5db025b3d51a/0x32/0x01.json",
              nftTxnHash:
                "0xee7c7aa22d57eb7ee871e03815165075933ce269bf1f7a22de141c5ae653ed1f",
              // openSeaUri: "https://testnets.opensea.io/assets/goerli/0x4823ca250ea95db3ec7b59e8b0ed5db025b3d51a/50",
            }}
            token={{ tokenId: "47" } as unknown as OwnedNft}
            frontEndConfig={frontEndConfig}
            newMetadata={metadata[47]}
            usedTraits={["joker eye right"]}
          />
        )}
      </div>
    </section>
  );
};
