import yellowLight from "../../images/YellowButton.svg";
import redLight from "../../images/RedButton.svg";
import greenLight from "../../images/GreenButton.svg";
import React from "react";
import "./MintContent.scss";
import "./ScheduleEventWindow.scss";

type EventState = "FUTURE" | "PAST" | "CURRENT";

type EventStateToImageString = {
  [key in EventState as string]: string;
};
type EventStateToImageStringPair = {
  [key in EventState as string]: [string, string];
};

const LUT: EventStateToImageString = {
  PAST: redLight,
  CURRENT: greenLight,
  FUTURE: yellowLight,
};

const CLASSNAME_LUT: EventStateToImageString = {
  PAST: "past",
  CURRENT: "current",
  FUTURE: "future",
};

const ALT_LUT: EventStateToImageString = {
  PAST: "red light closed",
  CURRENT: "green light open",
  FUTURE: "yellow light not open yet",
};

const DECORATION_LUT: EventStateToImageStringPair = {
  PAST: ["🅇 ", ": CLOSED   🅇"],
  CURRENT: ["☉ ", ": LIVE   ☉"],
  FUTURE: ["", ""],
};

/**
 * ScheduleEventWindow - represents a scheduled event with open and close dates
 *
 * The styling can be controlled with {eventWindowState}
 *
 * Run storybook to see examples
 */
export const ScheduleEventWindow = ({
  title,
  footer,
  openDate,
  closeDate,
  eventWindowState = "FUTURE",
  linkUrl,
}: {
  title: string;
  footer: string;
  openDate: string;
  closeDate: string;
  eventWindowState?: EventState;
  linkUrl?: string;
}) => {
  // TODO: add support for time window open/closed state representation based
  // on current times
  // TODO: fetch canonical datetimes from server to avoid discrepancy with
  // server enforced rules

  const decorateTitleWithState = (title: string, state: EventState) => {
    if (state === "CURRENT" && linkUrl) {
      return (
        <a href={linkUrl}>
          {DECORATION_LUT[state][0]} {title}
          {DECORATION_LUT[state][1]}
        </a>
      );
    }
    return DECORATION_LUT[state][0] + title + DECORATION_LUT[state][1];
  };

  const img = LUT[eventWindowState];

  return (
    <div
      className={`mint-content-card schedule-event-window ${CLASSNAME_LUT[eventWindowState]}`}
    >
      <div className="header content text-center">
        {decorateTitleWithState(title, eventWindowState)}
      </div>
      <div className={"d-flex table text-left"}>
        <div className={"d-flex flex-column justify-content-center my-0"}>
          <img
            className="yellow-button content h-100"
            src={img}
            alt={`${ALT_LUT[eventWindowState]}`}
          />
        </div>
        <div className={"d-flex flex-column my-0"}>
          <div className={"content inner-table flex-shrink-1"}>Opens:</div>
          <div className={"content inner-table flex-shrink-1"}>Closes:</div>
        </div>
        <div className={"flex-grow-1 d-flex flex-column my-0"}>
          <div
            className={"content inner-table text-left"}
            style={{ textAlign: "left" }}
          >
            {openDate}
          </div>
          <div
            className={"content inner-table text-left"}
            style={{ textAlign: "left" }}
          >
            {closeDate}
          </div>
        </div>
      </div>
      <footer className="content text-center">{footer}</footer>
    </div>
  );
};
