import { OwnedNft } from "alchemy-sdk";
import { TraitCategoryMap } from "../../../../types";

export interface OwnedGenesisMintNft extends OwnedNft {
  remixCount: number;
}

export interface WalletStatus {
  isSignedUp: boolean;
  signupUrl: string;
  mintCount: number;
  genesisMintCount: number;
  totalRemixes: number;
  genesisMintTokens: OwnedGenesisMintNft[];
}

export type RemixPhases = "pre-remix" | "open-remix" | "post-remix";
export type AuctionPhases = "pre-auction" | "open-auction" | "post-auction";
export type MintPhases = "pre-mint" | "open-mint" | "post-mint";

export interface FrontendConfig {
  premintUrl: string;
  maxMintsPerWallet: number;
  genesisCollectionUrl: string;
  genesisMintContractAddress: string;
  etherScanUrl: string;
  mintPhase: MintPhases;
  remixPhase: RemixPhases;
  auctionPhase: AuctionPhases;
  traitCategoryMap: TraitCategoryMap;
}

// returned by 'create' endpoints
// used as payload for 'status check' endpoint
export type NftStatusCheck = {
  transactionId: string;
  walletId: string;
  imgUri: string;
  metadataUri: string;
  error?: string;
  contractAddress: string;
};

// todo: reconcile type with back end
//   is there a quick way to keep it in sync?
export type MediaCheck = {
  metadataUri: string;
  imgUri: string;
  error?: string;
};

// returned by 'status check' endpoints
export interface CreateCursedEmojiStatus {
  nftTxnHash: string;
  imgUri: string;
  metadataUri: string;
  openSeaLink?: string;
  error?: string;
}

export const YELLOW_FACE_ID = 1;
export const BLUE_FACE_ID = 2;
export const RED_FACE_ID = 3;
export const POTATO_FACE_ID = 4;
