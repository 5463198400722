import { fabric } from "fabric";
import { Trait } from "../../../../types";
import { Part } from "../../types";

export type TraitList = string[];

export type AddFeatureResult = {
  traits: TraitList;
  allTraitsPresent: Part[];
  success: boolean;
};

export function canFeatureBeAdded(
  feature: Trait,
  canvas: Pick<fabric.Canvas, "getObjects">,
  maxFeatures: number
): AddFeatureResult {
  const names: Set<string> = new Set();
  const allTraitsPresent: Part[] = [];

  canvas.getObjects("image").forEach((value) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    names.add(value.id);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    allTraitsPresent.push(value.data);
  });

  if (names.size >= maxFeatures && !names.has(feature.name)) {
    return {
      traits: Array.from(names),
      allTraitsPresent: allTraitsPresent,
      success: false,
    };
  } else {
    names.add(feature.name);
    allTraitsPresent.push(feature);
    return {
      traits: Array.from(names),
      allTraitsPresent: allTraitsPresent,
      success: true,
    };
  }
}

export function addFeatureToCanvas(
  widthScale: number,
  oImg: fabric.Image,
  feature: Trait,
  canvas: fabric.Canvas
) {
  if (widthScale) {
    const width = oImg.width;
    if (width) oImg.scaleToWidth(Math.ceil(width * widthScale));
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  oImg.set({ id: feature.name, data: feature });
  canvas.add(oImg);
}
