import MyModal from "../Modal";
import { getMaxUniqueFeatures } from "../../config/rmx-console-config";
import React from "react";
import "./TraitLimitReachModal.scss";

type TraitLimitReachModalProps = {
  showFeatureLimitWarning: boolean;
  setShowFeatureLimitWarning: (value: boolean) => void;
};

export const TraitLimitReachModal = ({
  showFeatureLimitWarning,
  setShowFeatureLimitWarning,
}: TraitLimitReachModalProps) => {
  return (
    <MyModal
      title={"〠 OOPS 〠"}
      show={showFeatureLimitWarning}
      onHide={() => {
        setShowFeatureLimitWarning(false);
      }}
      size={"lg"}
      primaryActionHandler={() => setShowFeatureLimitWarning(false)}
      primaryActionLabel={"okay"}
    >
      <div className={"text-center modal-text"}>
        <div className={""}>
          {`You can only use ${getMaxUniqueFeatures()} traits per remix.`}
        </div>
        <hr className={`cross-rule`} data-content={"†"} />
      </div>
    </MyModal>
  );
};
