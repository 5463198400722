import { useEffect, useState } from "react";

interface LiveCountdownClockProps {
  date: Date;
  currentDate?: Date;
}

export const LiveCountdownClock = ({
  date,
  currentDate,
}: LiveCountdownClockProps) => {
  const [tick, setTick] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setTick(tick + 1);
    }, 1000);
  });

  const now = currentDate || new Date();

  // TODO: change this date to correct date pre release
  const finishTime = date;
  let timeLeftSeconds = (Number(finishTime) - Number(now)) / 1000;
  if (timeLeftSeconds <= 0) {
    timeLeftSeconds = 0;
  }
  let remainder: number;
  // calculate days left
  const daysLeft = Math.floor(timeLeftSeconds / 86400);
  remainder = timeLeftSeconds - daysLeft * 86400;
  // calculate hours from remainder
  const hoursLeft = Math.floor(remainder / 3600);
  remainder = remainder - hoursLeft * 3600;
  // calculate minutes from remainder
  const minutesLeft = Math.floor(remainder / 60);
  remainder = remainder - minutesLeft * 60;
  // calculate seconds from remainder
  const secondsLeft = Math.floor(remainder);

  const daysLeftFormatted = `${daysLeft}D`.padStart(3, "0");
  const hoursLeftFormatted = `${hoursLeft}H`.padStart(3, "0");
  const minutesLeftFormatted = `${minutesLeft}M`.padStart(3, "0");
  const secondsLeftFormatted = `${secondsLeft}S`.padStart(3, "0");
  const timeLeftFormatted = [
    daysLeftFormatted,
    hoursLeftFormatted,
    minutesLeftFormatted,
    secondsLeftFormatted,
  ].join(":");
  return (
    <>
      {timeLeftSeconds <= 0 ? (
        <time
          className="countdown timer ended"
          dateTime={Number(finishTime).toString()}
        >
          {timeLeftFormatted}
        </time>
      ) : (
        <time
          className="countdown timer chromatic-ignore"
          dateTime={Number(finishTime).toString()}
        >
          {timeLeftFormatted}
        </time>
      )}
    </>
  );
};
