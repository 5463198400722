import { OwnedNft, TokenUri } from "alchemy-sdk";
import { useModal } from "connectkit";
import React, { useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { useEventListener } from "usehooks-ts";
import { useAccount } from "wagmi";
import { Face, Part } from "../../../../types";
import mintingComplete from "../../audio/minting_complete.mp3";
import { loadStaticFaces } from "../../config/api/load-static-assets";
import { getMetadataFromUrl, getWalletStatus } from "../../config/api/rmx-api";
import {
  CreateCursedEmojiStatus,
  FrontendConfig,
  OwnedGenesisMintNft,
  WalletStatus,
} from "../../config/api/types";
import { useRemix } from "../../hooks/useRemix";
import { FaceColor, GenesisMintUIMetadata } from "../../types";
import { CTACursedEmoji } from "../CursedEmojis/CTACursedEmoji";
import { useEmojiCanvasContext } from "../EmojiCanvas/EmojiCanvasContext";
import MyModal from "../Modal";
import "./RmxConsole.scss";
import { EmojiWorkspace } from "./EmojiWorkspace";
import { Footer } from "./Footer";
import Header from "./Header";
import { HowToContent } from "./HowToContent";
import NFTReveal from "./NFTReveal";
import { Overlay } from "./Overlay";
import { PartsDisplay } from "./PartsDisplay";
import { PartsNav } from "./PartsNav";
import { TraitLimitReachModal } from "./TraitLimitReachModal";
import { State, useRmxConsoleState } from "./useRmxConsoleState";
import { useRmxConsoleUIModel } from "./useRmxConsoleUIModel";
import { useErrorHandler } from "react-error-boundary";
import WaitingScreenLoading from "../WaitingScreens/WaitingScreenLoading";
import { CursedFooter } from "../LandingPage/CursedFooter";
import { CursedEmojiFooter } from "../CursedEmojis/Footer/CursedEmojiFooter";

//todo: move this too
function getNextRemixableToken(
  nfts: OwnedGenesisMintNft[]
): OwnedGenesisMintNft | null {
  // const remixLimit = 1; // NOTE: contract limit is updateable, this might not always be true
  const remixLimit = 1; // NOTE: contract limit is updateable, this might not always be true
  const remixable = nfts
    .filter((value) => {
      return value.remixCount < remixLimit;
    })
    .sort((a, b) => {
      if (a.tokenId < b.tokenId) {
        return -1;
      }
      if (a.tokenId > b.tokenId) {
        return 1;
      }
      return 0;
    });
  console.log("remixable tokens sorted: ", remixable);

  return remixable[0] || null;
}

//TODO: create a rmxconsolepage and move fetching of emojis and features up a level
// this will simplify state in here and ew just dont render the builder until we load everything

// TODO: move this
const buildClassNameForState = (state: State): string => {
  if (state === "waitingForMint" || state === "mintComplete") {
    return "rmx-console fade-disabled";
  }

  return "rmx-console";
};

interface RmxConsoleProps {
  frontendConfig: FrontendConfig;
}

const RmxConsole = ({ frontendConfig }: RmxConsoleProps) => {
  const handleError = useErrorHandler();
  // BEGIN NEW REMIX
  const [remixableToken, setRemixableToken] = useState<OwnedNft | null>(null);
  const [newMetadata, setNewMetadata] = useState<GenesisMintUIMetadata>();
  const {
    controller,
    ready: canvasReady,
    setSelectedElements,
  } = useEmojiCanvasContext();

  // TODO: replace with  a new remixed emoji status
  const [cursedEmojiCreationStatus, setcursedEmojiCreationStatus] = useState<
    CreateCursedEmojiStatus | undefined
  >();

  const getNewMetadata = async (metadataUri: string) => {
    getMetadataFromUrl(metadataUri)
      .then((metadata) => {
        console.log(`new metadata:`, metadata);
        setNewMetadata(metadata);
      })
      .catch((error) => {
        console.error(error);
        handleError(error);
      });
  };

  const selectedToken: number | null = remixableToken?.tokenId
    ? parseInt(remixableToken.tokenId)
    : null;
  useEffect(() => {
    console.log(`Selected token for console: `, selectedToken);
    if (remixableToken?.rawMetadata?.image) {
      controller?.setFace(remixableToken?.rawMetadata?.image);
    }
  }, [selectedToken]);

  const { status: remixStatus, remix } = useRemix({
    genesisMintContractAddress: frontendConfig.genesisMintContractAddress,
    tokenId: selectedToken,
    getEmojiAsFileBlob: controller?.getEmojiAsFileBlob,
    onSuccess: async (cecStatus: CreateCursedEmojiStatus) => {
      console.log("in onSuccess", cecStatus);
      setcursedEmojiCreationStatus(cecStatus);
      await getNewMetadata(cecStatus.metadataUri);
    },
    getFabricJSON: controller?.canvasJSON,
  });

  useEffect(() => {
    switch (remixStatus) {
      case "remixing_token": {
        send("MINT");
        break;
      }
      case "remixed": {
        send("MINT_COMPLETE");
        break;
      }
      case "waiting_on_wallet_confirmation": {
        send("CONFIRM_MINT");
        break;
      }
      case "start": {
        send("BASE_EMOJI_SELECTED");
        break;
      }
    }
  }, [remixStatus]);

  // END NEW REMIX

  const [showHowto, setShowHowto] = useState(false);

  const [showFeatureLimitWarning, setShowFeatureLimitWarning] = useState(false);
  const [showNoTokenWarning, setShowNoTokenWarning] = useState(false);
  const [showRemixCompletedWarning, setShowRemixCompletedWarning] =
    useState(false);

  const [canvasDirty, setCanvasDirty] = useState<boolean>(false);
  const [walletStatus, setWalletStatus] = useState<WalletStatus | null>(null);

  const [curseActivatedDisplay, setCurseActivatedDisplay] =
    useState<boolean>(true);

  const [state, send] = useRmxConsoleState();
  const [loadingData, setLoadingData] = useState(false);

  const {
    ready: partsReady,
    face,
    setFace,
    category,
    setCategory,
    parts,
    selectedPartId,
  } = useRmxConsoleUIModel(frontendConfig.traitCategoryMap);

  const { address, isConnected, isConnecting } = useAccount();
  const { open, setOpen } = useModal();

  const [uniqueUsedTraits, setUniqueUsedTraits] = useState<string[]>([]);
  const [allUsedTraits, setAllUsedTraits] = useState<Part[]>([]);
  const [rawFabricJSON, setRawFabricJSON] = useState<unknown>({});

  // derived states
  const showWalletWarning = !isConnected;
  const showSignupWarning =
    !walletStatus?.isSignedUp && !showWalletWarning && !loadingData;

  // end derived states

  function clearCanvasSelection() {
    if (canvasReady) {
      const traitsLeft = controller.clearSelection();
      setAllUsedTraits(traitsLeft);
      const allTraits: string[] = traitsLeft.map((trait) => {
        return trait.name;
      });
      const setTraits = new Set(allTraits);
      setUniqueUsedTraits(Array.from<string>(setTraits));
    }
    setRawFabricJSON(controller.canvasJSON());
    setSelectedElements([]);
  }

  function keyboardHandler(evt: KeyboardEvent) {
    if (["Backspace", "Delete"].includes(String(evt.key))) {
      clearCanvasSelection();
      controller.canvas.discardActiveObject();
      controller.canvas.requestRenderAll();
    }
    if (["Escape", "Esc"].includes(String(evt.key))) {
      controller.canvas.discardActiveObject();
      controller.canvas.requestRenderAll();
    }
    // if (["o", "p"].includes(String(evt.key))) {
    //   controller.canvas.perPixelTargetFind = true;
    //   const obj = controller.canvas.getActiveObject()

    //   if (evt.key === 'o' && obj.opacity < 1) {
    //     obj.set('opacity', 1);
    //   } else if (evt.key === 'p' && obj.opacity >= 1) {
    //     obj.set('opacity', 0.5);
    //   }

    //   controller.canvas.requestRenderAll();
    // }
  }

  useEventListener("keydown", keyboardHandler);

  useEffect(() => {
    if (partsReady) {
      send("FINISHED_LOADING");
    }
  }, [partsReady, send]);

  useEffect(() => {
    if (canvasReady && face && state === "chooseBaseEmoji") {
      // if (controller && remixableToken?.rawMetadata?.image) {
      //   controller?.setFace(remixableToken.rawMetadata.image);
      // }
      // else {
      //   controller?.setFace(face.imgUrl);
      // }
      console.log(
        `canvasready base emoji selected`,
        controller,
        remixableToken?.rawMetadata,
        face,
        state
      );
      send("BASE_EMOJI_SELECTED");

      // if json in localstorage
      // also retrieve that state
      // and add it all to canvas

      let storedCanvasJson = null;
      storedCanvasJson = window?.localStorage?.getItem("stored_canvas");
      if (storedCanvasJson) {
        console.log(storedCanvasJson);
        controller.canvas.loadFromJSON(storedCanvasJson, (a) => {
          console.log(`storedjsoncallback`, a);
        });
        // controller.canvas.setBackgroundImage()
      }
    }
  }, [canvasReady, state]);

  useEffect(() => {
    if (remixableToken?.rawMetadata?.image) {
      controller?.setFace(remixableToken.rawMetadata.image);
    }
    // else if (face) {
    //   controller?.setFace(face.imgUrl);
    // }
    console.log(
      `face type switched: face, token, controller:`,
      face,
      remixableToken,
      controller
    );
  }, [face]);

  useEffect(() => {
    const determineFaceTypeOfNftBaseOnTokenInfo = (
      token: OwnedGenesisMintNft | null,
      facesOptions: Face[]
    ) => {
      let baseFace: Face;

      // Default to yellow for any failure states or special cases
      baseFace = facesOptions.find((option) => {
        return option.name === FaceColor.YELLOW;
      });

      if (token?.rawMetadata?.attributes) {
        const baseType = token?.rawMetadata?.attributes.find(
          () => "Cursed Family"
        );
        if (baseType?.value.toString().toLowerCase() === "old yeller") {
          baseFace = facesOptions.find((option) => {
            return option.name === FaceColor.YELLOW;
          });
        }
        if (baseType?.value.toString().toLowerCase() === "true blue") {
          baseFace = facesOptions.find((option) => {
            return option.name === FaceColor.BLUE;
          });
        }
        if (baseType?.value.toString().toLowerCase() === "big red") {
          baseFace = facesOptions.find((option) => {
            return option.name === FaceColor.RED;
          });
        }
        if (baseType?.value.toString().toLowerCase() === "yukon gold") {
          baseFace = facesOptions.find((option) => {
            return option.name === FaceColor.POTATO;
          });
        }
        baseFace.tokenId = token.tokenId;
      }
      return baseFace;
    };

    const fetch = async (walletId: string) => {
      setLoadingData(true);
      getWalletStatus(walletId)
        .then((result) => {
          setLoadingData(false);
          setWalletStatus(result);
          if (result) {
            console.log("token details", result.genesisMintTokens);
            //todo: set the next eligible tokenId

            let token: OwnedGenesisMintNft;
            token = getNextRemixableToken(result.genesisMintTokens);

            try {
              const saved_token =
                window?.localStorage?.getItem("remix_in_progress");
              if (saved_token) {
                token = JSON.parse(saved_token);
              }
            } catch (e) {
              console.error(e);
            }

            const facesOptions = loadStaticFaces();
            const baseFace = determineFaceTypeOfNftBaseOnTokenInfo(
              token,
              facesOptions
            );
            if (baseFace) {
              setFace(baseFace);
            }

            if (token) {
              controller?.setFace(token?.rawMetadata?.image);
              setRemixableToken(token);
              console.log(
                `showing token selected context`,
                result,
                baseFace,
                token?.rawMetadata?.image,
                controller
              );
              setShowHowto(true);
            }
            if (result.genesisMintCount > 0 && token === null) {
              console.log(
                `showing remix completed context`,
                result,
                baseFace,
                token
              );
              setShowRemixCompletedWarning(true);
            }
            if (result.genesisMintCount === 0) {
              console.log(`showing no token context`, result, baseFace, token);
              setShowNoTokenWarning(true);
            }
          }
        })
        .catch((error) => {
          setLoadingData(false);
          handleError(error);
        });
    };
    if (address != null) {
      fetch(address)
        .catch((error) => {
          console.log(`Error fetching mintCount for address ${address}`, error);
        })
        .then(() => {});
    }
  }, [address]);

  useEffect(() => {
    if (state === "mintComplete" && curseActivatedDisplay) {
      console.log("curse activated!");
      setTimeout(() => {
        setCurseActivatedDisplay(false);
      }, 6000);
    }
  }, [state]);

  //todo: on select part, set part selected to T
  const trySelectPart = (part: Part) => {
    const addResult = controller?.addFeature(part);
    if (addResult.success) {
      // we put something on the canvas, so mark it dirty.
      setUniqueUsedTraits(Array.from<string>(addResult.traits));
      setAllUsedTraits(addResult.allTraitsPresent);
      setRawFabricJSON(controller.canvasJSON());
      setCanvasDirty(true);
    } else {
      setShowFeatureLimitWarning(true);
    }
  };

  const toggleShowHowto = () => {
    setShowHowto((prevState) => !prevState);
  };

  const toggleShowMintDialog = async () => {
    if (uniqueUsedTraits.length > 0) {
      await purchaseAndMint();
    }
  };

  const purchaseAndMint = async () => {
    if (!address) {
      //todo: display error?
      console.error("walletId not found; Not minting");
      return;
    }

    console.log("remix status", remixStatus);
    remix?.();
  };

  const disabledRemixCTA = () => {
    return !isConnected || uniqueUsedTraits.length === 0;
  };

  const determineCTA = () => {
    if (!isConnected || isConnecting) {
      return (
        <CTACursedEmoji
          action={() => setOpen(true)} // TODO: replace with family button hook?
          title={"CONNECT WALLET"}
          disabled={isConnecting || open}
          variant={"short"}
        />
      );
    }
    if (showRemixCompletedWarning || showNoTokenWarning) {
      return (
        <CTACursedEmoji
          action={frontendConfig.genesisCollectionUrl}
          title={"VIEW COLLECTION"}
          disabled={false}
          variant={"short"}
        />
      );
    }
    if (isConnected) {
      return (
        <CTACursedEmoji
          action={toggleShowMintDialog}
          title={"REMIX"}
          disabled={disabledRemixCTA()}
          variant={"short"}
        />
      );
    }
  };

  const showOverlay = (): boolean => {
    return (
      showWalletWarning ||
      loadingData ||
      showRemixCompletedWarning ||
      showNoTokenWarning
    );
  };

  return (
    <>
      {state !== "waitingForMint" && state !== "mintComplete" && (
        <>
          <section className={buildClassNameForState(state)}>
            <section className={"min-vh-100 px-0 d-flex flex-column"}>
              <div
                className={"min-vh-100 position-relative d-flex flex-column "}
              >
                <Header
                  url="/cursedEmojis"
                  setShowHowto={setShowHowto}
                  isConnected={isConnected}
                />
                <EmojiWorkspace
                  frontendConfig={frontendConfig}
                  showWalletWarning={showWalletWarning}
                  showNoTokenWarning={showNoTokenWarning}
                  showSignupWarning={showSignupWarning}
                  premintSignupUrl={walletStatus?.signupUrl || ""}
                  onClearCanvasSelection={clearCanvasSelection}
                  usedTraits={uniqueUsedTraits}
                  showRemixCompleteWarning={showRemixCompletedWarning}
                  showLoadingModal={loadingData}
                  face={face}
                  allUsedTraits={allUsedTraits}
                />
                <section className={"d-flex justify-content-center w-100"}>
                  <div
                    className={`keyboard ${showOverlay() ? "grayScale" : ""}`}
                  >
                    {showOverlay() && <Overlay />}
                    <PartsNav
                      setCategory={setCategory}
                      category={category}
                      traitCategoryMap={frontendConfig.traitCategoryMap}
                      grayScaleActive={showOverlay()}
                    />
                    <PartsDisplay
                      onSelected={trySelectPart}
                      parts={parts}
                      face={face}
                      usedTraits={uniqueUsedTraits}
                      grayScaleActive={showOverlay()}
                    />
                  </div>
                </section>
                <Footer />
              </div>
            </section>
          </section>
          <MyModal
            title={"·⎈··how to remix··⎈·"}
            show={showHowto}
            onHide={toggleShowHowto}
            size={"lg"}
          >
            <HowToContent />
          </MyModal>
          <TraitLimitReachModal
            showFeatureLimitWarning={showFeatureLimitWarning}
            setShowFeatureLimitWarning={setShowFeatureLimitWarning}
          />
          {determineCTA()}
        </>
      )}
      {state === "waitingWalletConfirmation" && (
        <WaitingScreenLoading
          index={1}
          text={"Confirm on your wallet to continue."}
        />
      )}
      {state === "waitingForMint" && (
        <div
          className={"bg-transparent border-0 minting-status-msg text-center"}
        >
          <div className={"minting-background"}>
            <div className={"minting-flame-frame"}>
              {/* <img className={"minting-flames"} src={flames}></img> */}
              <img
                className={"minting-flames"}
                src={
                  "https://cursedemojis.world/assets/images/flames-slower.gif"
                }
              />
              <img
                className={"minting-hourglass"}
                src={
                  "https://cursedemojis.world/assets/images/ANIM_HOURGLASS_MUST_USE.gif"
                }
              />
              <ReactAudioPlayer
                src="https://cursedemojis.world/assets/mint-audio.mp3"
                autoPlay={true}
                loop={true}
              />
            </div>
          </div>
        </div>
      )}
      {state === "mintComplete" && curseActivatedDisplay && (
        <div
          className={
            "bg-transparent border-0 minting-status-msg text-center w-100 h-100 align-middle"
          }
        >
          <ReactAudioPlayer
            src={mintingComplete}
            autoPlay={true}
            loop={false}
          />
          <div
            className={
              "d-flex flex-column justify-content-center align-items-center minting-hundred-percent-container"
            }
          >
            <div className={"minting-hundred-percent"}>
              REMIX COMPLETE{" "}
              <span className={"minting-hundred-percent-span"}>100%</span>{" "}
            </div>
            <div className={"minting-curse-activated"}>
              2nd Incarnation Activated
            </div>
          </div>
          <div className={"minting-background"}>
            {/* <div className={"minting-hundred-percent-flames"}> */}
            {/*   <img src={flames}></img> */}
            {/* </div> */}
            <div className={"minting-flame-frame"}>
              <img
                className={"minting-flames complete"}
                src={
                  "https://cursedemojis.world/assets/images/flames-slower.gif"
                }
              />
            </div>
          </div>
        </div>
      )}
      {state === "mintComplete" &&
        !curseActivatedDisplay &&
        !!cursedEmojiCreationStatus && (
          <>
            <NFTReveal
              cursedEmojiCreationStatus={cursedEmojiCreationStatus}
              token={remixableToken}
              frontEndConfig={frontendConfig}
              newMetadata={newMetadata}
              usedTraits={uniqueUsedTraits}
            />
          </>
        )}
    </>
  );
};
export default RmxConsole;
