import React from "react";
import grey from "../../images/GrayButton.svg";
import green from "../../images/GreenButton.svg";
import yellow from "../../images/YellowButton.svg";
import red from "../../images/RedButton.svg";
import { LightColors } from "../../types";

const imageForColor = {
  grey: grey,
  green: green,
  yellow: yellow,
  red: red,
};

interface ColorLightProps {
  color: LightColors;
}

export const ColorLight = ({ color }: ColorLightProps) => {
  return (
    <img
      src={imageForColor[color]}
      className={`content ${color}-button`}
      alt={`${color} light`}
    />
  );
};
