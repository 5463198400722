import { ReactNode } from "react";
import "./RmxButton.scss";

export type RmxButtonProps = {
  title: string;
  disabled?: boolean;
  action: string | (() => void);
  className?: string;
  classes?: string[];
  img?: string;
  icon?: ReactNode;
};

export const RmxButton = (props: RmxButtonProps) => {
  let content: ReactNode = <>{props.title}</>;
  if (props.img) {
    content = <img src={props.img} alt={props.title} />;
  }
  if (props.icon) {
    content = (
      <>
        {props.icon}
        <span>{content}</span>
      </>
    );
  }

  const disableClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  if (typeof props.action === "string") {
    const hyperlink = () => {
      return (
        <a
          className={`rmx-button ${props.className} ${
            props.disabled ? "disabled" : ""
          }`}
          href={`${props.action}`}
          target={props.action ? "_blank" : undefined}
          title={props.title}
          onClick={props.disabled ? disableClick : undefined}
          data-testid={props.title}
        >
          {content}
        </a>
      );
    };

    return hyperlink();
  }

  const button = () => {
    if (typeof props.action === "function") {
      return (
        <button
          disabled={props.disabled}
          className={`rmx-button ${props.className}`}
          onClick={props.disabled ? disableClick : props.action}
          title={props.title}
          data-testid={props.title}
        >
          {content}
        </button>
      );
    }
  };

  return button();
};
