import { ColorLight } from "../ColorLight";
import { TableBox } from "../TableBox";
import { MintCountDown } from "../MintCountDown";
import React from "react";
import { MintCountInfo } from "../../../config/api/rmx-api";
import { NoMintLeft } from "../MintPageCursed/NoMintLeft";
import { FrontendConfig } from "../../../config/api/types";
import { LiveCountdownClock } from "../LiveCountdownClock";
import { REMIX_OPEN_DATE } from "../../../config/dates";

interface MintPageOpenNotConnectedProps {
  mintCountInfo: MintCountInfo;
  frontEndConfig: FrontendConfig;
}

export const MintPageOpenNotConnected = ({
  mintCountInfo,
  frontEndConfig,
}: MintPageOpenNotConnectedProps) => {
  const noMintLeft = (): boolean => {
    return mintCountInfo.totalMinted >= mintCountInfo.maximumMints;
  };
  console.log("noMintLeft", noMintLeft());
  console.log("mintCountInfo.maximumMints", mintCountInfo.maximumMints);
  console.log("mintCountInfo.totalMinted", mintCountInfo.totalMinted);
  return (
    <>
      {noMintLeft() && <NoMintLeft frontEndConfig={frontEndConfig} />}
      {!noMintLeft() && (
        <div className={"mint-page disconnected"}>
          <div className={"mint-content-card"}>
            <header className="d-flex flex-row justify-content-between header">
              <ColorLight color={"grey"} />
              <div
                className={
                  "text-center content w-100 d-flex align-items-center justify-content-center"
                }
              >
                C0NNECT YER WALLET
              </div>
              <ColorLight color={"grey"} />
            </header>
            <div className={"content copy"}>
              <p>
                Cursed Emojis is an experiment in co-creation, starting as a
                dialogue between artist and collector, through the medium of
                NFTs. The project is a collaboration between between artist{" "}
                <a
                  href={
                    "https://mirror.xyz/rmxparty.eth/KoLrhfPsd80LGg2O9nKSy6W-LXcht3XHpNu4KflUz_M"
                  }
                  target={"_blank"}
                >
                  yung jake
                </a>{" "}
                and{" "}
                <a href="https://www.rmx.party/" target="_blank">
                  RMX
                </a>
                , a startup dedicated to democratizing on-chain creation.
              </p>
              <p>
                There are 512 free mint passes distributed to selected creators,
                collectors, and communities.
              </p>
              <p>
                Each RMX Pass: Emoji Base grants a person the opportunity to
                create their own Cursed Emoji NFT using trait-based NFTs created
                by yung jake. Each Cursed Emoji NFT will be immortalized into a
                permanent on-chain CC0 collection.
              </p>
              <p>
                Please connect your wallet to get your RMX Pass: Emoji Base.
              </p>
            </div>
            <footer className={"text-center content"}>
              For more info, see our{" "}
              <a className={"caption"} href={"/about"} target={"_blank"}>
                FAQs
              </a>
            </footer>
          </div>
        </div>
      )}
      <section style={{ maxWidth: "512px" }}>
        <TableBox
          keys={["Access:", "Price:", "Limit:", "Remix In:", "Mints Left:"]}
          values={[
            <>
              {" "}
              Only Open to{" "}
              <a
                href="https://www.premint.xyz/site-cursed-emojis-co-create-art/"
                target="_blank"
              >
                Allowlist
              </a>
            </>,
            "Free to Mint",
            "1 Mint per Wallet",
            <LiveCountdownClock date={REMIX_OPEN_DATE} />,
            <MintCountDown mintCountInfo={mintCountInfo} />,
          ]}
          leftWidth={"medium"}
        />
      </section>
    </>
  );
};
