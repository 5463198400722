import React from "react";
import flagEmoji from "../../images/ce-redflag-desktop2x.png";
import { RmxButton } from "../RmxButton";
import "./ErrorComponent.scss";

interface ErrorComponentProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export const ErrorComponent = ({
  error,
  resetErrorBoundary,
}: ErrorComponentProps) => {
  console.log("Error catch component", error.message);
  return (
    <section
      className={"error-container d-flex flex-column justify-content-center "}
    >
      <img className={"error-image"} src={flagEmoji} />
      <p className="text-1">Something went wrong.</p>
      <p className="text-2">Please reload the page and try again.</p>
      <RmxButton
        title={"START OVER"}
        action={resetErrorBoundary}
        className={"secondary cta"}
      />
    </section>
  );
};
