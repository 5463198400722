import { Ratio } from "react-bootstrap";
import React from "react";
import "./HowToContent.scss";

export function HowToContent() {
  return (
    <div className={"howto-content"}>
      <div className={"w-100 howto-video text-center"}>
        <Ratio aspectRatio="16x9">
          <video controls>
            <source
              src={"https://cursedemojis.world/assets/media/desktop_how_to.mp4"}
              type={"video/mp4"}
            />
          </video>
        </Ratio>
      </div>
      <div className={"w-100 howto-description"}>
        <p>
          <strong>Start:</strong> You can create one Cursed Emoji per emoji
          base. You have a maximum of 10 on-chain traits which you may use ∞
          times!
        </p>
        <p>
          <strong>Remix:</strong> When complete, add your unique Cursed Emoji to
          the blockchain.{" "}
          <a
            href={
              "https://rmxparty.notion.site/C-rsed-C-ew-d89bf86b61d64baaa59d6c17f538f6b1"
            }
            target={"_blank"}
          >
            See inspo!
          </a>
        </p>
        <p>
          <strong>If you own multiple emoji bases:</strong> The RMX Console
          chooses the lowest token ID number first. Return to the console for
          the next base once your first remix is complete. If you want to remix
          in a different order, we recommend transferring a single token to a
          different wallet for remixing. For more info,{" "}
          <a href={"/about"} target={"_blank"}>
            see our FAQs.
          </a>
        </p>
      </div>
    </div>
  );
}
