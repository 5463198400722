import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { getMetadataFromUrl, MintCountInfo } from "../../config/api/rmx-api";
import {
  CreateCursedEmojiStatus,
  FrontendConfig,
} from "../../config/api/types";
import { GenesisMintUIMetadata } from "../../types";
import { AboutContent } from "./AboutContent";
import { AuctionContent } from "./AuctionContent";
import "./CursedEmojis.scss";
import { FinishedMintedEmoji } from "./FinishedMintedEmoji";
import { GenesisMintContent } from "./GenesisMintContent";
import { CTACursedEmoji } from "./CTACursedEmoji";
import { useGenesisMint } from "../../hooks/useGenesisMint";
import { RemixContent } from "./RemixContent";
import { CursedEmojiTabHeader } from "./CursedEmojiTabHeader/CursedEmojiTabHeader";
import { nftUrlOpenSea, parseNftId } from "../utils/urlOpenSeaParser";
import { CursedEmojiLayoutContent } from "./CursedEmojiLayoutContent";
import CursedEmojisNav from "./CursedEmojisNav/CursedEmojisNav";
import { CursedEmojiFooter } from "./Footer/CursedEmojiFooter";
import { useErrorHandler } from "react-error-boundary";
import { MintPageJustMinted } from "./MintPageJustMinted";

export interface CursedEmojisRenderProps {
  isConnecting: boolean;
  isConnected: boolean;
  isUserAllowed: boolean | null;
  hasUserAlreadyMinted: boolean;
  mintCountInfo: MintCountInfo;
  openConnectModal: (() => void) | undefined;
  address: `0x${string}` | undefined;
  frontendConfig: FrontendConfig;
}

export const CursedEmojisRender = ({
  isConnecting,
  isConnected,
  isUserAllowed,
  hasUserAlreadyMinted,
  mintCountInfo,
  openConnectModal,
  address,
  frontendConfig,
}: CursedEmojisRenderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleError = useErrorHandler();

  const [creationStatus, setCreationStatus] = useState<
    CreateCursedEmojiStatus | undefined
  >();
  const [mintedNftMetadata, setMintedNftMetadata] =
    useState<GenesisMintUIMetadata>();

  const { initFn, mintFn, readyToMint, genesisMintDetails, status } =
    useGenesisMint();

  const time = new Date();
  const indexLoadingScreen = Math.floor(time.getMinutes() / 20);

  useEffect(() => {
    if (creationStatus) {
      if (location.pathname == "/minted") return;
      navigate("/minted");
    }
  }, [creationStatus]);

  useEffect(() => {
    if (genesisMintDetails) {
      getMetadataFromUrl(genesisMintDetails.metadataUri)
        .then((mintMetadata: any) => {
          setCreationStatus({
            ...genesisMintDetails,
          });
          setMintedNftMetadata(mintMetadata);
        })
        .catch((error) => {
          handleError(error);
        });
    }
  }, [genesisMintDetails]);

  useEffect(() => {
    if (readyToMint && mintFn) {
      console.log("calling mintFn");
      mintFn();
    }
  }, [readyToMint]);

  const handleMintClick = async () => {
    if (!address) {
      console.error(
        "mint click handler fired without an address",
        address,
        initFn
      );
      return;
    }
    await initFn?.(address as `0x${string}`);
  };

  const mintReceiptPage = location.pathname.match(/minted/i);

  const remainingMintAvailable = () => {
    return mintCountInfo.totalMinted < mintCountInfo.maximumMints;
  };

  const defaultPage = () => {
    const { mintPhase, remixPhase } = frontendConfig;
    // if (mintPhase === "pre-mint") return "about";
    // if (mintPhase === "open-mint" && remixPhase === "pre-remix") return "mint";
    if (mintPhase === "post-mint" && remixPhase === "pre-remix") return "remix";
    if (mintPhase === "post-mint" && remixPhase === "open-remix")
      return "remix";
    if (mintPhase === "post-mint" && remixPhase === "post-remix")
      return "auction";
    return "remix";
  };

  const renderCTA = () => {
    const {
      mintPhase,
      remixPhase,
      genesisCollectionUrl,
      genesisMintContractAddress,
    } = frontendConfig;

    if (mintPhase == "pre-mint") return; // TODO: remove after pre-mint ends?
    if (isConnecting) return;
    if (status !== "start" && status !== "minted") return;
    if (window.location.pathname === "/minted") return;

    const mintButtonText = hasUserAlreadyMinted
      ? "VIEW COLLECTION"
      : "Mint Now";

    const disableMintButton = status != "start" || hasUserAlreadyMinted;

    const joinTheAllowlistCTA = (
      <CTACursedEmoji
        action={frontendConfig.premintUrl}
        title={"JOIN THE ALLOWLIST"}
        disabled={false}
        variant={"tall"}
      />
    );

    const connectWalletCTA = (
      <CTACursedEmoji
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action={openConnectModal}
        title={"Connect Wallet"}
        disabled={false}
        variant={"tall"}
      />
    );

    const viewCollectionCTA = (
      <CTACursedEmoji
        action={genesisCollectionUrl}
        title={"VIEW COLLECTION"}
        disabled={false}
        variant={"tall"}
      />
    );

    const mintNowCTA = (
      <CTACursedEmoji
        action={handleMintClick}
        title={mintButtonText}
        disabled={false}
        variant={"tall"}
      />
    );

    const viewMyTokenCTAAlreadyMinted = (
      <CTACursedEmoji
        action={frontendConfig.genesisCollectionUrl}
        title={"VIEW COLLECTION"}
        disabled={false}
        variant={"tall"}
      />
    );

    const remixNowCTA = (
      <CTACursedEmoji
        action={"/RMXConsole"}
        title={"Remix Now"}
        disabled={false}
        variant={"tall"}
      />
    );

    const viewAuctionCTA = (
      <CTACursedEmoji
        action={genesisCollectionUrl}
        title={"View Auction"}
        disabled={false}
        variant={"tall"}
      />
    );

    const viewMyTokenCTA = () => {
      if (mintedNftMetadata && mintedNftMetadata.image) {
        return (
          <CTACursedEmoji
            action={nftUrlOpenSea(
              genesisCollectionUrl,
              genesisMintContractAddress,
              parseNftId(mintedNftMetadata.image)
            )}
            title={"VIEW YOUR PASS"}
            disabled={false}
            variant={"tall"}
          />
        );
      }
    };

    if (mintPhase === "post-mint" && remixPhase === "pre-remix")
      return viewCollectionCTA;
    if (mintPhase === "post-mint" && remixPhase === "open-remix")
      return remixNowCTA;
    if (mintPhase === "post-mint" && remixPhase === "post-remix")
      return viewAuctionCTA;

    if (!remainingMintAvailable()) return viewCollectionCTA;
    if (!isConnected && openConnectModal) return connectWalletCTA;
    if (!isUserAllowed) return joinTheAllowlistCTA;
    if (status === "minted") return viewMyTokenCTA();

    // if (mintPhase === "pre-mint") return joinTheAllowlistCTA;
    if (mintPhase === "open-mint" && !hasUserAlreadyMinted) return mintNowCTA;
    if (mintPhase === "open-mint" && hasUserAlreadyMinted)
      return viewMyTokenCTAAlreadyMinted;
  };

  return (
    <div
      className={`${
        mintReceiptPage ? "mint-receipt" : ""
      } d-flex flex-column justify-content-between`}
      style={{ width: "100%" }}
    >
      <div className={`cursed-emojis-page px-3`}>
        <CursedEmojiTabHeader />
        <Routes>
          <Route
            path="about"
            element={
              <CursedEmojiLayoutContent
                status={status}
                isConnecting={isConnecting}
                isConnected={isConnected}
                isUserAllowed={isUserAllowed}
                hasUserAlreadyMinted={hasUserAlreadyMinted}
                contentForStatusStarted={
                  <AboutContent frontEndConfig={frontendConfig} />
                }
                contentForStatusMinted={
                  <AboutContent frontEndConfig={frontendConfig} />
                }
                indexLoadingScreen={indexLoadingScreen}
              />
            }
          />

          <Route
            path="auction"
            element={
              <CursedEmojiLayoutContent
                status={status}
                isConnecting={isConnecting}
                isConnected={isConnected}
                isUserAllowed={isUserAllowed}
                hasUserAlreadyMinted={hasUserAlreadyMinted}
                contentForStatusStarted={
                  <AuctionContent auctionPhase={frontendConfig.auctionPhase} />
                }
                contentForStatusMinted={
                  <AuctionContent auctionPhase={frontendConfig.auctionPhase} />
                }
                indexLoadingScreen={indexLoadingScreen}
              />
            }
          />

          <Route
            path="remix"
            element={
              <CursedEmojiLayoutContent
                status={status}
                isConnecting={isConnecting}
                isConnected={isConnected}
                isUserAllowed={isUserAllowed}
                hasUserAlreadyMinted={hasUserAlreadyMinted}
                contentForStatusStarted={
                  <RemixContent remixPhase={frontendConfig.remixPhase} />
                }
                contentForStatusMinted={
                  <RemixContent remixPhase={frontendConfig.remixPhase} />
                }
                indexLoadingScreen={indexLoadingScreen}
              />
            }
          />

          <Route
            path="mint"
            element={
              <CursedEmojiLayoutContent
                status={status}
                isConnecting={isConnecting}
                isConnected={isConnected}
                isUserAllowed={isUserAllowed}
                hasUserAlreadyMinted={hasUserAlreadyMinted}
                contentForStatusStarted={
                  <GenesisMintContent
                    isConnected={isConnected}
                    mintCountInfo={mintCountInfo}
                    isUserAllowed={isUserAllowed}
                    frontEndConfig={frontendConfig}
                    hasUserAlreadyMinted={hasUserAlreadyMinted}
                  />
                }
                contentForStatusMinted={
                  <div className={"mint-content"}>
                    <div className={"genesis-subnav text-center"}>
                      <CursedEmojisNav />
                    </div>
                    <MintPageJustMinted mintCountInfo={mintCountInfo} />
                  </div>
                }
                indexLoadingScreen={indexLoadingScreen}
              />
            }
          />

          {creationStatus && (
            <Route
              path="minted"
              element={
                <FinishedMintedEmoji
                  creationStatus={creationStatus}
                  mintedNftMetadata={mintedNftMetadata}
                  frontEndConfig={frontendConfig}
                />
              }
            />
          )}

          <Route path="*" element={<Navigate to={defaultPage()} replace />} />
        </Routes>
        <CursedEmojiFooter />
      </div>
      {/* {renderCTA()} */}
    </div>
  );
};
