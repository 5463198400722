import React from "react";
import "./CursedEmojiFooter.scss";
import { RmxLogo } from "./RmxLogo";

const cdnUrl = "https://cursedEmojis.world/assets/images";
const year = new Date().getFullYear();

const links = [
  {
    imageUrl: `${cdnUrl}/banner-twitter.png`,
    href: "https://twitter.com/RMX_PARTY",
    title: "Twitter",
  },
  {
    imageUrl: `${cdnUrl}/banner-opensea.png`,
    href: "https://opensea.io/rmxparty",
    title: "OpenSea",
  },
  {
    imageUrl: `${cdnUrl}/banner-geneva.png`,
    href: "https://app.geneva.com/invite/6626ed36-79db-41d3-94e5-68a2163345e6",
    title: "Geneva",
  },
  {
    imageUrl: `${cdnUrl}/banner-mirror.png`,
    href: "https://mirror.xyz/rmxparty.eth",
    title: "Mirror",
  },
  {
    imageUrl: `${cdnUrl}/banner-ug.png`,
    href: "https://www.instagram.com/rmxparty/",
    title: "Instagram",
  },
  {
    imageUrl: `${cdnUrl}/banner-web3.png`,
    href: "https://app.ens.domains/name/rmxparty.eth/details",
    title: "Web3",
  },
  {
    imageUrl: `${cdnUrl}/banner-about.png`,
    href: "https://www.rmx.party/",
    title: "About",
  },
  {
    imageUrl: `${cdnUrl}/banner-join.png`,
    href: "mailto:careers@rmx.party?subject=JoinUs",
    title: "Join",
  },
];

const listLinks = links.map((link) => {
  const { imageUrl, href, title } = link;

  return (
    <a
      key={title}
      className={"icons-footer "}
      href={href}
      target="_blank"
      title={title}
      aria-label={title}
    >
      <img alt={title} src={imageUrl} className={"image-icon"} />
    </a>
  );
});

export const CursedEmojiFooter = () => {
  return (
    <footer className="cursed-emojis-footer">
      <div className="bd-highlight mx-auto" style={{ maxWidth: "562px" }}>
        <hr id="footer-hr" />
        <nav className={"d-flex justify-content-center flex-wrap"}>
          {listLinks}
        </nav>
      </div>

      <div
        className={
          "d-flex flex-column align-items-center justify-content-center logo-section"
        }
      >
        <span>{RmxLogo()}</span>
        <span className={"logo-text mx-auto"}>
          Tools for on-chain creativity
        </span>
        <span className="copyright mx-auto">RMX (c){year}</span>
      </div>
    </footer>
  );
};
