import React from "react";
import "../CursedEmojis/MintContent.scss";
import "./WhiteButtonWithBorder.scss";

interface WhiteButtonWithBorderProps {
  children: React.ReactElement;
}

export const WhiteButtonWithBorder = ({
  children,
}: WhiteButtonWithBorderProps) => {
  return (
    <div className={"schedule mint-content text-center w-100 pb-0"}>
      <div className={"overwrite mint-content-card"}>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};
