import React, { useEffect, useRef } from "react";
import "./emojicanvas.scss";
import { CANVAS_ID } from "./EmojiCanvasController";
import { useEmojiCanvasContext } from "./EmojiCanvasContext";

export const EmojiCanvas = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { setCanvasContainerDivElement } = useEmojiCanvasContext();

  useEffect(() => {
    if (ref?.current != null) {
      setCanvasContainerDivElement(ref.current);
    }
  }, [ref, setCanvasContainerDivElement]);

  return (
    <div className={"emoji-canvas"} ref={ref} data-testid={"emoji-canvas"}>
      <canvas id={CANVAS_ID} />
    </div>
  );
};

export default EmojiCanvas;
