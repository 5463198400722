import React from "react";
import "../CursedEmojis/CursedEmojis.scss";
import "./RefreshOpenSeaExplanation.scss";

export const RefreshOpenSeaExplanation = () => {
  return (
    <div className={"mint-content w-100 info-container"}>
      <div className={"mint-content-card"}>
        <header className={"text-center content"}>
          ⱤEFRESHING YOUR METADATA
        </header>
        <div className={"content copy"}>
          <p className={"text-content pb-1"}>
            This Cursed Emoji is a remixed NFT, becoming its second incarnation.
            To view your Cursed Emoji, go to your NFT on OpenSea, select the
            three dots next to the share icon. Select “Refresh metadata” and the
            second incarnation of your Cursed Emoji is born!{" "}
            <a
              href={
                "https://rmxparty.notion.site/H-w-t-See-Y-r-Rem-x-040bf070918a44bf97415c6b36308983"
              }
              target={"_blank"}
            >
              See an example here.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
