import { MintCountInfo } from "../../config/api/rmx-api";
import "./Countdown.scss";
import React from "react";

export const MintCountDown = ({
  mintCountInfo,
}: {
  mintCountInfo: MintCountInfo;
}) => {
  const { totalMinted: total, maximumMints: max } = mintCountInfo;
  let totalLeft = Math.abs(max) - Math.abs(total);
  if (totalLeft <= 0) {
    totalLeft = 0;
  }
  return (
    <>
      {totalLeft <= 0 ? (
        <span className="countdown timer ended">
          {totalLeft} / {max}
        </span>
      ) : (
        <span className="countdown timer chromatic-ignore">
          {totalLeft} / {max}
        </span>
      )}
    </>
  );
};
