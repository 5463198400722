import React from "react";
import "./BigBoxWithTitleLightTextAndBigImage.scss";
import { ColorLight } from "./ColorLight";
import { LightColors } from "../../types";

interface BigBoxWithTitleLightTextAndBigImageProps {
  title: string;
  color: LightColors;
  text: string | React.ReactElement;
  children: React.ReactElement;
  text2?: string;
}

export const BigBoxWithTitleLightTextAndBigImage = ({
  title,
  color,
  text,
  text2,
  children,
}: BigBoxWithTitleLightTextAndBigImageProps) => {
  return (
    <div className={"box-content-card"}>
      <header className="d-flex flex-row justify-content-between header align-items-stretch">
        <ColorLight color={color} />
        <div
          className={
            "text-center content w-100 d-flex align-items-center justify-content-center"
          }
        >
          {title}
        </div>
        <ColorLight color={color} />
      </header>
      <div className={"content copy d-flex flex-column"}>
        <p>{text}</p>
        <p className={"text-2"}>{text2}</p>
      </div>
      <div
        className={
          "content dimmed d-flex align-items-center justify-content-center"
        }
        style={{ background: "#F5F7F9" }}
      >
        {children}
      </div>
    </div>
  );
};
