import React, { ReactElement } from "react";
import "./ModalishWalletWarning.scss";

export interface ModalishWarningProps {
  show: boolean;
  title?: string;
  subTitle?: string;
  line1?: string | ReactElement;
  line2?: string | ReactElement;
  spin?: boolean;
  showCross?: boolean;
}

export const ModalishWalletWarning = ({
  show,
  title,
  subTitle,
  line1,
  line2,
  spin = false,
  showCross = true,
}: ModalishWarningProps) => {
  return (
    <>
      {show && (
        <div
          className={
            "wallet-connect-modalish position-absolute w-100 d-flex flex-column text-center justify-content-center px-4 align-items-center"
          }
        >
          <div className={"title-container"}>
            <p>{title}</p>
            <p>{subTitle}</p>
          </div>
          <section>
            {line1 && <p>{line1}</p>}
            {line2 && <p className={"text-line-2"}>{line2}</p>}
          </section>
          {showCross === true && (
            <hr
              className={`cross-rule ${spin ? "spin" : ""}`}
              data-content={"†"}
            />
          )}
        </div>
      )}
    </>
  );
};
