import * as React from "react";
import { Face, Part } from "../../../../types";
import "./PartsDisplay.scss";
import { useCallback } from "react";
import { RmxButton } from "../RmxButton";

export type PartsDisplayProps = {
  onSelected: (part: Part) => void;
  parts: Part[];
  face: Face | undefined;
  usedTraits: string[];
  grayScaleActive: boolean;
};

export function PartsDisplay({
  onSelected,
  parts,
  face,
  usedTraits,
  grayScaleActive,
}: PartsDisplayProps) {
  const selectionHandler = (part: Part) => {
    return () => {
      onSelected(part);
    };
  };

  const setHighlight = useCallback(
    (record: Part) => {
      if (usedTraits.find((traitName) => traitName === record.name) && face) {
        return `selected`;
      } else {
        return "";
      }
    },
    [usedTraits, face]
  );

  const emojiJSX = parts.map((record: Part) => {
    return (
      <div key={record.id}>
        <RmxButton
          disabled={grayScaleActive ? true : false}
          className={`${setHighlight(record)} trait`}
          data-testid={`${record.name}`}
          action={selectionHandler(record)}
          img={record.imgUrl}
          title={record.name}
        />
      </div>
    );
  });

  return (
    <div className={`parts-display ${grayScaleActive ? "grayScale" : ""}`}>
      <div
        className={`parts-display-container ${grayScaleActive ? "blur" : ""}`}
      >
        <div className={"parts-display-grid"}>{emojiJSX}</div>
      </div>
    </div>
  );
}
