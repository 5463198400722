import React, { useState } from "react";
import emojiFront from "../../images/new_front_icon.png";
import emojiBack from "../../images/new_back_icon.png";
import {
  CreateCursedEmojiStatus,
  FrontendConfig,
} from "../../config/api/types";
import { GenesisMintUIMetadata } from "../../types";
import { MintedEmojiDisplayBack } from "./MintedEmojiDisplayBack";
import "./MintedEmojiDisplay.scss";

type viewState = "Front" | "Back";

interface MintedEmojiDisplayProps {
  frontEndConfig: FrontendConfig;
  newMetadata: GenesisMintUIMetadata | undefined;
  usedTraits: string[];
  cursedEmojiCreationStatus: CreateCursedEmojiStatus;
}

export function MintedEmojiDisplay({
  frontEndConfig,
  newMetadata,
  usedTraits,
  cursedEmojiCreationStatus,
}: MintedEmojiDisplayProps) {
  const [viewSide, setViewSide] = useState<viewState>("Front");

  return (
    <div className={"minted-emoji-display w-100 mx-auto"}>
      <div
        className={
          viewSide === "Front"
            ? "emoji-card mx-auto"
            : "flipped emoji-card mx-auto"
        }
      >
        {viewSide === "Back" && (
          <MintedEmojiDisplayBack
            frontEndConfig={frontEndConfig}
            newMetadata={newMetadata}
            transaction={cursedEmojiCreationStatus.nftTxnHash}
            usedTraits={usedTraits}
          />
        )}
        <img
          className={"reveal-emoji-image"}
          src={cursedEmojiCreationStatus?.imgUri}
          alt={newMetadata.name}
        />
      </div>
      <section className={"d-flex flex-row justify-content-center gap-4"}>
        <EmojiButton label="Front" state={viewSide} setState={setViewSide} />
        <EmojiButton label="Back" state={viewSide} setState={setViewSide} />
      </section>
    </div>
  );
}

export function EmojiButton({
  label,
  state,
  setState,
}: {
  label: viewState;
  state: viewState;
  setState: any;
}) {
  let image: string;
  let buttonId: string;

  if (label === "Front") {
    image = emojiFront;
    buttonId = "emojiButtonFront";
  } else {
    image = emojiBack;
    buttonId = "emojiButtonBack";
  }

  return (
    <div
      className={"emoji-button"}
      onClick={() => {
        setState(label);
      }}
    >
      <button
        id={buttonId}
        className={"rmx-button"}
        aria-pressed={state === label}
      >
        <img src={image}></img>
      </button>
      <label htmlFor={buttonId}>{label}</label>
    </div>
  );
}
