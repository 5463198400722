// thin abstraction over document methods; helps with testing
import { Blobable, instanceOfBlobable } from "./types";

export const DOMUtils = {
  getBlobableById: (elementId: string): Blobable => {
    const c = document.getElementById(elementId);
    if (!c) throw Error(`Element Id: ${elementId} not found`);
    if (!instanceOfBlobable(c))
      throw Error(`Element with Id:${elementId} is not Blobable`);
    return c as unknown as Blobable;
  },
};
