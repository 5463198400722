import cursedEmojiLogo from "../../images/cursed_emojis_logo.svg";
import {
  GenesisMintMetadataAttribute,
  GenesisMintUIMetadata,
} from "../../types";
import {
  getFlavorTextForFamily,
  getIconForTraitType,
  renderTraitDate,
} from "../utils/metadataReaderUtil";
import React from "react";
import { FrontendConfig } from "../../config/api/types";
import traitCount from "../../images/trait-count.svg";

interface MintedEmojiDisplayBackProps {
  newMetadata: GenesisMintUIMetadata | undefined;
  frontEndConfig: FrontendConfig;
  transaction: string;
  usedTraits: string[];
}

export const MintedEmojiDisplayBack = ({
  newMetadata,
  frontEndConfig,
  transaction,
  usedTraits,
}: MintedEmojiDisplayBackProps) => {
  const displayNftName = () => {
    if (newMetadata) {
      const split = newMetadata.name.split("#");
      return "#" + split[1];
    }
  };

  const displayTransaction = () => {
    return transaction ? `${transaction.slice(0, 35)}...` : null;
  };

  const determineBackgroundForBackTitle = () => {
    if (newMetadata) {
      const baseType = newMetadata.attributes.find(
        (attribute) => attribute.trait_type === "Cursed Family"
      );
      return (baseType?.value || "Old Yeller")
        .toString()
        .toLowerCase()
        .replace(" ", "-");
    }
  };

  const traitTypeToDisplay = [
    "Cursed Family",
    "Temperament",
    "Birthday",
    "Incarnation",
  ];

  const metaDataToDisplay = () => {
    const metadataToDisplay: GenesisMintMetadataAttribute[] = [];
    let metadataFound;
    traitTypeToDisplay.map((traitToFind) => {
      metadataFound = newMetadata?.attributes.find(
        (attribute) => attribute.trait_type === traitToFind
      );
      if (metadataFound) {
        metadataToDisplay.push(metadataFound);
      }
    });
    return metadataToDisplay;
  };

  const numberOfTraitsUsed = () => {
    if (newMetadata) {
      return newMetadata.attributes.length - traitTypeToDisplay.length;
    }
  };

  return (
    <section className={"emoji-metadata"}>
      <section
        className={`back-title ${determineBackgroundForBackTitle()}`}
        data-testid={"back-title"}
      >
        <div className="d-flex flex-row pt-2">
          <img src={cursedEmojiLogo} className={"w-50"} />
          <section className={"nft-name-section"}>
            <div className="nft-name">{displayNftName()}</div>
          </section>
        </div>
        <a
          className="transaction-id"
          href={`${frontEndConfig.etherScanUrl}/tx/${transaction}`}
          target={"_blank"}
          title={transaction}
        >
          {displayTransaction()}
        </a>
      </section>
      <section className="genealogy">
        <div className="genealogy-title mb-2">CURSED GENEALOGY</div>
        <section className={"content-back"}>
          <section>
            {newMetadata &&
              metaDataToDisplay().map(
                (attribute: GenesisMintMetadataAttribute, index: number) => {
                  return (
                    <section key={`metadata-attribute-key${index}`}>
                      <div className="d-flex flex-row align-items-center pt-1">
                        <img
                          className="attribute-image"
                          src={getIconForTraitType(attribute)}
                          alt={attribute.trait_type}
                          title={attribute.trait_type}
                        />
                        {attribute.display_type === "date" && (
                          <div className="attribute-name">
                            {renderTraitDate(attribute)}
                          </div>
                        )}
                        {attribute.display_type !== "date" && (
                          <div className="attribute-name">
                            {attribute.value}
                          </div>
                        )}
                      </div>
                      {attribute.trait_type === "Cursed Family" && (
                        <section
                          title={getFlavorTextForFamily(attribute)}
                          className="attribute-description"
                        >
                          {getFlavorTextForFamily(attribute)}
                        </section>
                      )}
                      {attribute.trait_type === "Cursed Family" && (
                        <div className="w-100 attribute-separator mt-2"></div>
                      )}
                    </section>
                  );
                }
              )}
          </section>
          <div className="d-flex flex-row justify-content-between align-items-center pb-2">
            <div className={"d-flex flex-row align-items-center"}>
              <img
                className="attribute-image"
                src={traitCount}
                alt={"Number of unique traits"}
                title={"Number of unique traits"}
              />
              <div className="attribute-name">Traits in Use</div>
            </div>
            <div className="attribute-name">{numberOfTraitsUsed()}/10</div>
          </div>
        </section>
      </section>
    </section>
  );
};
