import iconBase from "../../images/base.svg";
import iconBaseYellow from "../../images/icon-yellow-base.svg";
import iconBaseBlue from "../../images/icon-blue-base.svg";
import iconBaseRed from "../../images/icon-red-base.svg";
import iconBasePotato from "../../images/icon-potato-base.svg";
import iconTestTube from "../../images/icon-test-tube.svg";
import iconGeneration from "../../images/icon-dna.png";
import iconCake from "../../images/icon-cake.svg";
import { GenesisMintMetadataAttribute } from "../../types";

export type TraitType =
  | "cursed family"
  | "temperament"
  | "incarnation"
  | "birthday";

export type FamilyName = "old yeller" | "true blue" | "big red" | "yukon gold";

type TraitTypeToString = {
  [key in TraitType as string]: string;
};

type FamilyNameToString = {
  [key in FamilyName as string]: string;
};

const FAMILY_IMG_LUT: FamilyNameToString = {
  "old yeller": iconBaseYellow,
  "true blue": iconBaseBlue,
  "big red": iconBaseRed,
  "yukon gold": iconBasePotato,
};

const TRAIT_IMG_LUT: TraitTypeToString = {
  "cursed family": iconBase,
  temperament: iconTestTube,
  incarnation: iconGeneration,
  birthday: iconCake,
};

export const getIconForFamily = (family: FamilyName | string): string => {
  return FAMILY_IMG_LUT[family.toLowerCase()] || iconBase;
};

export const getIconForTraitType = ({
  trait_type,
  value,
}: {
  trait_type: TraitType | string;
  value: string | number;
}): string => {
  if (trait_type.toLowerCase() === "cursed family") {
    return getIconForFamily(`${value}`.toLowerCase()) || iconBase;
  }
  return TRAIT_IMG_LUT[trait_type.toLowerCase()] || iconBase;
};

export const FLAVOR_TEXTS_LUT: FamilyNameToString = {
  "old yeller": `Common base. Lively by nature and often found in tall grass. Look at the stars, look how they shine for you, and everything you do...`,
  "big red": `Rare base. Aggressive by nature and can sometimes be found in dark caves. Deliciously different. Not a dog.`,
  "true blue": `Super rare base. Relaxed by nature and can be found in large forests. Truth is the aim of belief. Da ba dee da ba di.`,
  "yukon gold": `Ultra rare base. Whimsical by nature and can be found in the dirt, but they are only seen after you’ve given up your search for them.`,
};

export const getFlavorTextForFamily = ({
  value,
}: GenesisMintMetadataAttribute) => {
  return FLAVOR_TEXTS_LUT[`${value}`.toLowerCase()];
};

export const renderTraitDate = ({ value }: GenesisMintMetadataAttribute) => {
  return new Date(value).toLocaleDateString();
};
