import { ReactNode } from "react";

export enum FaceColor {
  YELLOW = "yellow",
  BLUE = "blue",
  RED = "red",
  POTATO = "potato",
}

// Parts are collectively faces and traits; used to build emojis
export type Part = {
  id: number;
  imgUrl: string;
  name: FaceColor | string;
  category: Category;
  faceId?: number;
  tokenId?: string;
  idName?: string;
};

export const CATEGORIES = ["eyes", "mouths", "bodyparts", "options"];
export type Category = typeof CATEGORIES[number];

// a Face never has a reference another face
export type Face = Omit<Part, "faceId" | "idName">;

// React helper types
export interface ChildrenProps {
  children?: ReactNode;
}

// canvas support

/**
 * Blobable is anything that implements the toBlob method; used to target canvas elements
 */
export interface Blobable {
  toBlob: (
    callback: BlobCallback,
    type?: string | undefined,
    quality?: never
  ) => void;
}

export function instanceOfBlobable(obj: any) {
  return "toBlob" in obj;
}

export type GenesisMintMetadataAttribute = {
  display_type?: string;
  trait_type: string;
  value: string | number;
};

export type GenesisMintUIMetadata = {
  name: string;
  description: string;
  attributes: GenesisMintMetadataAttribute[];
  image: string;
};

export type LightColors = "red" | "green" | "grey" | "yellow";
