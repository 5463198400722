import React, { useEffect, useState } from "react";
import "./TodayIs.scss";

interface TodayIsProps {
  dateToDisplay?: Date;
}

export const TodayIs = ({ dateToDisplay }: TodayIsProps) => {
  const [tick, setTick] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setTick(tick + 1); // NOTE: this just forces re-render, date is updated by re-instantiating it
    }, 1000);
  });
  const now = dateToDisplay ? dateToDisplay : new Date();
  // const classString = dateToDisplay ? "" : "chromatic-ignore";
  const classString = "chromatic-ignore";

  const nowFormatted = now
    .toLocaleString("en-US", {
      timeZone: "America/New_York",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    })
    .toUpperCase();

  return (
    <div className={"mint-content-card"}>
      <div className="header content clock-header">D0ʘMED ROADMAP</div>
      <div className="content clock-content">
        TODAY IS{" "}
        <time className={classString} dateTime={Number(now).toString()}>
          {nowFormatted}
        </time>
      </div>
    </div>
  );
};
