import WaitingScreenLoading from "../WaitingScreens/WaitingScreenLoading";
import React from "react";
import { GenesisMintStatus } from "../../hooks/useGenesisMint";

interface CursedEmojiLayoutContentProps {
  status: GenesisMintStatus;
  isConnecting: boolean;
  isConnected: boolean;
  isUserAllowed: boolean | null;
  hasUserAlreadyMinted: boolean | null;
  contentForStatusStarted: React.ReactElement;
  contentForStatusMinted: React.ReactElement;
  indexLoadingScreen: number;
}

export const CursedEmojiLayoutContent = ({
  status,
  isConnecting,
  isConnected,
  isUserAllowed,
  hasUserAlreadyMinted,
  contentForStatusStarted,
  contentForStatusMinted,
  indexLoadingScreen,
}: CursedEmojiLayoutContentProps) => {
  const hasNotReceivedDataFromBackEndWhileIsConnected = () => {
    return (
      isConnected && (isUserAllowed == null || hasUserAlreadyMinted == null)
    );
  };

  return (
    <>
      {status === "waiting_on_wallet_confirmation" && (
        <>
          <WaitingScreenLoading
            index={0}
            text={"Confirm on your wallet to continue."}
          />
        </>
      )}
      {status === "minting" && (
        <>
          <WaitingScreenLoading
            index={1}
            text={[
              "Minting may take a moment.",
              <br />,
              "Please do not refresh.",
            ]}
          />
        </>
      )}
      {status === "waiting_on_access_list_confirmation" && (
        <>
          <WaitingScreenLoading
            index={2}
            text={"Validating that you are on the allowlist."}
          />
        </>
      )}
      {(isConnecting || hasNotReceivedDataFromBackEndWhileIsConnected()) && (
        <WaitingScreenLoading
          index={indexLoadingScreen}
          text={indexLoadingScreen}
        />
      )}

      {status === "start" && !isConnecting && (
        <>
          <div
            className={
              "flex-grow-1 pb-5 d-flex flex-column justify-content-center align-items-center"
            }
          >
            {contentForStatusStarted}
          </div>
        </>
      )}
      {status === "minted" && isConnected && isUserAllowed && (
        <>{contentForStatusMinted}</>
      )}
    </>
  );
};
