import { Face, FaceColor, Part } from "../../types";

import potato from "../../images/emoji/base/potato.png";
import red from "../../images/emoji/base/red.png";
import yellow from "../../images/emoji/base/yellow.png";
import blue from "../../images/emoji/base/blue.png";
import {
  BLUE_TRAITS,
  POTATO_FEATURES,
  RED_TRAITS,
  UNIVERSAL_TRAITS,
  YELLOW_TRAITS,
} from "./static-traits";
import {
  BLUE_FACE_ID,
  POTATO_FACE_ID,
  RED_FACE_ID,
  YELLOW_FACE_ID,
} from "./types";

// default face is yellow
export const DEFAULT_FACE_ID = YELLOW_FACE_ID;
export const DEFAULT_FACE: Face = {
  id: DEFAULT_FACE_ID,
  imgUrl: yellow,
  name: FaceColor.YELLOW,
  category: "face",
};

export const FACES: Face[] = [
  DEFAULT_FACE,
  {
    id: BLUE_FACE_ID,
    imgUrl: blue,
    name: FaceColor.BLUE,
    category: "face",
  },
  {
    id: RED_FACE_ID,
    imgUrl: red,
    name: FaceColor.RED,
    category: "face",
  },
  {
    id: POTATO_FACE_ID,
    imgUrl: potato,
    name: FaceColor.POTATO,
    category: "face",
  },
];

export const getFaceById = (id?: number): Face | null => {
  return FACES.find((e) => e.id === id) ?? null;
};

export const loadStaticFaces = (): Face[] => {
  return FACES;
};

export const loadStaticFeatures = (): Part[] => [
  ...UNIVERSAL_TRAITS,
  ...YELLOW_TRAITS,
  ...BLUE_TRAITS,
  ...RED_TRAITS,
  ...POTATO_FEATURES,
];
