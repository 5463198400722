import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import downloadIcon from "../../images/downloadIcon.svg";
import questionIcon from "../../images/question.svg";
import { CursedHorizontalRule } from "../CursedHorizontalRule";
import "./AboutContent.scss";
import CursedEmojisNav from "./CursedEmojisNav/CursedEmojisNav";
import "./MintContent.scss";
import { ScheduleEventWindow } from "./ScheduleEventWindow";
import { TodayIs } from "./TodayIs";
import {
  AuctionPhases,
  FrontendConfig,
  MintPhases,
  RemixPhases,
} from "../../config/api/types";

export const LiveClock = () => {
  const [tick, setTick] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setTick(tick + 1);
    }, 1000);
  });
  const now = new Date();

  const nowFormatted = now
    .toLocaleString("en-US", {
      timeZone: "America/New_York",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    })
    .toUpperCase();

  return <time dateTime={Number(now).toString()}>{nowFormatted}</time>;
};

interface AboutContentProps {
  frontEndConfig: FrontendConfig;
}

export const AboutContent = ({ frontEndConfig }: AboutContentProps) => {
  const determineLightStateForMint = (mintState: MintPhases) => {
    if (mintState === "open-mint") {
      return "CURRENT";
    } else if (mintState === "post-mint") {
      return "PAST";
    } else {
      return "FUTURE";
    }
  };

  const determineLightStateForRemix = (remixState: RemixPhases) => {
    return remixState === "open-remix" ? "CURRENT" : "FUTURE";
  };

  const determineLightStateForAuction = (autionState: AuctionPhases) => {
    if (autionState === "open-auction") {
      return "CURRENT";
    } else if (autionState === "post-auction") {
      return "PAST";
    } else {
      return "FUTURE";
    }
  };

  return (
    <div className={"mint-content text-center"}>
      <div className={"genesis-subnav text-center"}>
        <CursedEmojisNav />
      </div>

      <section className={"intro"}>
        <div className={"mint-content-card "}>
          <header className={"text-center content"}>A CUⱤSED OVERVIEW</header>
          <div className={"content copy"}>
            <p>
              Artist{" "}
              <a
                href={
                  "https://mirror.xyz/rmxparty.eth/KoLrhfPsd80LGg2O9nKSy6W-LXcht3XHpNu4KflUz_M"
                }
                target={"_blank"}
              >
                yung jake
              </a>{" "}
              has created and minted 235 unique traits and 4 Emoji Base colors.
              The Emoji Base acts as your RMX Pass.
            </p>
            <p>
              The RMX Console is token-gated software for web3 users. With your
              RMX Pass, you can create your very own CC0 Cursed Emoji.
            </p>
            <p>
              yung jake will create a 1:1 artwork from his favorite Cursed
              Emojis. If he selects the Cursed Emoji you make, you will receive
              a portion of an auction.
            </p>
          </div>
        </div>
      </section>

      <CursedHorizontalRule content="†" className="separator" />

      <section className={"schedule d-flex flex-column"}>
        <TodayIs />

        <ScheduleEventWindow
          title="I. Get Yer RMX Pass: Emoji Base"
          footer="512 RMX Passes, Free to Mint"
          openDate={"Jan 17, 2023 11:00 AM EST"}
          closeDate={"Jan 18, 2023 11:00 AM EST"}
          eventWindowState={determineLightStateForMint(
            frontEndConfig.mintPhase
          )}
        />
        <ScheduleEventWindow
          title="II. Remix Your Emoji Base"
          footer="Remix by Jan 24 to be considered for yung jake artwork"
          openDate={"Jan 19, 2023 11:00 AM EST"}
          closeDate={"when all remixes are complete"}
          eventWindowState={determineLightStateForRemix(
            frontEndConfig.remixPhase
          )}
          linkUrl={"/rmxconsole"}
        />
        <ScheduleEventWindow
          title="III. yung jake x Cursed Emoji Art Auction"
          footer="If your Cursed Emoji is used, receive a percentage of the sale"
          openDate={"Feb 7, 2023  11:00 AM EST"}
          closeDate={"Feb 8, 2023  11:00 AM EST"}
          eventWindowState={determineLightStateForAuction(
            frontEndConfig.auctionPhase
          )}
        />
        <div className={"mint-content-card"}>
          <div className="content d-flex align-content-center justify-content-center">
            <Link
              to="/CalendarInvite/rmx-cursed-emoji-calendar.ics"
              target="_blank"
              download
            >
              Save the Dates! Download Cal Events
              <img src={downloadIcon} className={"link-icon"} />
            </Link>
          </div>
        </div>
      </section>

      <CursedHorizontalRule content="§" className="separator" />

      <section className={"faq"} id={"faq"}>
        <div className={"mint-content-card"}>
          <div className="header content text-center">WRETℭHED FAQ</div>
          <div className="content">
            <p>
              <strong>How does this all work?</strong>
              <br />
              <a href="https://www.rmx.party/" target="_blank">
                RMX
              </a>{" "}
              and{" "}
              <a
                href="https://mirror.xyz/rmxparty.eth/KoLrhfPsd80LGg2O9nKSy6W-LXcht3XHpNu4KflUz_M"
                target="_blank"
              >
                yung jake
              </a>{" "}
              have created an experiment in on-chain collaboration in which we
              give select wallets the ability to mint 512 free RMX Passes (AKA
              “passes,” “Emoji Bases”).
            </p>
            <p>
              Each pass grants access to our site’s token-gated RMX Console when
              it opens on 01/19/23. The console gives each NFT pass holder the
              ability to remix the pass’s image directly by layering up to 10 of
              yung jake’s NFT traits in infinite combinations. These remixed
              passes will be immortalized as Cursed Emoji NFTs in a permanent
              CC0 collection on the Ethereum blockchain.
            </p>
            <p>
              yung jake will be creating a new NFT piece with some of his
              favorite Cursed Emojis. He’ll take two weeks to create a new and
              derivative piece from your Cursed Emojis, and then we’ll auction
              it on 02/07/2023. When the auction is complete, we’ll split the
              sale 50/50 with the creators' wallets whose Cursed Emojis he used.
            </p>
            <p>
              <strong>Is this a free mint?</strong>
              <br />
              Yes. You are responsible for gas fees.
            </p>
            <p>
              <strong>What is the timeline of the mint?</strong>
              <br />
              The RMX Passes will be available starting 1/17/2023.
            </p>
            <p>The RMX Console will open on 1/19/2023.</p>
            <p>
              If you’d like your Cursed Emoji considered for yung jake’s
              artwork, you’ll need to complete your remix by 1/24/2023.
              Regardless, the console will remain open until all 512 Cursed
              Emojis are remixed.
            </p>
            <p>
              <strong>What is an Emoji Base?</strong>
              <br />
              The bases are designed by artist{" "}
              <a
                href="https://mirror.xyz/rmxparty.eth/KoLrhfPsd80LGg2O9nKSy6W-LXcht3XHpNu4KflUz_M"
                target="_blank"
              >
                yung jake
              </a>
              . They start life as the genesis mint of the RMX Passes to our RMX
              Console. An Emoji Base serves as both a pass and the underlying
              “face” of the Cursed Emoji you will create in the console itself.
              When you mint a pass, you’ll receive one of four randomized colors
              for your Emoji Base (see below).
            </p>
            <p>
              <strong>Can I use an Emoji Base more than once?</strong>
              <br />
              No. The base unlocks the RMX Console, where you remix it to create
              your Cursed Emoji. Once you’ve created and minted your Cursed
              Emoji, your base is immutably altered and can’t be used again.
            </p>
            <p>
              <strong>How many passes are being minted?</strong>
              <br />
              We’re minting 512 RMX Passes: Emoji Bases.
            </p>
            <p>
              <strong>What are the color distributions of the bases?</strong>
              <br />
              256: Yellow 🟡
              <br />
              128: Red 🔴
              <br />
              96: Blue 🔵
              <br />
              32: Potato 🥔
            </p>
            <p>
              <strong>Can I choose what color base I get?</strong>
              <br />
              Your color is randomly generated at the time of mint. If you want
              a specific color, you might see if it’s available for sale on a
              <a href={frontEndConfig.genesisCollectionUrl} target={"_blank"}>
                {" "}
                marketplace.
              </a>
            </p>
            <p>
              <strong>Why only 512?</strong>
              <br />
              This is an experiment. We don’t know how folks will receive it,
              play with it and make emojis with it, so we wanted to keep the
              scope small and simple to start.
            </p>
            <p>
              <strong>
                What are Incarnations, Birthday, and Temperaments?
              </strong>
              <br />
              When you remix your Emoji Base to create your Cursed Emoji, the
              smart contract and metadata of your NFT will update, thus
              advancing your NFT into its second incarnation.
              <br />
              Birthday is the day your Emoji Base is minted.
              <br />
              Temperament is a description of your Cursed Emoji’s innate
              personality.
            </p>
            <p>
              <strong>Is there an allowlist or pre-mint?</strong>
              <br />
              Yes, we’ll be sharing the details with select friends and
              communities of creators for pre-mint access. Want to participate?
              DM us on{" "}
              <a href="https://twitter.com/rmx_party" target="_blank">
                Twitter
              </a>
              ! We’ll also be raffling 25 spots to the public.
              <br />
              Be sure to sign up:
              <br />
              <a
                href="https://www.premint.xyz/yung-jake-cursed-emojis/"
                target="_blank"
              >
                https://www.premint.xyz/yung-jake-cursed-emojis/
              </a>
            </p>
            <p>
              <strong>When is the public mint?</strong>
              <br />
              The public mint is on 01/17/23.
            </p>
            <p>
              <strong>
                What are the 60 reserved bases going to be used for?
              </strong>
              <br />A few will go to the team. A few will be used for testing
              purposes. The rest will be given to community members and friends
              who emerge as potentially great Cursed Emoji creators. If you’re
              looking to get a pass after the genesis mint, do something
              creative and tag us!
            </p>
            <p>
              <strong>
                I missed the Genesis Mint. Is there a way I can still
                participate?
              </strong>
              <br />
              Try searching the{" "}
              <a href={frontEndConfig.genesisCollectionUrl}> collection</a> to
              see if there are any Emoji Bases available for sale.
            </p>
            <p>
              <strong>When does the RMX Console open for remixing?</strong>
              <br />
              The RMX Console opens 01/19/23 for creating and remixing Cursed
              Emojis.
            </p>
            <p>
              <strong>
                I bought a second (or third or fourth) Emoji Base on OpenSea.
                Can I remix both?
              </strong>
              <br />
              Yes. You’ll be able to remix all your Emoji Bases. The RMX Console
              will choose the lowest token number first. You can return to the
              console for the next base once you’ve completed your first remix.
              If you want to remix in a different order, we recommend
              transferring a single token to a different wallet for remixing.
            </p>
            <p>
              <strong>
                I remixed but only see my Emoji Base on OpenSea. How do I see my
                Cursed Emoji?
              </strong>
              <br />
              When you remix your Emoji Base to create your Cursed Emoji, the
              smart contract and metadata of your NFT will update. OpenSea
              requires you to manually refresh a token’s metadata to see the
              updates on its site.
              <br />
              <br />
              Follow these steps to refresh metadata and see your Emoji Base
              transform into your Cursed Emoji: <br />
              1. Go to your NFT on OpenSea.
              <br />
              2. Select the three dots next to the share icon.
              <br />
              3. Select “Refresh metadata.”
              <br />
              4. After a few minutes, reload the page to see the base update
              into your Cursed Emoji!
            </p>
            <p>
              <strong>What do I get when I mint with you?</strong>
              <ul>
                <li>You’ll get a RMX Pass: Emoji Base.</li>
                <li>
                  You’ll get access to the RMX Console to make a Cursed Emoji
                  for the internet.
                </li>
                <li>
                  Jake’s Grails - This is the opportunity to be one of Jake’s
                  official selections of Cursed Emojis from the 512 to make a
                  unique artwork and share in the profits of its sale.
                </li>
                <li>You’ll get access to RMX’s Geneva community.</li>
                <li>
                  You’ll be able to monetize your Cursed Emoji however you wish
                  (see license info below).
                </li>
                <li>
                  You’ll receive first access to RMX drops for the rest of 2023.
                </li>
              </ul>
            </p>
            <p>
              <strong>
                What’s the license for my Cursed Emoji? Do I have commercial
                rights?
              </strong>
              <br />
              The license is a fork of CC0 called “Can’t Be Evil.” Specifically,
              we’re assigning the{" "}
              <a
                href="https://7q7win2vvm2wnqvltzauqamrnuyhq3jn57yqad2nrgau4fe3l5ya.arweave.net/_D9kN1WrNWbCq55BSAGRbTB4bS3v8QAPTYmBThSbX3A/3"
                target="_blank"
              >
                CBE-NECR-HS
              </a>
              . This license allows for free non-commercial use and commercial
              rights where individual owners retain exploitation rights over
              their Cursed Emoji.
            </p>
            <p>
              <strong>What is so unique about this mint?</strong>
              <br />
              Cursed Emojis is a collaboration between artist yung jake and RMX,
              a startup dedicated to making on-chain creation simple, fun, and
              accessible.
            </p>
            <p>
              There are 512 free RMX Passes: Emoji Bases distributed to select
              creators, collectors, and communities.
            </p>
            <p>
              Each RMX Pass grants the ability for one person to create their
              own Cursed Emoji NFT using trait-based NFTs created by yung jake.
              Each Cursed Emoji NFT will be immortalized into a permanent
              on-chain CC0 collection.
            </p>
            <p>
              The project is an experiment in co-creation. It starts as a
              dialogue between artist and collector, with each Emoji Base
              unlocking a palette of possible traits.
            </p>
            <p>
              Only the collectors can decide how each Cursed Emoji will actually
              manifest as an NFT through their creativity and remixing choices.
              No one can anticipate what the final set of Cursed Emojis will
              look like. If you hold a base, you decide. We’re excited to see
              what you, the community of collectors, design.
            </p>
            <p>
              The conversation between artist and collectors completes a loop
              when yung jake makes a new NFT-based piece from the Cursed Emojis
              NFT collection.
            </p>
            <p>
              Jake will put the new piece up for auction and split the proceeds
              50/50 with the collectors of each of the Cursed Emojis he uses.
            </p>
            <p>
              All Cursed Emojis will be released under a modified Creative
              Commons license that allows collectors to retain economic rights
              to their creations - CBE-NECR-HS.
            </p>

            <p>
              <strong>Who is yung jake?</strong>
              <br />
              yung jake, a Los Angeles-based visual artist and rapper, is best
              known for generating music videos that reflect on how digital art,
              social media, and shifting perceptions of reality inform modern
              culture.{" "}
              <a
                href="https://mirror.xyz/rmxparty.eth/KoLrhfPsd80LGg2O9nKSy6W-LXcht3XHpNu4KflUz_M"
                target="_blank"
              >
                You can read an interview we did with him here
              </a>{" "}
              or check out this{" "}
              <a
                href="https://www.nytimes.com/2017/07/26/style/emoji-portraits-yung-jake.html"
                target="_blank"
              >
                NYTimes profile here
              </a>
              .
            </p>

            <p>
              <strong>Who is RMX?</strong>
              <br />
              We’re making tools for on-chain creativity.{" "}
              <a
                href="https://mirror.xyz/rmxparty.eth/SHotA5LcVl4z3QzqSujvhk9dsWckeJ5cvg0vGV5NPbg"
                target="_blank"
              >
                Learn more about it in this post from rmxceo
              </a>
              .
            </p>
            <p>
              <strong>Is there a community for Cursed Emoji creators?</strong>
              <br />
              Yes, once you’ve minted your Emoji Base, feel free to join us in{" "}
              <a
                href="https://app.geneva.com/invite/6626ed36-79db-41d3-94e5-68a2163345e6"
                target="_blank"
              >
                Geneva
              </a>
              ! Members get exclusive access to RMX’s unique community of web3
              creators and influencers and get access to all RMX drops in the
              next 12 months.
            </p>
            <p>
              <strong>What are your Terms and Conditions?</strong>
              <br />
              <a
                href="https://cursedemojis.world/assets/Remix%20-%20NFT%20Site%20Terms%20and%20Conditions.pdf"
                target="_blank"
              >
                Terms and Conditions
              </a>
            </p>
          </div>

          <div className="content text-center">
            <a href={"https://www.rmx.party"} target={"_blank"}>
              Looking to learn about RMX?
              <img src={questionIcon} className={"link-icon"} />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
