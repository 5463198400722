import {
  ConnectKitProvider,
  getDefaultClient,
  ConnectKitButton,
} from "connectkit";
import ReactDOM from "react-dom/client";
import { createClient, goerli, mainnet, WagmiConfig } from "wagmi";
import App from "./App";
import "./index.scss";
import { ErrorComponent } from "./components/Error/ErrorComponent";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { ErrorBoundary } from "react-error-boundary";

const alchemyId = process.env.REACT_APP_ALCHEMY_API_KEY;
const infuraId = process.env.REACT_APP_INFURA_API_KEY;
const enabledChainsByFlags = [
  process.env.REACT_APP_USE_MAINNET ? mainnet : goerli,
];

// const chains = [mainnet, goerli]; // TODO: make conditional on ENV appropriately
const chains = enabledChainsByFlags; // TODO: make conditional on ENV appropriately
// const chains = [goerli]; // TODO: make conditional on ENV appropriately
const wagmiClient = createClient(
  getDefaultClient({
    appName: "Cursed Emojis",
    alchemyId,
    infuraId,
    chains,
  })
);

Bugsnag.start({
  apiKey: "436d14f5b2f5d48a6ad6df83907d33a5",
  plugins: [new BugsnagPluginReact()],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  Bugsnag.notify(error);
  console.log(error);
};

root.render(
  <>
    <ErrorBoundary FallbackComponent={ErrorComponent} onError={myErrorHandler}>
      <WagmiConfig client={wagmiClient}>
        <ConnectKitProvider
          theme="minimal"
          customTheme={{
            "--ck-font-family": "Times New Roman",
            "--ck-connectbutton-font-size": "16px",
            "--ck-connectbutton-hover-background":
              "linear-gradient(270deg, #ff00ef -18.56%, #ffef00 41.63%, #46f517 102.08%)",
          }}
          options={{
            disclaimer: (
              <>
                By connecting your wallet you agree to the{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cursedemojis.world/assets/Remix%20-%20NFT%20Site%20Terms%20and%20Conditions.pdf"
                  className={"terms-condition-link"}
                >
                  Terms and Conditions
                </a>{" "}
              </>
            ),
          }}
        >
          <App />
        </ConnectKitProvider>
      </WagmiConfig>
    </ErrorBoundary>
  </>
);
